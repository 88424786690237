import { useRef } from 'react';

import { cn } from '~/utils';

import useDeviceStore from '../StandoutDevice.store';
import { Moon, Sun } from './DeviceThemeToggle.icons';
import styles from './DeviceThemeToggle.module.css';

interface ThemeToggleProps {
  isVisible?: boolean;
}

const ThemeToggle = ({ isVisible }: ThemeToggleProps) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $handle = useRef<HTMLButtonElement>(null);
  const $track = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useDeviceStore((state) => [
    state.theme,
    state.setTheme,
  ]);

  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  };

  return (
    <div className={cn(styles.container, isVisible && styles.isVisible)}>
      <div className={styles.toggleWrapper} ref={$wrapper}>
        <div className={styles.toggleTrack} ref={$track}>
          <button className={styles.toggle} ref={$handle} onClick={toggleTheme}>
            <div className={styles.toggleContainer}>
              <div
                className={cn(
                  styles.indicator,
                  theme === 'dark' ? styles.dark : styles.light,
                )}
              ></div>
              <div className={styles.icons}>
                <div
                  className={cn(
                    styles.icon,
                    theme === 'dark' && styles.activeIcon,
                  )}
                >
                  <Moon />
                </div>
                <div
                  className={cn(
                    styles.icon,
                    theme === 'light' && styles.activeIcon,
                  )}
                >
                  <Sun />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThemeToggle;
