'use client';
import { gsap } from 'gsap';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import FloatingVideoPreview from '~/components/molecules/FloatingVideoPreview/FloatingVideoPreview';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { ForwardedTextLockupRef } from '~/components/molecules/TextLockups/TextLockups.types';
import Tube from '~/components/molecules/Tube/Tube';
import { TubeRef } from '~/components/molecules/Tube/Tube.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import UIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';
import isNotSmall from '~/utils/isNotSmall';
import { EaseType } from '~/utils/singletons/Easing';

import styles from './HeroTube.module.css';
import { HeroTubeProps } from './HeroTube.types';

const useUIStore = UIStore;
/**
 * Hero tube component
 * @param content Portable text content
 * @param floatingVideoPreview Video with content to be displayed floating bottom-right on desktop
 * @param className
 */
const HeroTube = (props: HeroTubeProps) => {
  const { className, content, floatingVideoPreview } = props;

  const tube1Ref = useRef<TubeRef>(null);
  const tube2Ref = useRef<TubeRef>(null);

  const translateProgress = useRef(0);
  const windowHeight = useRef(0);
  const overlap = useRef(0);
  const pixelRatio = useRef(1);

  const windowWidth = useUIStore((state) => state.windowWidth);

  const [hasTube1Loaded, setHasTube1Loaded] = useState(false);
  const [hasTube2Loaded, setHasTube2Loaded] = useState(false);
  const [isInView, setIsInView] = useState(false);

  const hasAnimatedIn = useRef(false);

  const $text = useRef<ForwardedTextLockupRef>(null);

  const $component = useRef<HTMLDivElement>(null);
  const $container = useRef<HTMLDivElement>(null);

  const uniforms = useRef<{
    resolution: [number, number];
    tubeTop: number;
    tubeHeight: number;
    horizontalStretch: number;
  }>({
    resolution: [0, 0],
    tubeTop: 0,
    tubeHeight: 0,
    horizontalStretch: 0.15,
  });

  const $tubeWrapper = useRef<HTMLDivElement>(null);

  const tubeTl = useRef<GSAPTimeline | null>(null);

  const translateFactor = () => 470 * (1 / windowHeight.current);

  const update = () => {
    if (!$text.current?.$element?.current) return;
    gsap.set($text.current?.$element?.current, {
      y: translateProgress.current * windowHeight.current * translateFactor(),
    });
    uniforms.current.tubeTop = overlap.current * pixelRatio.current;
  };

  // resets animation on resize, because ball animation need to be recalculated.
  useEffect(() => {
    hasAnimatedIn.current = false;
    if (tubeTl.current) {
      tubeTl.current.pause(0);
      tubeTl.current.kill();
      tubeTl.current = null;
    }
  }, [windowWidth]);

  useEffect(() => {
    if (
      !hasAnimatedIn.current &&
      isInView &&
      tube1Ref.current?.$container.current &&
      tube2Ref.current?.$container.current
    ) {
      hasAnimatedIn.current = true;
      const tl = gsap.timeline();
      tl.to(tube1Ref.current.$container.current, {
        opacity: 1,
        yPercent: 0,
        y: 0,
        duration: 1,
        scale: 1,
        ease: EaseType.BASIC_BUTTER,
      });
      tl.to(
        tube2Ref.current.$container.current,
        {
          opacity: 1,
          yPercent: 0,
          y: 0,
          duration: 1,
          scale: 1,
          ease: EaseType.BASIC_BUTTER,
        },
        0.08,
      );

      if (tubeTl.current === null) {
        const tl = gsap.timeline({ delay: 1 });
        if (tube1Ref.current) {
          const ballAnimation = tube1Ref.current?.play({
            repeat: -1,
            repeatDelay: 2,
          });
          if (ballAnimation) {
            tl.add(ballAnimation);
          }
        }
        if (tube2Ref.current) {
          const ballAnimation = tube2Ref.current?.play({
            repeat: -1,
            repeatDelay: 3,
            reversed: true,
          });

          if (ballAnimation) {
            tl.add(ballAnimation, '-=3.5');
          }
        }
        tubeTl.current = tl;
      }
    } else if (!isInView && tubeTl.current) {
      tubeTl.current.kill();
      tubeTl.current = null;
    }
  }, [hasTube1Loaded, hasTube2Loaded, isInView, windowWidth]);

  const onProgress = useCallback(
    (progress: number, isCurrentlyInView: boolean) => {
      if (isNotSmall()) {
        translateProgress.current = gsap.utils.mapRange(0, 0.8, 0, 1, progress);
        update();

        if (isCurrentlyInView && !hasAnimatedIn.current) {
          setIsInView(true);
        }
      } else {
        if (isCurrentlyInView && tubeTl.current === null) {
          const tl = gsap.timeline();
          if (tube1Ref.current) {
            const ballAnimation = tube1Ref.current?.play({
              repeat: -1,
              repeatDelay: 2,
            });

            if (ballAnimation) {
              tl.add(ballAnimation);
            }
          }
          if (tube2Ref.current) {
            const ballAnimation = tube2Ref.current?.play({
              repeat: -1,
              repeatDelay: 3,
              reversed: true,
            });

            if (ballAnimation) {
              tl.add(ballAnimation, '-=4');
            }
          }
          tubeTl.current = tl;
        } else if (!isCurrentlyInView && tubeTl.current) {
          tubeTl.current.kill();
          tubeTl.current = null;
        }
      }
    },
    [],
  );

  useScrollProgress($component, onProgress, { shouldAlwaysComplete: false });

  useEffect(() => {
    windowHeight.current = UIStore.getState().windowHeight || 0;

    const debouncedResize = debounce(() => {
      windowHeight.current = UIStore.getState().windowHeight || 0;

      if (!isNotSmall()) {
        if (!$text.current?.$element?.current) return;

        gsap.set($text.current?.$element?.current, {
          clearProps: 'transform',
        });
      }
    }, 10);

    const unsubscribe = UIStore.subscribe(
      (state) => state.windowWidth,
      debouncedResize,
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ModuleWrapper
      className={cn(styles.hero, className)}
      {...props}
      ref={$component}
    >
      <div className={styles.container} ref={$container}>
        <div className={styles.textWrapper}>
          <TextLockup
            ref={$text}
            value={content.blocks}
            className={styles.content}
            lockupOptions={content.lockupOptions}
          />
        </div>

        {floatingVideoPreview && (
          <FloatingVideoPreview
            className={styles.floatingVideoPreview}
            options={floatingVideoPreview}
            $parent={$container}
          />
        )}

        <div className={cn(styles.tubeWrapper)} ref={$tubeWrapper}>
          <div className={styles.topTubeWrapper}>
            <Tube
              ref={tube1Ref}
              type="tube1"
              className={styles.tube1}
              autoPlayWhenInView={false}
              onLoad={() => setHasTube1Loaded(true)}
            />
          </div>
          <Tube
            ref={tube2Ref}
            type="tube2"
            className={styles.tube2}
            autoPlayWhenInView={false}
            onLoad={() => setHasTube2Loaded(true)}
          />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default HeroTube;
