import { CMSGraphicProps } from '~/components/atoms/Graphic/Graphic.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';
import { getUniqueId } from '~/utils';

import { LogoWallProps } from './LogoWall.types';

const duplicator = (source: CMSGraphicProps[]) => {
  return [
    { key: getUniqueId('logos'), logos: [...source] },
    { key: getUniqueId('logos'), logos: [...source] },
    { key: getUniqueId('logos'), logos: [...source] },
  ];
};

const logoWallFormatter = (module: CMSModuleBase) => {
  if (module._type === 'module.logoWall') {
    const moduleLogoWalls = module as LogoWallProps;

    // Desktop
    moduleLogoWalls.desktopLogos = duplicator(moduleLogoWalls.logos);
  }
};

export default logoWallFormatter;
