import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from 'react';

import { cn } from '~/utils';

import styles from './GlassWrapper.module.css';

const GlassWrapper = (
  {
    children,
    className,
    contentClassName,
    style,
  }: {
    children: ReactNode;
    className?: string;
    contentClassName?: string;
    style?: CSSProperties;
  },
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div className={cn(styles.glassWrapper, className)} style={style} ref={ref}>
      <div className={cn(styles.glassWrapperContent, contentClassName)}>
        {children}
      </div>
    </div>
  );
};

export default forwardRef(GlassWrapper);
