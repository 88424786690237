'use client';
import { gsap } from 'gsap';
import {
  CSSProperties,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import SvgPlusIcon from '~/assets/svg/plus-icon.svg';
import Graphic from '~/components/atoms/Graphic/Graphic';
import Image from '~/components/atoms/Image/Image';
import Observer from '~/components/atoms/Observer/Observer';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Glow from '~/components/molecules/Glow/Glow';
import Shadow from '~/components/molecules/Shadow/Shadow';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { dict } from '~/data/stores/Dictionary';
import useUIStore from '~/state/ui';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';
import { tickerAddOnce } from '~/utils/ticker';

import {
  QuoteInteractiveCard,
  QuoteInteractiveProps,
} from '../QuoteInteractive.types';
import { animateIn, animateOut } from './QuoteInteractiveLg.animation';
import styles from './QuoteInteractiveLg.module.css';
import QuoteInteractiveLgModal from './QuoteInteractiveLgModal/QuoteInteractiveLgModal';

const QuoteInteractiveLg = ({
  content,
  quoteCards,
  introLockupOptionsDesktop,
  className,
}: QuoteInteractiveProps) => {
  const $cards = useRef<HTMLDivElement[]>(Array(quoteCards.length));
  const $cardsInner = useRef<HTMLButtonElement[]>(Array(quoteCards.length));
  const $titles = useRef<HTMLButtonElement[]>(Array(quoteCards.length));
  const $logos = useRef<HTMLElement[]>(Array(quoteCards.length));
  const $list = useRef<HTMLUListElement>(null);
  const $dummy = useRef<HTMLDivElement>(null);

  const isAnimatingRef = useRef(false);

  const [currentCardRect, setCurrentCardRect] = useState<DOMRect>();
  const [hasInteractedSinceResize, setHasInteractedSinceResize] =
    useState<boolean>(false);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>();
  const listRect = useRef<DOMRect>();
  const preloadedImages = useRef<boolean[]>(
    Array(quoteCards.length).fill(false),
  );

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const mousePositionXSetter = useRef<(value: number) => void>();
  const mousePositionYSetter = useRef<(value: number) => void>();
  const windowWidth = useUIStore((state) => state.windowWidth);

  const handleHover = (index: number) => {
    if (index === currentIndex) return;

    setCurrentIndex(index);
    setHasInteractedSinceResize(true);
    setShowCard(true);
  };

  const handleMouseLeave = () => {
    setShowCard(false);
    setCurrentIndex(undefined);
  };

  const handleClick = (index: number) => {
    const target = $cardsInner.current[index] as HTMLButtonElement;
    tickerAddOnce(() => {
      if (isAnimatingRef.current) return;
      const rect = target.getBoundingClientRect();
      setShowModal(true);
      setCurrentIndex(index);
      setCurrentCardRect(rect);
    }, true);
  };

  const handleMouseMove = (event: MouseEvent<HTMLButtonElement>) => {
    if (listRect.current) {
      const max = listRect.current.width;
      const x = gsap.utils.mapRange(
        0,
        max,
        0,
        max / 1.5,
        event.clientX - listRect.current.x,
      );
      const y = gsap.utils.mapRange(
        0,
        max,
        0,
        max / 1.5,
        event.nativeEvent.offsetY,
      );
      if (mousePositionXSetter.current) mousePositionXSetter.current(x);
      if (mousePositionYSetter.current) mousePositionYSetter.current(y);
    }
  };

  const onClose = useCallback(() => {
    //  restore focus to the trigger element
    $titles.current[currentIndex || 0].focus();
    setShowModal(false);
  }, [currentIndex]);

  const handleKeyDown = (
    event: KeyboardEvent<HTMLButtonElement | HTMLLIElement>,
  ) => {
    if (isAnimatingRef.current) return;
    if (event.code === 'Enter') {
      setShowModal(true);
    }
  };

  useEffect(() => {
    $cards.current.forEach(($card, index) => {
      if (index === currentIndex) return;
      animateOut(
        $card,
        $logos.current[index],
        {
          // add delay to prevent flashing when opening modal
          delay: showModal ? 0.2 : 0,
        },
        showModal,
      );
    });

    if (currentIndex !== undefined) {
      animateIn($cards.current[currentIndex], $logos.current[currentIndex], {
        onStart: () => {
          isAnimatingRef.current = true;
        },
        onComplete: () => {
          isAnimatingRef.current = false;
        },
      });
    }
    // intentionally omitting showModal state because it creates double animation triggers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    // when resize, reset preload flags to false so that we trigger preload again
    preloadedImages.current.fill(false);
    setHasInteractedSinceResize(false);
    tickerAddOnce(() => {
      listRect.current = $list.current?.getBoundingClientRect();
    }, true);
  }, [windowWidth]);

  useLayoutEffect(() => {
    mousePositionXSetter.current = gsap.quickSetter(
      $list.current,
      '--mouse-x',
      'px',
    ) as (value: number) => void;
    mousePositionYSetter.current = gsap.quickSetter(
      $list.current,
      '--mouse-y',
      'px',
    ) as (value: number) => void;

    // Setting the expanded size to the hover card using
    // a dummy element that is sized just like the modal
    // so that we can load the larger image once in the card
    //(thanks to autResize={false} on the <Image/> component)
    gsap.set($list.current, {
      '--expanded-width': $dummy.current?.getBoundingClientRect().width + 'px',
    });
  }, []);

  return (
    <div
      className={cn(
        styles.quoteInteractiveLg,
        showModal && styles.modalOpen,
        hasInteractedSinceResize && styles.hasInteracted,
        className,
      )}
    >
      <div ref={$dummy} className={styles.dummy}></div>
      <TextLockup
        value={content.blocks}
        lockupOptions={introLockupOptionsDesktop}
        className={styles.content}
      />
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
        className={styles.quoteInteractiveWrapper}
      >
        <ul
          ref={$list}
          className={styles.quoteInteractive}
          onMouseLeave={handleMouseLeave}
        >
          {quoteCards.map((quoteCard: QuoteInteractiveCard, index: number) => {
            return (
              <li
                className={styles.quoteInteractive}
                style={{ '--index-interactive-quote': index } as CSSProperties}
                key={quoteCard._key}
                onMouseOver={() => handleHover(index)}
                onFocus={() => handleHover(index)}
              >
                {/* Title */}
                <button
                  ref={(node: HTMLButtonElement) =>
                    ($titles.current[index] = node)
                  }
                  className={styles.quoteInteractiveTitleWrapper}
                  onMouseMove={handleMouseMove}
                  onClick={() => handleClick(index)}
                  onKeyDown={handleKeyDown}
                >
                  <p className={styles.quoteInteractiveTitle}>
                    {quoteCard.eyebrow}
                  </p>
                </button>
                {/* Card */}
                <div
                  ref={(node: HTMLDivElement) => ($cards.current[index] = node)}
                  className={styles.quoteInteractiveCardWrapper}
                >
                  <Glow
                    className={styles.quoteInteractiveGlowWrapper}
                    source={
                      quoteCard.image.glow && {
                        ...quoteCard.image.glow,
                      }
                    }
                  >
                    <Shadow className={styles.quoteInteractiveShadowWrapper}>
                      <button
                        ref={(node: HTMLButtonElement) =>
                          ($cardsInner.current[index] = node)
                        }
                        className={cn(
                          styles.quoteInteractiveCard,
                          showCard && index === currentIndex && styles.active,
                        )}
                        onKeyDown={handleKeyDown}
                        aria-label={dict('clickToViewTestimonial')}
                      >
                        <EnhancedMedia
                          overlay={quoteCard.image.overlay}
                          className={styles.quoteInteractiveMedia}
                        >
                          <Image
                            className={styles.quoteInteractiveMediaImage}
                            source={quoteCard.image.image}
                            isDisplayed={isInView !== false}
                            autoResize={false}
                            fixedAspectRatio={true}
                          />
                        </EnhancedMedia>

                        {quoteCard.logo && (
                          <Graphic
                            ref={(node: HTMLDivElement) =>
                              ($logos.current[index] = node)
                            }
                            className={styles.quoteInteractiveLogo}
                            source={quoteCard.logo.source}
                            isInView={true}
                            name={quoteCard.logo.name}
                          />
                        )}

                        <div data-index={index} className={styles.moreButton}>
                          <SvgPlusIcon className={styles.buttonIcon} />
                        </div>
                      </button>
                    </Shadow>
                  </Glow>
                </div>
              </li>
            );
          })}
        </ul>
        <ul
          className={cn(
            styles.quoteInteractive,
            styles.quoteInteractiveGradients,
            showCard && styles.hidden,
          )}
          aria-hidden="true"
        >
          {quoteCards.map((quoteCard: QuoteInteractiveCard) => (
            <li className={styles.quoteInteractive} key={quoteCard._key}>
              <p className={styles.quoteInteractiveTitle}>
                {quoteCard.eyebrow}
              </p>
            </li>
          ))}
        </ul>
      </Observer>
      {showModal && currentCardRect && (
        <QuoteInteractiveLgModal
          currentIndex={currentIndex}
          sourceRect={currentCardRect}
          quoteCards={quoteCards}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default QuoteInteractiveLg;
