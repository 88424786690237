'use client';
import { useState } from 'react';

import SvgCaret from '~/assets/svg/caret.svg';
import ButtonPlay from '~/components/atoms/Buttons/UI/ButtonPlay/ButtonPlay';
import ButtonToggle from '~/components/atoms/Buttons/UI/ButtonToggle/ButtonToggle';
import Image from '~/components/atoms/Image/Image';
import Observer from '~/components/atoms/Observer/Observer';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import ModalVideo from '~/components/molecules/Modal/ModalVideo/ModalVideo';
import Shadow from '~/components/molecules/Shadow/Shadow';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { dict } from '~/data/stores/Dictionary';
import decorationsStyles from '~/styles/theme/decorations.module.css';
import { cn } from '~/utils';

import styles from './VideoPlaylist.module.css';
import {
  CMSVideoPlaylistVideo,
  VideoPlaylistProps,
} from './VideoPlaylist.types';

/**
 * A playlist of videos that open in a modal
 * @param videosList Array of objects containing the videos' p
 * @param playButtonLabel Aria label for the play button, defaults to "Watch the Video"
 * @param className
 * @example <VideoPlaylist className={...} videosList={...} />
 */
const VideoPlaylist = (props: VideoPlaylistProps) => {
  const { className, playButtonLabel, videosList } = props;
  const [isPlaylistExpanded, setIsPlaylistExpanded] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const [currentItemIndex, setCurrentItemIndex] = useState<number>();

  const renderClasses = cn(
    styles.videoPlaylist,
    isPlaylistExpanded && styles.isPlaylistExpanded,
    className,
  );

  const selectItemAndShowVideo = (item: CMSVideoPlaylistVideo) => {
    setCurrentItemIndex(
      videosList.findIndex((videoItem) => videoItem === item),
    );

    setShowVideoModal(true);
  };

  const onVideoEnded = () => {
    // clear the current item index if modal video autoplays to the next video on the list, so the previous video can be selected again
    setCurrentItemIndex(undefined);
  };

  const onModalClose = () => {
    setCurrentItemIndex(undefined);
    setShowVideoModal(false);
  };

  return (
    <ModuleWrapper className={cn(renderClasses)} {...props}>
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '100% 0%' }}
        className={cn(styles.container, styles.containerVideoPlaylist)}
      >
        <div className={styles.columnFeatured}>
          <Shadow className={styles.dropShadow}>
            <button
              className={styles.featuredMediaWrapper}
              onClick={() => selectItemAndShowVideo(videosList[0])}
              data-button-play-trigger
            >
              <EnhancedMedia
                overlay={videosList[0].poster.overlay}
                className={styles.featuredPoster}
              >
                <Image
                  source={videosList[0].poster.image}
                  isDisplayed={isInView !== false}
                  fixedAspectRatio={true}
                />
              </EnhancedMedia>
              <div className={styles.featuredVideoInfoWrapper}>
                <div className={styles.featuredLabel}>
                  <span
                    className={cn(
                      styles.featuredTitle,
                      decorationsStyles.clampAndTruncate,
                    )}
                  >
                    {videosList[0].title}
                  </span>
                  <span className={styles.featuredSubtitle}>
                    {videosList[0].subtitle}
                  </span>
                </div>
                <div className={styles.featuredButtonWrapper}>
                  <ButtonPlay
                    className={cn(styles.buttonPlay, styles.featuredButton)}
                    aria-label={playButtonLabel}
                    tag={'span'}
                  />
                </div>
              </div>
            </button>
          </Shadow>
        </div>
        <div className={styles.columnVideoList}>
          <ul className={styles.videoListWrapper}>
            {videosList &&
              videosList.map((videoListItem, index) => {
                if (index === 0) return null;
                return (
                  <li
                    key={videoListItem._key}
                    className={styles.videoListItem}
                    data-button-play-trigger
                  >
                    <button
                      className={styles.itemThumbnailButton}
                      onClick={() => selectItemAndShowVideo(videoListItem)}
                      aria-label={dict('clickToWatchVideo')}
                    >
                      <EnhancedMedia
                        overlay={videoListItem.poster.overlay}
                        className={styles.itemThumbnail}
                      >
                        <Image
                          source={videoListItem.poster.image}
                          isDisplayed={isInView !== false}
                          fixedAspectRatio={true}
                        />
                      </EnhancedMedia>
                      <ButtonPlay
                        className={styles.itemPlayButton}
                        size="small"
                        tag={'div'}
                      />
                    </button>
                    <div className={styles.itemDescription}>
                      <button
                        className={styles.itemDescriptionButton}
                        onClick={() => selectItemAndShowVideo(videoListItem)}
                      >
                        <h4
                          className={cn(
                            styles.itemTitle,
                            decorationsStyles.clampAndTruncate,
                          )}
                        >
                          {videoListItem.title}
                        </h4>

                        <span className={styles.itemSubtitleWrapper}>
                          <h5 className={styles.itemSubtitle}>
                            {videoListItem.subtitle}
                          </h5>
                        </span>
                      </button>
                    </div>
                  </li>
                );
              })}
          </ul>
          <div className={styles.viewAllButtonWrapper}>
            <ButtonToggle
              icon={<SvgCaret />}
              onClick={(isExpanded) => setIsPlaylistExpanded(isExpanded)}
              labelIdle={dict('viewLess')}
              labelActive={dict('viewAll')}
            />
          </div>
        </div>
      </Observer>
      <ModalVideo
        selectedItemIndex={currentItemIndex}
        videosList={videosList}
        modalTriggeredByClick={showVideoModal}
        onModalClose={onModalClose}
        onVideoEnded={onVideoEnded}
      />
    </ModuleWrapper>
  );
};

export default VideoPlaylist;
