'use client';
import { useEffect, useState } from 'react';

import { PortableTextCustomValueData } from '~/components/molecules/PortableText/PortableText.types';
import { dict } from '~/data/stores/Dictionary';

import Hero from '../../Hero/Hero';
import { EnhancedMediaUtil, PortableTextUtil } from '../Live.utils';
import { LiveHeroProps } from './LiveHero.types';

const LiveHero = (props: LiveHeroProps) => {
  const [ctaLabel, setCtaLabel] = useState<string>(
    dict('fioLiveStreamRegisterCTALabel'),
  );
  const [ctaLinkURL, setCTALinkURL] = useState<string>(
    `/${props.event.slug}/register`,
  );

  useEffect(() => {
    if (new Date(props.event.date) < new Date(Date.now())) {
      setCtaLabel(dict('fioLiveStreamWatchCTALabel'));
      setCTALinkURL(`/${props.event.slug}`);
    }
  }, [props.event]);

  if (props.event === null) {
    return null;
  }

  const content: PortableTextCustomValueData = {
    blocks: PortableTextUtil.content(
      PortableTextUtil.svg(props.fioLiveLogo.markup),
      PortableTextUtil.title('title2', props.event.title),
      PortableTextUtil.body(
        props.event.shortDescription || props.event.description,
      ),
      PortableTextUtil.buttonGroup(
        PortableTextUtil.button(
          PortableTextUtil.internalLink(ctaLabel, ctaLinkURL),
        ),
      ),
    ),
    lockupOptions: {
      name: 'title2Block',
      columns: 10,
      alignment: 'left',
    },
  };

  const media = EnhancedMediaUtil.fromCMSImage(props.event?.cover_image);

  return (
    <Hero alignment="leftMiddle" content={content} media={media} {...props} />
  );
};

export default LiveHero;
