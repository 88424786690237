'use client';
import { ForwardedRef, forwardRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import { CMSImage, ImageFit } from '~/components/atoms/Image/Image.types';
import { CMSVideoProps, VideoRef } from '~/components/atoms/Video/Video.types';
import AutoplayingVideo from '~/components/molecules/AutoplayingVideo/AutoplayingVideo';

import { MediaProps } from './Media.types';

const Media = (
  props: MediaProps,
  ref: ForwardedRef<HTMLElement | VideoRef>,
) => {
  const {
    forceIsInView,
    forceUseMp4,
    className,
    contain,
    onReady,
    onVideoReady,
    onVideoEnded,
    playEndlessly,
    preload = false,
    sanityMedia,
    sizes,
    isDisplayed,
    fixedAspectRatio,
    willAutoplay,
  } = props;
  if (sanityMedia?.mediaType === 'image') {
    const imageRef = ref as ForwardedRef<HTMLElement>;

    const imageProps = sanityMedia as CMSImage;

    let conditionalProps = {};

    if (sizes) {
      if (preload) {
        conditionalProps = { ...conditionalProps, preload: true, sizes };
      } else {
        conditionalProps = { ...conditionalProps, sizes };
      }
    }

    let fit: ImageFit = {};

    if (fixedAspectRatio) {
      fit = { fixedAspectRatio: true };
    } else if (contain) {
      fit = { contain: true };
    }

    return (
      <Image
        source={imageProps}
        className={className}
        ref={imageRef}
        onReady={onReady}
        {...fit}
        {...conditionalProps}
        isDisplayed={isDisplayed}
      />
    );
  }

  if (sanityMedia?.mediaType === 'video') {
    const videoRef = ref as ForwardedRef<VideoRef>;
    const videoProps = sanityMedia as CMSVideoProps;
    return (
      <AutoplayingVideo
        ref={videoRef}
        aspectRatio={videoProps.aspectRatio}
        src={forceUseMp4 ? videoProps.mp4Url : videoProps.url}
        forceUseMp4={forceUseMp4}
        isLooping={videoProps.isLooping}
        forceIsInView={forceIsInView}
        className={className}
        onReady={onReady}
        onVideoReady={onVideoReady}
        onEnded={onVideoEnded}
        playEndlessly={playEndlessly}
        contain={contain}
        thumbnail={videoProps.thumbnail}
        willAutoplay={willAutoplay}
      />
    );
  }

  console.warn('Media Error: No valid Image or Video.', props);
  return null;
};

export default forwardRef(Media);
