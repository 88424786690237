import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Media from '~/components/molecules/Media/Media';
import { cn } from '~/utils';

import useDeviceStore from '../StandoutDevice.store';
import styles from './MediaLayer.module.css';
import { MediaLayerProps } from './MediaLayer.types';

const MediaLayer = ({ layer, innerClassNames }: MediaLayerProps) => {
  const baseMedia = layer?.default;
  const variantMedia = layer?.variant;
  const theme = useDeviceStore((state) => state.theme);
  const haveVariant = Object(variantMedia) === variantMedia;

  return (
    <div className={cn(styles.container, innerClassNames?.container)}>
      {baseMedia?.media?.sanityMedia && (
        <EnhancedMedia
          overlay={baseMedia?.overlay}
          className={cn(
            styles.base,
            innerClassNames?.baseMedia,
            haveVariant && theme === 'dark' && styles.active,
            !haveVariant && styles.active,
          )}
        >
          <Media
            sanityMedia={baseMedia?.media?.sanityMedia}
            quality={90}
            forceIsInView={true}
            autoResize={false}
            willAutoplay={false}
            isLooping={true}
            fixedAspectRatio={true}
          />
        </EnhancedMedia>
      )}
      {variantMedia?.media?.sanityMedia && (
        <EnhancedMedia
          overlay={variantMedia?.overlay}
          className={cn(
            styles.variant,
            innerClassNames?.variantMedia,
            haveVariant && theme === 'light' && styles.active,
          )}
        >
          <Media
            sanityMedia={variantMedia?.media?.sanityMedia}
            quality={90}
            forceIsInView={true}
            autoResize={false}
            willAutoplay={false}
            isLooping={true}
            fixedAspectRatio={true}
          />
        </EnhancedMedia>
      )}
    </div>
  );
};

export default MediaLayer;
