import { KeenSliderInstance } from 'keen-slider/react';
import { RefObject } from 'react';

import { getSpacer, keenSliderConfig } from '~/utils';
import getKeenSliderSlidesPerView from '~/utils/getKeenSliderSlidesPerView';

import { CarouselVariants } from '../Carousel.types';

export const getSliderOptions = ({
  $dummyGrid,
  variant,
  onCreated,
  onDetailsChanged,
  onSlideChanged,
}: {
  $dummyGrid: RefObject<HTMLDivElement>;
  variant: CarouselVariants;
  onCreated: (slider: KeenSliderInstance) => void;
  onDetailsChanged: () => void;
  onSlideChanged: () => void;
}) => {
  const getConfigForLayout = (breakpoint: string = 'sm') => {
    if (variant === 'oneCard') {
      switch (breakpoint) {
        case 'sm':
          // TODO: investigate why loop option needs to be specificed for each breakpoint for it to work on after resizing
          return {
            loop: true,
            slides: {
              perView: 1,
            },
          };
        case 'md':
        case 'lg':
          const spacerValue =
            breakpoint === 'lg' ? getSpacer(64) : getSpacer(40);
          return {
            loop: true,
            slides: {
              spacing: spacerValue,
              origin: 'center',
              // using the window width and grid width, calculate how many slides are visible on the screen if the active slide is set to the grid width
              perView: getKeenSliderSlidesPerView($dummyGrid, spacerValue, 1),
            },
          };
      }
    } else {
      const spacerValue = getSpacer(24);
      switch (breakpoint) {
        case 'sm':
          return {
            loop: false,

            slides: {
              spacing: spacerValue,
              perView: getKeenSliderSlidesPerView(
                $dummyGrid,
                spacerValue,
                0.82,
              ),
            },
          };
        case 'md':
        case 'lg':
          return {
            loop: false,
            slides: {
              spacing: spacerValue,
              perView: 2,
            },
          };
      }
    }
  };

  return {
    ...keenSliderConfig.defaultConfig,
    selector: '.slide',
    loop: true,

    breakpoints: {
      [keenSliderConfig.breakpoints.sm.mediaQuery]: {
        ...getConfigForLayout(),
      },

      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        ...getConfigForLayout('md'),
      },

      [keenSliderConfig.breakpoints.lg.mediaQuery]: {
        ...getConfigForLayout('lg'),
      },
    },
    created: (slider: KeenSliderInstance) => {
      onCreated(slider);
    },
    detailsChanged: () => {
      onDetailsChanged();
    },
    slideChanged: () => {
      // this doesn't get triggered until the slide is settled
      onSlideChanged();
    },
  };
};
