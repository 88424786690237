import { gsap } from 'gsap';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Graphic from '~/components/atoms/Graphic/Graphic';
import Observer from '~/components/atoms/Observer/Observer';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import Shadow from '~/components/molecules/Shadow/Shadow';
import UIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';

import styles from './SingleImageMediaContainer.module.css';
import {
  SingleImageMediaContainerProps,
  SingleImageMediaContainerRef,
} from './SingleImageMediaContainer.types';

const intMaxParallax = parseInt(styles.maxParallax);
const intMaxParallaxSm = parseInt(styles.maxParallaxSm);

const SingleImageMediaContainer = (
  {
    className,
    media,
    innerClassNames,
    mediaLayout,
    shouldParallax = true,
    $scrollWrapper,
    logo,
    alignment,
  }: SingleImageMediaContainerProps,
  ref: ForwardedRef<SingleImageMediaContainerRef>,
) => {
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const progressSetter = useRef<(value: number) => void>();

  const $root = useRef<HTMLDivElement>(null);
  const mediaRef = useRef<HTMLElement | VideoRef>(null);

  useLayoutEffect(() => {
    // We apply the translation value for the parallax effect only if it's not in a device
    if (mediaRef.current) {
      let $element: HTMLElement | undefined;

      // The HTML element to update differs based on the type of media
      if ('$wrapper' in mediaRef.current && mediaRef.current.$wrapper.current) {
        $element = mediaRef.current.$wrapper.current;
      } else if (mediaRef.current instanceof HTMLElement) {
        $element = mediaRef.current;
      }

      // If we did find an HTML element to update, then we update it through GSAP
      if (typeof $element !== 'undefined' && media) {
        progressSetter.current = gsap.quickSetter(
          '$wrapper' in mediaRef.current
            ? mediaRef.current.$wrapper.current
            : mediaRef.current,
          'y',
          'rem',
        ) as (value: number) => void;
      }
    }
  }, [media]);

  const onProgress = useCallback(
    (progress: number) => {
      if (progressSetter.current && shouldParallax) {
        const roundedProgress = Math.round(progress * 1000) / 1000;
        progressSetter.current(
          roundedProgress *
            (UIStore.getState().breakpoint?.name === 'sm'
              ? intMaxParallaxSm
              : intMaxParallax) *
            -1,
        );
      }
    },
    [shouldParallax],
  );

  useScrollProgress($scrollWrapper, onProgress);

  useImperativeHandle(
    ref,
    () => ({
      mediaRef,
    }),
    [],
  );

  const Wrapper = mediaLayout === 'bleed' ? GlassWrapper : 'div';
  // assembling props here as otherwise typescript complains about non-standard
  // dom properties being set on a <div> element.
  const WrapperProps =
    mediaLayout === 'bleed'
      ? { contentClassName: styles.mediaWrapperContent }
      : {};

  const mediaAspectRatio =
    'asset' in media.sanityMedia
      ? media.sanityMedia.asset.aspectRatio
      : media.sanityMedia.aspectRatio;

  return (
    <Observer
      ref={$root}
      className={cn(
        styles.singleImageMediaContainer,
        shouldParallax && styles.hasParallax,
        mediaLayout === 'bleed' && styles.isBleed,
        alignment && styles[alignment],
        className,
      )}
      callback={updateIsInView}
      options={{ rootMargin: '200% 0%' }}
    >
      {logo && (
        <Graphic
          {...logo}
          className={styles.logo}
          isInView={isInView !== false}
        />
      )}
      <Shadow className={styles.shadow}>
        <Wrapper
          className={cn(styles.mediaWrapper, innerClassNames?.mediaWrapper)}
          style={
            {
              ...(mediaAspectRatio
                ? { '--media-aspect-ratio': mediaAspectRatio }
                : {}),
            } as CSSProperties
          }
          {...WrapperProps}
        >
          <Media
            ref={mediaRef}
            sanityMedia={media.sanityMedia}
            className={cn(styles.media, innerClassNames?.media)}
            isDisplayed={isInView !== false}
            fixedAspectRatio={true}
          />
        </Wrapper>
      </Shadow>
    </Observer>
  );
};

export default forwardRef<
  SingleImageMediaContainerRef,
  SingleImageMediaContainerProps
>(SingleImageMediaContainer);
