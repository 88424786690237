import { gsap } from 'gsap';

import BackgroundImage from '~/components/molecules/SectionBackgrounds/BackgroundImage/BackgroundImage';
import { assert } from '~/utils/assert';

import { HeroGradientBackground } from '../../molecules/SectionBackgrounds/HeroGradient/HeroGradient';
import { SectionBackgroundComponent } from './PageSection.types';

/**
 * Given a component type return an instance of the background component if
 * it exists, otherwise null.
 *
 * @param type The component type
 * @returns A background component or null
 */
export function getSectionBackgroundComponent(
  props: SectionBackgroundComponent,
): JSX.Element | null {
  const type = props._type;
  switch (type) {
    case 'sectionBackground.heroGradient':
      return <HeroGradientBackground key={props._key} {...props} />;
    case 'sectionBackground.backgroundImage':
      return <BackgroundImage key={props._key} {...props} />;
    default:
      assert.never(type);
  }
  return null;
}

export const opacityTransform = gsap.utils.pipe(
  gsap.utils.clamp(0.7, 1),
  gsap.utils.mapRange(0.7, 1, 0, 1),
);
