import { RefObject } from 'react';

import { CMSImage } from '~/components/atoms/Image/Image.types';
import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { PortableTextCustomValueData } from '~/components/molecules/PortableText/PortableText.types';

import { CMSModuleBase } from '../../ModularComponent/ModularComponent.types';

// how many cards to distribute per column
export const CARDS_PER_COLUMN = 2;

// the column offset shifts the columns Y position
export const COLUMN_OFFSETS = [0, -0.1, -0.15, -0.05];

export type StandoutMosaicProps = CMSModuleBase & {
  title: PortableTextCustomValueData;
  deviceUI: CMSEnhancedMedia;
  mosaicCards: CMSImage[];
};

export type MosaicCard = {
  index: number;
  element: RefObject<HTMLDivElement>;
  image: CMSImage;
};

export type MosaicColumn = {
  index: number;
  element: RefObject<HTMLDivElement>;
  cards: MosaicCard[];
  offset: number;
};
