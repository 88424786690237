'use client';

import { Flip, gsap } from 'gsap/all';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import SvgPlayIcon from '~/assets/svg/visuallyCenteredPlay.svg';
import { ForwardedButtonRef } from '~/components/atoms/Buttons/Ctas/Button/Button.types';
import ButtonBase from '~/components/atoms/Buttons/Ctas/ButtonBase';
import ButtonClose from '~/components/atoms/Buttons/UI/ButtonClose/ButtonClose';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import PlayableVideo from '~/components/molecules/PlayableVideo/PlayableVideo';
import { dict } from '~/data/stores/Dictionary';
import UIStore from '~/state/ui';
import { VIDEO_MODAL_WHEEL_THRESHOLD } from '~/types/decorations';
import { cn, isBreakpointOrGreater, useScrollProgress } from '~/utils';
import ClientOnlyPortal from '~/utils/ClientOnlyPortal/ClientOnlyPortal';
import isPointInRectangle from '~/utils/math/isPointInRectangle';
import { tickerAddOnce } from '~/utils/ticker';
import useFocusTrap from '~/utils/useFocusTrap';
import useMouseDistance from '~/utils/useMouseDistance/useMouseDistance';

import Image from '../../../atoms/Image/Image';
import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import {
  closeVideoAnimation,
  hide,
  openVideoAnimation,
  show,
} from './FloatingActionButton.animations';
import styles from './FloatingActionButton.module.css';
import { CMSFloatingActionButton } from './FloatingActionButton.types';
import { clearMagnetism } from './FloatingActionButton.utils';
gsap.registerPlugin(Flip);

/**
 * Component displaying a sticky preview of a video in the bottom right corner
 * of the screen on md+. The preview will open full screen on click similar to
 * a modal.
 * @param props CMSFloatingActionButton
 * @param props.video CMSVideoProps - The video data
 * @param props.label String - The text content
 * @param props.className String - Classname from the parent component
 * overlaying the thumbnail
 */
const FloatingActionButton = (props: CMSFloatingActionButton) => {
  const windowWidth = UIStore((state) => state.windowWidth);
  const { video, label, className } = props;
  // Used to mark the top of the FAB before it starts to stick
  const $floatingButtonContainer = useRef<HTMLDivElement>(null);
  // Used to show the FAB at the end of the section
  const $dummyFullSection = useRef<HTMLDivElement>(null);
  const fabTopPosition = useRef<number>(0);
  const wasFabPreviewVisible = useRef<boolean>(false);
  const $componentWrapper = useRef<HTMLElement>(null);
  const $element = useRef<HTMLDivElement>(null);
  const $video = useRef<VideoRef>(null);
  const $label = useRef<HTMLDivElement>(null);
  const $modalCloseButton = useRef<ForwardedButtonRef>(null);
  const $modalPlaceholder = useRef<HTMLDivElement>(null);
  const $modalBackground = useRef<HTMLButtonElement>(null);
  const $modalVideoWrapper = useRef<HTMLDivElement>(null);
  const [isFabVisible, setIsFabVisible] = useState(false);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const wheelAmountRef = useRef<number>(0);

  const [isPlaying, setIsPlaying] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const breakpointRef = useRef(UIStore.getState().breakpoint);

  const id = useId();

  const mouseDistanceWrapperRect = useRef<DOMRect>();

  // Handle the preview playing state based on the FAB's visibility
  // We can't use the Video's logic because useScrollProgress is not working as expected on a sticky element
  const onScrollProgressFullSectionVisible = useCallback(
    (progress: number, isVisible: boolean) => {
      if (isVisible !== wasFabPreviewVisible.current) {
        if (isVisible) {
          setIsFabVisible(true);
        } else {
          setIsFabVisible(false);
        }
      }
      wasFabPreviewVisible.current = isVisible;
    },
    [],
  );
  useScrollProgress($dummyFullSection, onScrollProgressFullSectionVisible);

  // The dummy needs to be aligned with the top of the FAB
  useEffect(() => {
    fabTopPosition.current = $floatingButtonContainer.current?.offsetTop || 0;
  }, [windowWidth]);

  useEffect(() => {
    if ($element.current && $label.current) {
      if (isFabVisible) {
        show({ $element: $element.current, $label: $label.current });
      } else {
        hide({ $element: $element.current, $label: $label.current });
      }
    }
  }, [isFabVisible]);

  const closeVideo = useCallback(() => {
    UIStore.getState().setIsNavigationBarVisible(true);
    UIStore.getState().setIsScrollLocked(false);

    window.removeEventListener('keydown', handleKeyDown);
    window.removeEventListener('wheel', handleWheel);

    if (navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/)) {
      $video.current?.$player?.removeEventListener(
        'webkitendfullscreen',
        closeVideo,
      );
      $video.current?.pause();
      setIsModalOpen(false);
      setIsPlaying(false);
    } else {
      tickerAddOnce(() => {
        if (
          $modalPlaceholder.current &&
          $video.current &&
          $modalCloseButton.current
        ) {
          closeVideoAnimation({
            $video: $video.current,
            $modalPlaceholder,
            $modalBackground,
            $modalVideoWrapper,
            $modalCloseButton: $modalCloseButton.current.$button,
            callback: () => {
              $video.current?.pause();
              setIsModalOpen(false);
              setIsPlaying(false);
            },
          });
        }
      });
    }
    return () => {
      if (navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/)) {
        $video.current?.$player?.removeEventListener(
          'webkitendfullscreen',
          closeVideo,
        );
      }
    };
  }, []);

  const openVideo = () => {
    setIsModalOpen(true);
    $video.current?.restart();

    if (navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/)) {
      setIsPlaying(true);
    } else {
      UIStore.getState().setIsNavigationBarVisible(false);

      tickerAddOnce(() => {
        if (
          $modalPlaceholder.current &&
          $video.current &&
          $modalCloseButton.current
        ) {
          openVideoAnimation({
            $video: $video.current,
            $modalPlaceholder,
            $modalBackground,
            $modalVideoWrapper,
            $modalCloseButton: $modalCloseButton.current.$button,
          });
          setIsPlaying(true);
          window.addEventListener('keydown', handleKeyDown);
          window.addEventListener('wheel', handleWheel);
        }
      });
    }
  };

  const onVideoReady = useCallback(() => {
    if (navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad)/)) {
      // mobile player exit fullscreen
      $video.current?.$player?.addEventListener(
        'webkitendfullscreen',
        closeVideo,
        { once: true },
      );
    }
  }, [closeVideo]);

  const handleButtonClose = useCallback(() => {
    closeVideo();
    if ($componentWrapper.current) {
      $componentWrapper.current.focus();
    }
    // TODO: Adress this eslint warning by adding the dependency and wrapping functions in useCallbacks
    // Disabling the linter as we don't expect closeVideo to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles trapping the focus when the video modal is open
   */
  const onFocusTrapKeyDown = useFocusTrap($modalVideoWrapper);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        handleButtonClose();
      }
      if (onFocusTrapKeyDown) {
        onFocusTrapKeyDown(event);
      }
    },
    // Disabling the linter as we don't expect closeVideo to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFocusTrapKeyDown, handleButtonClose],
  );

  /**
   * Handles closing the video on scroll
   */
  const handleWheel = useCallback(
    (event: WheelEvent) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      wheelAmountRef.current += event.deltaY;
      if (Math.abs(wheelAmountRef.current) > VIDEO_MODAL_WHEEL_THRESHOLD) {
        wheelAmountRef.current = 0;
        closeVideo();
      }

      // reset wheel value if scroll is not happening for a certain amount of time
      timeoutRef.current = setTimeout(() => {
        wheelAmountRef.current = 0;
      }, 100);
    },
    // Keeping the deps array empty as we don't expect closeVideo to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Handles resize
   */
  useEffect(() => {
    const reset = () => {
      closeVideo();
      clearMagnetism({
        $componentWrapper,
      });
    };

    const unsubscribeBreakpoint = UIStore.subscribe(
      (state) => state.breakpoint,
      (newBreakpoint) => {
        if (document.fullscreenElement === null) {
          breakpointRef.current = newBreakpoint;
          reset();
        }
      },
    );

    const unsubscribeWindowHeight = UIStore.subscribe(
      (state) => state.windowHeight,
      () => {
        if (isBreakpointOrGreater(breakpointRef.current, 'md')) {
          if (document.fullscreenElement === null) {
            reset();
          }
        }
      },
    );

    const updateMouseDistanceWrapperRect: ResizeObserverCallback = (
      entries,
    ) => {
      for (const entry of entries) {
        mouseDistanceWrapperRect.current = entry.contentRect;
      }
    };

    const resizeObserver = new ResizeObserver(updateMouseDistanceWrapperRect);

    if ($componentWrapper.current) {
      resizeObserver.observe($componentWrapper.current);
    }

    return () => {
      unsubscribeBreakpoint();
      unsubscribeWindowHeight();
      resizeObserver.disconnect();
    };
    // Keeping the deps array empty as we don't expect closeVideo to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the magnetism of the preview
   */

  // This variable represents the state of the magnetism of the floating preview
  const isResting = useRef(false);

  useMouseDistance(
    $componentWrapper,
    (distance: number, { x, y }) => {
      if (breakpointRef.current?.name === 'sm') {
        clearMagnetism({ $componentWrapper });
      } else if (!isModalOpen && mouseDistanceWrapperRect.current) {
        let translateX = 0;
        let translateY = 0;

        let resting = true;

        const isInField = isPointInRectangle(
          x,
          y,
          mouseDistanceWrapperRect.current.width,
          mouseDistanceWrapperRect.current.height,
        );

        if (isInField) {
          resting = false;
          translateX = x / -5;
          translateY = y / -5;
        }

        if (!resting || resting !== isResting.current) {
          isResting.current = resting;
          gsap.to($componentWrapper.current, {
            x: translateX,
            y: translateY,
            duration: resting ? 1.2 : 0.7,
            ease: resting ? 'elastic.out(1, 0.75)' : 'power1.out',
          });
        }
      }
    },
    { origin: 'center' },
  );

  return (
    <>
      {/* This div is used to mesure the sections' height */}
      <div
        ref={$dummyFullSection}
        className={styles.dummyFullSection}
        style={
          {
            '--fab-top-position': `${fabTopPosition.current}px`,
          } as CSSProperties
        }
      ></div>
      <ModuleWrapper
        className={cn(
          styles.floatingActionButtonContainer,
          props.hasSpacers?.before && styles.overrideSpacerBefore,
          props.hasSpacers?.after && styles.overrideSpacerAfter,
          className,
        )}
        {...props}
        // override the spacers so the sticky element functions properly. it will be applied to the prev and next elements.
        hasSpacers={{
          after: [],
          before: [],
        }}
        ref={$floatingButtonContainer}
      >
        <ButtonBase
          className={styles.floatingActionButton}
          ref={$componentWrapper}
          onClick={openVideo}
          customTrackingEvent={`video-opened-${label}`}
        >
          <div className={styles.hoverWrapper} ref={$element}>
            <div className={styles.videoPreviewWrapper}>
              {video.thumbnail && (
                <Image
                  source={video.thumbnail}
                  className={styles.videoPreview}
                  fixedAspectRatio={true}
                />
              )}
              <SvgPlayIcon className={styles.icon} />
            </div>
            <div ref={$label} className={styles.label}>
              <span className={styles.labelInnerText}>{label}</span>
            </div>
          </div>
        </ButtonBase>
      </ModuleWrapper>

      <ClientOnlyPortal selector="body">
        <div className={styles.modalPlaceholder} ref={$modalPlaceholder}>
          <button
            className={styles.modalBackground}
            ref={$modalBackground}
            onClick={closeVideo}
            tabIndex={-1}
          >
            <span className="visuallyHidden">{dict('clickToClose')}</span>
          </button>
          <div
            className={styles.modalVideoWrapper}
            data-id={id}
            ref={$modalVideoWrapper}
          >
            {isModalOpen && (
              <PlayableVideo
                {...video}
                src={video.url}
                className={styles.modalVideo}
                forceAutoplay={isPlaying}
                ref={$video}
                shouldFocusControls={isPlaying}
                onVideoReady={onVideoReady}
              />
            )}
            <ButtonClose
              className={styles.modalCloseButton}
              ref={$modalCloseButton}
              onClick={handleButtonClose}
            />
          </div>
        </div>
      </ClientOnlyPortal>
    </>
  );
};

export default FloatingActionButton;
