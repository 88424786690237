'use client';
import { gsap } from 'gsap';
import {
  KeenSliderInstance,
  TrackDetails,
  useKeenSlider,
} from 'keen-slider/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import ButtonCarouselArrow from '~/components/atoms/Buttons/UI/ButtonCarouselArrow/ButtonCarouselArrow';
import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Image from '~/components/atoms/Image/Image';
import Observer from '~/components/atoms/Observer/Observer';
import Shadow from '~/components/molecules/Shadow/Shadow';
import useUIStore from '~/state/ui';
import globalStyles from '~/styles/global.module.css';
import blurredImagesStyles from '~/styles/theme/blurredImages.module.css';
import { cn, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import calculateRelativeInstanceDistance from '~/utils/calculateRelativeInstanceDistance';
import calculateSlideVisibility from '~/utils/calculateSlideVisibility';

import CarouselWithToutsTextBlock from '../CarouselWithToutsTextBlock/CarouselWithToutsTextBlock';
import styles from './CarouselWithToutsDesktop.module.css';
import {
  CMSModuleCarouselWithToutsDesktop,
  SLIDE_DURATION,
} from './CarouselWithToutsDesktop.types';
import CarouselWithToutsSlide from './CarouselWithToutsSlide/CarouselWithToutsSlide';
import { CMSCarouselWithToutsSlide } from './CarouselWithToutsSlide/CarouselWithToutsSlide.types';

/**
 * Component that handles the keen slider slider functionality for the Carousel Tabber.
 * @param slides An array of slide data objects
 * @param className
 * @example <CarouselWithToutsDesktop slides={slides]/>
 */
const CarouselWithToutsDesktop = ({
  slides,
  hasBlurredBackground,
  className,
}: CMSModuleCarouselWithToutsDesktop) => {
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  // updates on click of label pagination, to set the active label text and to move carousel
  const [clickedLabelIndex, setClickedLabelIndex] = useState(0);
  const [currentSlideTransitionedIndex, setCurrentSlideTransitionedIndex] =
    useState(0);
  const currentTransitionedSlideRef = useRef(0);

  // details for the image carousel, updated on carousel move
  const details = useRef<TrackDetails>();
  // the details for the label carousel, update on carousel move
  const prevSwipeProgress = useRef<number | null>(null);
  const destLabelIndex = useRef(0);

  const $slidesViewport = useRef<HTMLDivElement>(null);
  const $slides = useRef<HTMLLIElement[]>([]);
  const $labels = useRef<HTMLButtonElement[]>([]);
  const $blurs = useRef<HTMLDivElement[]>(Array(slides.length));
  const $blurDim = useRef<HTMLDivElement>(null);
  const slideAspectRatios = useRef<number[]>(Array(slides.length));

  const isComputedStyleComplete = useUIStore(
    (state) => state.isComputedStyleComplete,
  );

  // initialize image slider
  const [sliderRef, imageSliderInstance] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.slide',
    disabled: true,

    detailsChanged(slider) {
      if (prevSwipeProgress.current === null) {
        prevSwipeProgress.current = slider.track.details.progress;
      }
      details.current = slider.track.details;
      onImageSliderMove();
    },
    animationEnded: (slider) => {
      prevSwipeProgress.current = null;
      checkCurrentSlide(slider);
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: false,
      },
    },
  });

  useEffect(() => {
    slideAspectRatios.current = slides.map((slide) => {
      const {
        media: { sanityMedia },
      } = slide.media;
      let aspectRatio;

      switch (sanityMedia.mediaType) {
        case 'image':
          aspectRatio = sanityMedia.asset.aspectRatio;
          break;
        case 'video':
          aspectRatio = sanityMedia.thumbnail?.asset.aspectRatio;
          break;
      }
      return aspectRatio || 1;
    });
  }, [slides]);

  const goToPrev = useCallback(() => {
    imageSliderInstance.current?.prev();
  }, [imageSliderInstance]);

  const goToNext = useCallback(() => {
    imageSliderInstance.current?.next();
  }, [imageSliderInstance]);

  const checkCurrentSlide = useCallback(
    (slider: KeenSliderInstance) => {
      const currentIndex = slider.track.details.rel;
      setCurrentSlideTransitionedIndex(currentIndex);
      setClickedLabelIndex(currentIndex);
      currentTransitionedSlideRef.current = currentIndex;
    },
    [setCurrentSlideTransitionedIndex, setClickedLabelIndex],
  );

  useEffect(() => {
    const slider = imageSliderInstance.current;
    if (slider) {
      if (isComputedStyleComplete) {
        slider.update();
        checkCurrentSlide(slider);
      }
    }
  }, [imageSliderInstance, isComputedStyleComplete, checkCurrentSlide]);

  const paginationClick = (index: number) => {
    // if it's in between 2 slides / currently animating, prevent re-render (currentSlideIndex will be set on animation complete)
    if (
      imageSliderInstance.current &&
      imageSliderInstance.current.track.details.position % 1 === 0
    ) {
      setClickedLabelIndex(index);
    }

    imageSliderInstance.current?.moveToIdx(index, false, {
      duration: SLIDE_DURATION * 1000,
    });
  };

  function moveSlides() {
    let direction = 1;

    if (!details.current) return;

    const { progress, slides, position } = details.current;
    if (prevSwipeProgress.current && prevSwipeProgress.current < progress) {
      direction = 1;
    } else if (
      prevSwipeProgress.current &&
      prevSwipeProgress.current > progress
    ) {
      direction = -1;
    }

    const dest = direction === 1 ? Math.ceil(position) : Math.floor(position);

    slides.forEach((slide, i) => {
      const overlayOpacity = slide.portion * 100;
      let x;

      if (position - currentTransitionedSlideRef.current === 0) {
        // slider has stopped animating, set all slides to translate 0
        x = 0;
      } else {
        if (
          (i === currentTransitionedSlideRef.current && direction === 1) ||
          (i === dest && direction === -1)
        ) {
          x = -slide.distance * 50;
        } else {
          x = 0;
        }
      }

      gsap.set($slides.current[i], {
        '--slide-overlay-opacity': `${overlayOpacity}%`,
        '--slide-x': `${x}%`,
      });
    });
  }

  const highlightClosestLabel = () => {
    if (details.current && $labels.current.length) {
      const { position } = details.current;
      const nearestIndex = Math.round(position);
      if (nearestIndex !== destLabelIndex.current) {
        destLabelIndex.current = nearestIndex;
        $labels.current.forEach((el, index) => {
          if (index !== nearestIndex) el.classList.remove(styles.active);
        });
        $labels.current[nearestIndex].classList.add(styles.active);
      }
    }
  };

  const showBlur = () => {
    if (details.current) {
      const { progress, slides } = details.current;
      const progresses = calculateSlideVisibility(
        slides.length,
        progress,
        false,
      );
      for (let slideIndex = 0; slideIndex < slides.length; slideIndex++) {
        gsap.set($blurs.current[slideIndex], {
          visibility: 'visible',
          opacity: progresses[slideIndex],
        });
      }
    }
  };

  function onImageSliderMove() {
    moveSlides();
    highlightClosestLabel();
    showBlur();
  }

  return (
    <div className={cn(styles.desktopCarouselContainer, className)}>
      {hasBlurredBackground && (
        <div className={blurredImagesStyles.blurs}>
          {slides.map((slide, i) => {
            let blur;
            const {
              media: { sanityMedia },
            } = slide.media;

            switch (sanityMedia.mediaType) {
              case 'image':
                blur = sanityMedia;
                break;
              case 'video':
                blur = sanityMedia.thumbnail;
                break;
            }
            const relativeDistance = calculateRelativeInstanceDistance(
              slides.length,
              i,
              currentSlideTransitionedIndex,
              false,
            );

            return (
              <div
                className={blurredImagesStyles.blurWrapper}
                key={slide._key}
                ref={(node) => {
                  if (node) {
                    $blurs.current[i] = node;
                  }
                }}
                style={{
                  aspectRatio: slideAspectRatios.current[i],
                }}
              >
                {blur && (
                  <Image
                    source={blur}
                    className={blurredImagesStyles.blur}
                    dpr={1}
                    blur={300}
                    quality={100}
                    isDisplayed={isInView !== false && relativeDistance <= 1}
                  />
                )}
              </div>
            );
          })}
          <div className={blurredImagesStyles.blurDim} ref={$blurDim} />
        </div>
      )}
      <Observer
        className={styles.carouselContainer}
        options={{ rootMargin: '200% 0%' }}
        callback={updateIsInView}
      >
        <Shadow className={styles.shadow}>
          <GlassWrapper className={styles.carouselDeviceWrapper}>
            {/* Slides */}
            <div className={styles.slidesViewport} ref={$slidesViewport}>
              <div className={styles.slidesInner}>
                <ul className={styles.slides} ref={sliderRef}>
                  {slides.map(
                    (slide: CMSCarouselWithToutsSlide, slideIndex: number) => (
                      <li
                        key={slide._key}
                        id={slide._key}
                        className={cn(styles.slide, 'slide')}
                        aria-labelledby={`${slide._key}-label`}
                        ref={(element) =>
                          addToRefArray({
                            element,
                            refArray: $slides,
                            index: slideIndex,
                          })
                        }
                      >
                        <div className={styles.slideInner}>
                          <CarouselWithToutsSlide
                            media={slide.media}
                            isActive={
                              slideIndex === currentSlideTransitionedIndex
                            }
                            isInView={isInView !== false}
                            index={slideIndex}
                          />
                        </div>
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </div>
          </GlassWrapper>
        </Shadow>
        <div className={globalStyles.carouselArrowsContainer}>
          <ButtonCarouselArrow
            className={cn(
              globalStyles.carouselArrowsButtonPrev,
              currentSlideTransitionedIndex === 0 &&
                globalStyles.carouselArrowsButtonDisabled,
            )}
            iconDirection={'left'}
            onClick={goToPrev}
          />
          <ButtonCarouselArrow
            className={cn(
              globalStyles.carouselArrowsButtonNext,
              currentSlideTransitionedIndex === slides.length - 1 &&
                globalStyles.carouselArrowsButtonDisabled,
            )}
            iconDirection={'right'}
            onClick={goToNext}
          />
        </div>
      </Observer>
      <div className={styles.descriptionsContainer}>
        <div className={styles.descriptionsSubgrid}>
          {slides.map((slide: CMSCarouselWithToutsSlide, index: number) => {
            return (
              <button
                className={cn(
                  styles.descriptionButton,
                  index === clickedLabelIndex && styles.active,
                )}
                key={`slide=${slide._key}`}
                onClick={() => {
                  paginationClick(index);
                }}
                ref={(element) =>
                  addToRefArray({
                    element,
                    refArray: $labels,
                    index: index,
                  })
                }
              >
                <CarouselWithToutsTextBlock
                  text={slide.text}
                  className={styles.slideDescription}
                />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CarouselWithToutsDesktop;
