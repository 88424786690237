/**
 * The assert utility is used for runtime assertions. Any condition that
 * does not strictly evaluate to true will be considered an error.
 *
 * @param condition A condition that evaluates to a boolean
 * @param message Optional assertion error message
 */
export function assert(condition: boolean, message?: string) {
  if (condition !== true) {
    throw new Error(message || 'Assertion Error');
  }
}

/**
 * The assert.never helper is used to simulate an exhaustive switch statement
 * when switching over literal types. It provides a guard that will fail
 * during typecheck unless all options are accounted for in a switch.
 */
assert.never = (value: never): never => {
  throw new Error(`Unreachable code for ${value}`);
};
