import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { PortableTextCustomValueData } from '~/components/molecules/PortableText/PortableText.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

export type CarouselMediaProps = CMSModuleBase & {
  text?: PortableTextCustomValueData;
  slides: (CMSEnhancedMedia & { _key: string })[];
};

export const MOBILE_MAX_VISIBLE_SLIDES = 4;
