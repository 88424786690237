'use client';
import {
  KeenSliderInstance,
  KeenSliderOptions,
  useKeenSlider,
} from 'keen-slider/react';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

import ButtonCarouselArrow from '~/components/atoms/Buttons/UI/ButtonCarouselArrow/ButtonCarouselArrow';
import Observer from '~/components/atoms/Observer/Observer';
import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import UIStore from '~/state/ui';
import useUIStore from '~/state/ui';
import globalStyles from '~/styles/global.module.css';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import Easing, { EaseType } from '~/utils/singletons/Easing';

import styles from './Carousel.module.css';
import { CarouselProps } from './Carousel.types';
import CarouselSlide from './CarouselSlide/CarouselSlide';
import carouselSlideStyles from './CarouselSlide/CarouselSlide.module.css';
import { ForwardedCarouselSlideRef } from './CarouselSlide/CarouselSlide.types';
import { getSliderOptions } from './utils/getSliderOptions';

/**
 * Quote carousel component
 * @param slides An array of slide data objects
 * @param className
 * @example <Carousel slides={slides} />
 */
const Carousel = (props: CarouselProps) => {
  const { accentType, slides, variant = 'oneCard', className } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const lastIndex = useRef<number>(-1);
  const maxIndex = useRef<number>();
  const refSlides = useRef<ForwardedCarouselSlideRef[]>(Array(slides.length));
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  const $dummyGrid = useRef<HTMLDivElement>(null);
  const breakpoint = useUIStore((state) => state.breakpoint);
  const isMobile = breakpoint?.name === 'sm';

  const calculateSlidesActive = (slider?: KeenSliderInstance) => {
    const instance = slider || instanceRef?.current;

    if (!instance) return;

    const activeSlides: number[] = [];

    if (isMobile || variant === 'oneCard') {
      activeSlides.push(instance?.track?.details?.rel as number);
    } else {
      activeSlides.push(instance?.track?.details?.rel as number);
      activeSlides.push((instance?.track?.details?.rel as number) + 1);
    }

    refSlides.current.forEach((item, i) => {
      const $element = item?.$element?.current;
      if (!$element) return;
      $element.classList[activeSlides.includes(i) ? 'add' : 'remove'](
        carouselSlideStyles.isActive,
      );
    });
  };

  const onCreated = (slider: KeenSliderInstance) => {
    maxIndex.current = slider.track.details.maxIdx;
    calculateSlidesActive(slider);
  };

  const onSlideChanged = () => {
    checkCurrentSlide();
    calculateSlidesActive();
  };

  const onDetailsChanged = () => {
    const progresses = instanceRef.current?.track.details.slides.map(
      (slide) => slide.portion,
    );
    progresses?.forEach((progress: number, slideIndex: number) => {
      const slide = refSlides.current[slideIndex];
      if (slide) slide.setProgress(progress);
    });

    checkCurrentSlide();
  };

  const sliderOptions: KeenSliderOptions = getSliderOptions({
    $dummyGrid,
    variant,
    onCreated,
    onDetailsChanged,
    onSlideChanged,
  });

  const [sliderRef, instanceRef] = useKeenSlider({
    ...sliderOptions,
    defaultAnimation: {
      duration: 700,
      easing: Easing.getEasingFunction(EaseType.EMBELLISHMENT),
    },
  });

  const checkCurrentSlide = useCallback(() => {
    const keenSlider = instanceRef.current;
    if (keenSlider && keenSlider.track) {
      const currentIndex = keenSlider.track.details?.rel || 0;
      if (currentIndex !== lastIndex.current) {
        refSlides.current[lastIndex.current]?.setActive(false);
        refSlides.current[currentIndex]?.setActive(true);

        // for pagination dots
        setActiveIndex(currentIndex);
        lastIndex.current = currentIndex;
      }
    }
  }, [instanceRef]);

  // on window resize, call slider update function to resize slides per view
  const updateSlider = useCallback(() => {
    instanceRef.current?.update(sliderOptions);
  }, [instanceRef, sliderOptions]);

  useEffect(() => {
    checkCurrentSlide();

    const unsubscribe = UIStore.subscribe(
      (state) => [state.windowWidth as number],
      debounce(updateSlider, 100),
    );

    return () => {
      unsubscribe();
    };
  }, [updateSlider, checkCurrentSlide]);

  const scrollPrev = useCallback(() => {
    instanceRef.current?.prev();
  }, [instanceRef]);

  const scrollNext = useCallback(() => {
    instanceRef.current?.next();
  }, [instanceRef]);

  const goToIndex = useCallback(
    (index: number) => {
      instanceRef.current?.moveToIdx(index);
    },
    [instanceRef],
  );

  return (
    <div className={cn(styles.carousel, className)}>
      <Observer
        className={cn(styles.carouselContainerGrid, styles[variant])}
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
      >
        <div className={styles.dummyGrid}>
          {/* used to calculate the grid width regardless of screen size, to set the active slide's width */}
          <div className={styles.dummyGridInner} ref={$dummyGrid}></div>
        </div>

        <div className={`${styles.slidesContainer}`} ref={sliderRef}>
          {slides.map(({ _key, eyebrow, image, logo, text }, slideIndex) => (
            <CarouselSlide
              ref={(ref: ForwardedCarouselSlideRef) => {
                addToRefArray({
                  element: ref,
                  refArray: refSlides,
                  index: slideIndex,
                });
              }}
              _key={_key}
              className={cn(styles.slide, 'slide')}
              eyebrow={
                accentType === 'eyebrow' || typeof accentType === 'undefined'
                  ? eyebrow
                  : undefined
              }
              image={image}
              isInView={isInView !== false}
              key={_key}
              logo={
                accentType === 'logo' || typeof accentType === 'undefined'
                  ? logo
                  : undefined
              }
              onClick={goToIndex}
              slideIndex={slideIndex}
              text={text}
              variant={variant}
            />
          ))}
        </div>

        <PaginationDots
          className={styles.paginationDots}
          total={slides.length}
          parentSliderRef={instanceRef}
        />
        <div className={globalStyles.carouselArrowsContainer}>
          <ButtonCarouselArrow
            className={cn(
              globalStyles.carouselArrowsButtonPrev,
              activeIndex === 0 &&
                !instanceRef.current?.options.loop &&
                globalStyles.carouselArrowsButtonDisabled,
            )}
            iconDirection={'left'}
            onClick={scrollPrev}
          />
          <ButtonCarouselArrow
            className={cn(
              globalStyles.carouselArrowsButtonNext,
              activeIndex === maxIndex.current &&
                !instanceRef.current?.options.loop &&
                globalStyles.carouselArrowsButtonDisabled,
            )}
            iconDirection={'right'}
            onClick={scrollNext}
          />
        </div>
      </Observer>
    </div>
  );
};

export default Carousel;
