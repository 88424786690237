'use client';
import { ForwardedRef, forwardRef, useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './LogoWall.module.css';
import { LogoWallProps } from './LogoWall.types';
import LogoWallRow from './LogoWallRow/LogoWallRow';

const LogoWall = (
  props: LogoWallProps,
  $ref?: ForwardedRef<HTMLDivElement>,
) => {
  const { className, desktopLogos } = props;
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  return (
    <ModuleWrapper
      ref={$ref}
      className={cn(styles.container, className)}
      {...props}
    >
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
        className={styles.content}
      >
        <div className={cn(styles.layout, styles.desktopLayout)}>
          <LogoWallRow row={desktopLogos} isInView={isInView !== false} />
        </div>
      </Observer>
    </ModuleWrapper>
  );
};

export default forwardRef(LogoWall);
