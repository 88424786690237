import { CMSImage } from '~/components/atoms/Image/Image.types';
import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { PortableTextCustomValueData } from '~/components/molecules/PortableText/PortableText.types';
import { Tout } from '~/components/molecules/Touts/Touts.types';
import {
  CMSModuleBase,
  MoleculeBase,
} from '~/components/organisms/ModularComponent/ModularComponent.types';

export type StickyBackgroundProps = CMSModuleBase & {
  /**
   * Video displayed on top of the background image
   */
  video: CMSEnhancedMedia<'video'>;
  /**
   * Sections containing PortableText content rich text or touts
   */
  sections: (StickyBackgroundSection | StickyBackgroundSectionTouts)[];
};

export type StickyBackgroundSection = MoleculeBase & {
  _type: 'stickyBackgroundSection';
  text: PortableTextCustomValueData;
};

export type StickyBackgroundSectionTouts = MoleculeBase & {
  _type: 'stickyBackgroundSectionTouts';
  title?: PortableTextCustomValueData;
  tallestWrapperHeight?: number;
  touts: Tout[];
};

export const stickyBackgroundImageKey = 'background-appleTV';

export type StickyBackgroundImage = { backgroundAsset: CMSImage };

export const THRESHOLD = 0.3;
