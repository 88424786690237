import { gsap } from 'gsap';
import { KeenSliderInstance } from 'keen-slider/react';
import { RefObject } from 'react';

import { MOBILE_MAX_VISIBLE_SLIDES } from '../CarouselMedia.types';

const positionSlidesOnMobile = (
  instance: KeenSliderInstance,
  $slides: RefObject<HTMLElement[]>,
  $overlays: RefObject<HTMLElement[]>,
) => {
  const { slides } = instance.track.details;
  const offset = 2.5;

  for (let slideIndex = 0; slideIndex < slides.length; slideIndex++) {
    const slide = slides[slideIndex];
    let x,
      y = 0,
      opacity = 1;

    if (slide.distance <= 0) {
      // if the slide is a currently visible or previous item, map its distance to an x value between 0 and -120
      x = gsap.utils.mapRange(
        -1,
        0,
        -120,
        0,
        gsap.utils.clamp(-1, 0, slide.distance),
      );
    } else {
      // if the slide is a next item, the x and y values get offset iteratively
      x = offset * slide.distance;
      y = offset * slide.distance;

      // map the black overlay's opacity to a value between 0 and 1 only if the slide is within the maxVisible threshold, later slides will not be visible
      opacity = gsap.utils.clamp(
        0,
        1,
        gsap.utils.mapRange(
          0,
          Math.min(MOBILE_MAX_VISIBLE_SLIDES, slides.length),
          1,
          0.2,
          slide.distance,
        ),
      );
    }

    if ($slides.current && $overlays.current) {
      const newSlideProps = {
        xPercent: x,
        yPercent: y,
        visibility: 'visible',
      };
      gsap.set($overlays.current[slideIndex], {
        opacity: 1 - opacity,
      });
      if (opacity === 0) {
        newSlideProps.visibility = 'hidden';
      }
      gsap.set($slides.current[slideIndex], newSlideProps);
    }
  }
};

export default positionSlidesOnMobile;
