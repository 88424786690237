'use client';
import { capitalize } from 'lodash';
import { FocusEvent, MouseEvent, useRef } from 'react';

import DiagonalArrow from '~/assets/svg/arrow-southeast.svg';
import SvgI from '~/assets/svg/i.svg';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './PricingCard.module.css';
import { MAX_BULLETS, PricingCardProps } from './PricingCard.types';
import { getFeaturesLinkURL } from './PricingCard.utils';

const PricingCard = ({
  product,
  pricePeriod,
  highlighted,
  variant,
  featuresLink,
  numSibblings,
}: PricingCardProps) => {
  const cardDetailsRef = useRef<HTMLDivElement>(null);
  const refBullets = useRef<HTMLElement[]>([]);
  const priceDetails =
    product.pricesByPeriod?.filter(
      (price) => price.pricePeriod.value === pricePeriod,
    )[0] || null;

  // sign up button can be from current price period or fallback
  const signUpButton =
    priceDetails?.signUpButton.button || product.signUpButton.button;

  const onMouseOver = (
    e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
  ) => {
    const $bullets = refBullets.current;
    const $i = e.currentTarget;
    for (const $bullet of $bullets) {
      if ($bullet && $bullet.contains($i)) {
        $bullet.setAttribute('data-is-active', 'true');
      }
    }
  };

  const onMouseLeave = (
    e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>,
  ) => {
    const $bullets = refBullets.current;
    const $i = e.currentTarget;
    for (const $bullet of $bullets) {
      if ($bullet && $bullet.contains($i)) {
        $bullet.removeAttribute('data-is-active');
      }
    }
  };

  return (
    <div
      className={cn(
        styles.pricingCard,
        styles[`pricingCardVariant${capitalize(variant)}`],
        highlighted ? styles.highlightedCard : null,
        typeof numSibblings === 'number'
          ? styles[`pricingCardColumns${numSibblings}`]
          : null,
      )}
      key={product._key}
    >
      {/* eyebrow */}
      <div className={styles.cardEyebrow}>{product.eyebrow}</div>

      {/* title */}
      <div className={styles.cardTitle}>{product.tier.title}</div>

      <div className={styles.cardContentWrapper}>
        <div className={styles.cardIntro}>
          {/* big white text - either a price or a big title */}
          <div className={styles.cardPriceWrapper}>
            {priceDetails && !product.secondaryTitle && (
              <>
                <div className={styles.cardPrice}>
                  ${priceDetails.price} /{' '}
                  {priceDetails.pricePeriod.abbreviation}
                </div>
                <div className={styles.cardPriceContext}>
                  <span className={styles.cardPriceContextLine}>
                    {priceDetails.contextLine1 || ' '}
                  </span>
                </div>
              </>
            )}
            {product.secondaryTitle && (
              <div className={cn(styles.cardSecondaryTitle)}>
                {product.secondaryTitle}
                {/* adding an empty context line here so we can maintain proper alignment */}
                <span className={styles.cardPriceContextLine}>&nbsp;</span>
              </div>
            )}
          </div>

          {/* top subtext */}
          <div className={styles.cardDescription}>
            <div className={styles.cardDescriptionContent}>
              {product.description}
            </div>
          </div>

          {/* cta: full variant */}
          {variant === 'full' && (
            <div className={styles.ctaRow}>
              {signUpButton?.to?.url && (
                <Button {...signUpButton} className={styles.cardDetailButton}>
                  {signUpButton.to?.label}
                </Button>
              )}
              {product.secondaryButton?.to?.url && (
                <Button
                  {...product.secondaryButton}
                  className={styles.cardDetailButton}
                >
                  {product.secondaryButton.to?.label}
                </Button>
              )}
            </div>
          )}
        </div>

        {/* card details, bullet list etc */}
        <div className={cn(styles.cardDetails)} ref={cardDetailsRef}>
          {/* bottom block of bullets */}
          <div className={styles.cardDetailsTitle}>{product.detailsTitle}</div>

          {product.detailsBullets && product.detailsBullets.length && (
            <>
              <div className={cn(styles.cardDetailsBulletsWrapper)}>
                <ul className={cn(styles.cardDetailBullets)}>
                  {product.detailsBullets.map((bullet, index) =>
                    index > MAX_BULLETS ? null : (
                      <li
                        className={cn(styles.cardDetailBulletItem)}
                        key={bullet._key}
                        ref={(element) =>
                          bullet.description &&
                          addToRefArray({ element, refArray: refBullets })
                        }
                      >
                        <span className={styles.cardDetailBulletText}>
                          {bullet.title}
                        </span>
                        {bullet.description && (
                          <>
                            <button
                              className={styles.cardDetailBulletSvgButton}
                              onMouseOver={(e) => onMouseOver(e)}
                              onFocus={(e) => onMouseOver(e)}
                              onMouseOut={(e) => onMouseLeave(e)}
                              onBlur={(e) => onMouseLeave(e)}
                              aria-label={bullet.description}
                            >
                              <SvgI className={styles.cardDetailBulletSvg} />
                            </button>
                            <div className={styles.cardDetailBulletTooltip}>
                              <span
                                className={styles.cardDetailBulletTooltipText}
                              >
                                {bullet.description}
                              </span>
                              <figure
                                className={styles.cardDetailBulletTooltipTail}
                              />
                            </div>
                          </>
                        )}
                      </li>
                    ),
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>

      {/* cta: reduced variant */}
      {variant === 'reduced' && (
        <div className={styles.ctaRow}>
          {signUpButton?.to?.url && (
            <Button {...signUpButton} className={styles.cardDetailButton}>
              {signUpButton.to?.label}
            </Button>
          )}
          {product.secondaryButton?.to?.url && (
            <Button
              {...product.secondaryButton}
              className={styles.cardDetailButton}
            >
              {product.secondaryButton.to?.label}
            </Button>
          )}
        </div>
      )}

      {/* view all features link */}
      {variant === 'full' && featuresLink && (
        <div className={styles.viewAllFeaturesLink}>
          <a href={getFeaturesLinkURL(featuresLink)}>
            View all Features
            <DiagonalArrow />
          </a>
        </div>
      )}
    </div>
  );
};

export default PricingCard;
