'use client';
import { gsap } from 'gsap';
import { useCallback, useEffect, useRef, useState } from 'react';

import SvgCaret from '~/assets/svg/caret.svg';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import Observer from '~/components/atoms/Observer/Observer';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { dict } from '~/data/stores/Dictionary';
import useUIStore from '~/state/ui';
import addToRefArray from '~/utils/addToRefArray';

import {
  hideCardsAnimation,
  showCardsAnimation,
} from './CardGridEditorial.animations';
import styles from './CardGridEditorial.module.css';
import {
  CardGridEditorialItem,
  CardGridEditorialProps,
  DEFAULT_CARDS_DISPLAYED,
} from './CardGridEditorial.types';
import CardGridEditorialCard from './CardGridEditorialCard/CardGridEditorialCard';

const CardGridEditorial = (props: CardGridEditorialProps) => {
  const { cardGridEditorialItems, className, logoWrapperHeight } = props;
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  const [allCardsVisible, setAllCardsVisible] = useState(false);
  const $cards = useRef<HTMLAnchorElement[]>([]);
  const $cardGridWrapper = useRef<HTMLDivElement>(null);

  const breakpoint = useUIStore((state) => state.breakpoint);

  const calculateWrapperHeight = useCallback(
    ({ wrapperHeight }: { wrapperHeight: 'short' | 'tall' }) => {
      let rowCount = 0;
      let rowGap = 0;
      let wrapperTotalHeight = 0;

      const numOfCards =
        wrapperHeight === 'tall'
          ? $cards.current.length
          : DEFAULT_CARDS_DISPLAYED;

      if (
        !$cards.current.length ||
        !$cardGridWrapper ||
        !$cardGridWrapper.current
      ) {
        return 0;
      }

      const cardHeight = $cards.current[0].getBoundingClientRect().height;

      if ($cards.current.length > 0) {
        const style = getComputedStyle($cards.current[0].parentElement!);
        rowGap = parseFloat(style.rowGap);
      }

      // check viewport width
      if (breakpoint?.name === 'sm') {
        // calculate rows for one column at mobile widths
        rowCount = numOfCards;
        wrapperTotalHeight = rowCount * cardHeight + rowGap * (rowCount - 1);
        return wrapperTotalHeight;
      }

      // calculate rows for two columns at wider widths
      rowCount = Math.ceil(numOfCards / 2);

      wrapperTotalHeight = rowCount * cardHeight + rowGap * (rowCount - 1);
      return wrapperTotalHeight;
    },
    [$cards, breakpoint],
  );

  const onClickMoreLess = () => {
    if (allCardsVisible) {
      hideCardsAnimation({
        $cardGridWrapper,
        $cards,
        shortWrapperHeight: calculateWrapperHeight({ wrapperHeight: 'short' }),
        tallWrapperHeight: calculateWrapperHeight({ wrapperHeight: 'tall' }),
        onComplete: () => {
          setAllCardsVisible(false);
        },
      });
    } else {
      setAllCardsVisible(true);
    }
  };

  useEffect(() => {
    // Shift the focus to the first of the newly visible cards on tab
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        if ($cards.current[DEFAULT_CARDS_DISPLAYED]) {
          $cards.current[DEFAULT_CARDS_DISPLAYED].focus();
          window.removeEventListener('keydown', handleKeyDown);
        }
      }
    };

    if (allCardsVisible) {
      showCardsAnimation({
        $cardGridWrapper,
        $cards,
        shortWrapperHeight: calculateWrapperHeight({ wrapperHeight: 'short' }),
        onComplete: () => {
          gsap.set([$cards.current, $cardGridWrapper.current], {
            clearProps: 'all',
          });
        },
      });
      window.addEventListener('keydown', handleKeyDown);
    } else {
      gsap.set([$cards.current, $cardGridWrapper.current], {
        clearProps: 'all',
      });
    }
  }, [allCardsVisible, calculateWrapperHeight]);

  return (
    <ModuleWrapper className={className} {...props}>
      <Observer
        className={styles.cardGridEditorial}
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
      >
        <div className={styles.content} ref={$cardGridWrapper}>
          {cardGridEditorialItems &&
            cardGridEditorialItems.map(
              (card: CardGridEditorialItem, cardIndex: number) => (
                <CardGridEditorialCard
                  card={card}
                  className={
                    allCardsVisible || cardIndex < DEFAULT_CARDS_DISPLAYED
                      ? ''
                      : styles.invisible
                  }
                  key={card._key}
                  isInView={
                    cardIndex < DEFAULT_CARDS_DISPLAYED
                      ? isInView
                      : isInView && allCardsVisible
                  }
                  logoWrapperHeight={logoWrapperHeight}
                  ref={(element) =>
                    addToRefArray({
                      element,
                      refArray: $cards,
                      index: cardIndex,
                    })
                  }
                />
              ),
            )}
        </div>
        {cardGridEditorialItems.length > DEFAULT_CARDS_DISPLAYED && (
          <div className={styles.triggerWrapper}>
            <Button buttonColorScheme="glass" onClick={onClickMoreLess}>
              {allCardsVisible ? dict('showLess') : dict('showMore')}{' '}
              <span className={styles.caret} aria-expanded={allCardsVisible}>
                <SvgCaret className={styles.caretIcon} />
              </span>
            </Button>
          </div>
        )}
      </Observer>
    </ModuleWrapper>
  );
};

export default CardGridEditorial;
