import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type ShaderParameters = {
  // UI
  showModules: boolean;
  // aurora shader
  randomSeed: number;
  numOctaves: number;
  // interaction
  brushSize: number;
  mouseDeltaScale: number;
  // fluid simulation shader
  curlStrength: number;
  velocityDissipation: number;
  pressureDissipation: number;
  densityDissipation: number;
};

type ShaderParameterStore = ShaderParameters & {
  setShowModules: (v: boolean) => void;
  setRandomSeed: (v: number) => void;
  setNumOctaves: (v: number) => void;
  setBrushSize: (v: number) => void;
  setMouseDeltaScale: (v: number) => void;
  setVelocityDissipation: (v: number) => void;
  setPressureDissipation: (v: number) => void;
  setDensityDissipation: (v: number) => void;
  setCurlStrength: (v: number) => void;
};

export const ShaderParameterStore = createWithEqualityFn<ShaderParameterStore>(
  (set) => ({
    showModules: true,
    randomSeed: 33758.5453123,
    numOctaves: 5,
    brushSize: 0.2,
    mouseDeltaScale: 0.5,
    velocityDissipation: 0.999,
    pressureDissipation: 0.99,
    densityDissipation: 0.99,
    curlStrength: 20,
    setShowModules: (v) => set({ showModules: v }),
    setRandomSeed: (v) => set({ randomSeed: v }),
    setNumOctaves: (v) => set({ numOctaves: Math.round(v) }),
    setBrushSize: (v) => set({ brushSize: v }),
    setMouseDeltaScale: (v) => set({ mouseDeltaScale: v }),
    setVelocityDissipation: (v) => set({ velocityDissipation: v }),
    setPressureDissipation: (v) => set({ pressureDissipation: v }),
    setDensityDissipation: (v) => set({ densityDissipation: v }),
    setCurlStrength: (v) => set({ curlStrength: v }),
  }),
  shallow,
);
