'use client';

import { useKeenSlider } from 'keen-slider/react';
import { useMemo, useRef } from 'react';

import Graphic from '~/components/atoms/Graphic/Graphic';
import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import Shadow from '~/components/molecules/Shadow/Shadow';
import { cn, getSpacer, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import TextLockup from '../TextLockups/TextLockup';
import styles from './Touts.module.css';
import { MOBILE_SIZE_TEXT_LIMIT, Tout, ToutsProps } from './Touts.types';

const spacerValue = getSpacer(24);

const Touts = ({
  className,
  containerClassName,
  touts,
  isTile = false,
  isColumnLayout = false,
  isMobileSlider,
  tallestWrapperHeight = 0,
  title,
}: ToutsProps) => {
  const countClass = styles[`with${touts.length}Touts`];

  // check for touts with longest text, and use it decide wether it spans over 3 or 4 columns on mobile
  const isMobileSmall = useMemo(() => {
    let isMobileSmall = true;
    let maxTextLength = 0;
    // if its a tile, no need for the calculation, it should always be 4 column
    if (isTile) return false;
    touts.forEach((tout) => {
      const body = tout.content.blocks.find((block) => block.style === 'body');
      if (body) {
        const text = body.children.map((child) => child.text).join('');
        if (text.length > maxTextLength) {
          maxTextLength = text.length;
        }
      }
    });
    // if the max tout length is more than {{MOBILE_SIZE_TEXT_LIMIT}}, span touts over 4 columns on mobile
    if (maxTextLength > MOBILE_SIZE_TEXT_LIMIT) {
      isMobileSmall = false;
    }
    return isMobileSmall;
  }, [touts, isTile]);

  const toutRefs = useRef<HTMLElement[]>(Array(touts.length));

  // Keen slider (mobile only)

  const [sliderRef, instanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    loop: false,
    disabled: !isMobileSlider,
    selector: '.tout',
    slides: {
      origin: 'auto',
      spacing: spacerValue,
      perView: 1,
      number: touts.length,
    },

    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: true,
      },
    },
  });

  const Inner = isTile ? Shadow : 'div';
  const tallestGraphicWrapper = `${tallestWrapperHeight / 10}rem`;

  return (
    <div className={cn(styles.touts, className)}>
      {title && (
        <TextLockup value={title.blocks} lockupOptions={title.lockupOptions} />
      )}
      <div
        ref={sliderRef}
        className={cn(
          styles.container,
          countClass,
          isTile && styles.toutsOnTiles,
          isColumnLayout && styles.isColumnLayout,
          isMobileSlider && styles.isMobileSlider,
          isMobileSmall && styles.mobileSmallLayout,
          containerClassName,
        )}
      >
        {touts?.length &&
          touts.map((tout: Tout, index: number) => {
            const isLogo = tout.graphicType === 'logo';
            return (
              <div
                ref={(tout: HTMLDivElement) =>
                  addToRefArray({
                    element: tout,
                    refArray: toutRefs,
                    index,
                  })
                }
                key={tout._key}
                className={cn('tout', styles.toutInnerWrapper)}
              >
                <Inner
                  className={cn(
                    styles.tout,
                    isTile && styles.tile,
                    isTile && styles.shadow,
                  )}
                >
                  {tout?.icon && (
                    <div
                      className={cn(
                        styles.iconWrapper,
                        isLogo && styles.isLogo,
                      )}
                      style={{
                        height: tallestGraphicWrapper,
                      }}
                    >
                      <Graphic {...tout?.icon} className={styles.icon} />
                    </div>
                  )}
                  <TextLockup
                    className={styles.content}
                    lockupOptions={tout.content.lockupOptions}
                    value={tout.content.blocks}
                  />
                </Inner>
              </div>
            );
          })}
      </div>
      {isMobileSlider && (
        <PaginationDots
          className={styles.paginationDots}
          total={touts.length}
          parentSliderRef={instanceRef}
        />
      )}
    </div>
  );
};

export default Touts;
