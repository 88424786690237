'use client';
import { gsap } from 'gsap';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import useUIStore from '~/state/ui';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';

import SideBySideItem from '../../SideBySideItem/SideBySideItem';
import { SideBySideItemRef } from '../../SideBySideItem/SideBySideItem.types';
import styles from './StackedSideBySideWrapper.module.css';
import {
  StackedSideBySideWrapperProps,
  StackedSideBySideWrapperRef,
} from './StackedSideBySideWrapper.types';
import {
  enterProgressTransformer,
  leaveProgressTransformer,
  nextProgressTransformer,
} from './StackedSideBySideWrapper.utils';

const StackedSideBySideWrapper = (
  {
    index,
    title,
    content,
    total,
    logo,
    media,
    className,
    active,
    isGlowHidden = true,
  }: StackedSideBySideWrapperProps,
  ref: ForwardedRef<StackedSideBySideWrapperRef>,
) => {
  const sideBySideRef = useRef<SideBySideItemRef>(null);
  const $element = useRef<HTMLDivElement>(null);
  const enterProgressSetter = useRef<(value: number) => void>();
  const leaveProgressSetter = useRef<(value: number) => void>();
  const nextProgressSetter = useRef<(value: number) => void>();

  const animation = useRef<GSAPTimeline>();

  const windowWidth = useUIStore((state) => state.windowWidth);
  const windowHeight = useUIStore((state) => state.windowHeight);
  const breakpoint = useUIStore((state) => state.breakpoint);

  const isComputedStyleComplete = useUIStore(
    (state) => state.isComputedStyleComplete,
  );

  const updateVideoState = useCallback(
    (progress: number, isInView: boolean) => {
      if (media.sanityMedia?.mediaType === 'video') {
        const mediaRef = sideBySideRef.current?.mediaRef;
        if (mediaRef && mediaRef.current && 'pause' in mediaRef.current) {
          if (1 - progress < 0.15 || !isInView) {
            mediaRef.current.pause();
          } else {
            mediaRef.current.play();
          }
        }
      }
    },
    [media.sanityMedia?.mediaType],
  );

  useImperativeHandle(
    ref,
    () => ({
      get $element() {
        return $element;
      },
      setProgress(progress: number, isInView: boolean) {
        if (enterProgressSetter.current) {
          enterProgressSetter.current(enterProgressTransformer(progress));
        }
        if (leaveProgressSetter.current) {
          leaveProgressSetter.current(leaveProgressTransformer(progress));
        }

        const nextProgress = nextProgressTransformer(progress);
        if (nextProgressSetter.current)
          nextProgressSetter.current(nextProgress);
        updateVideoState(nextProgress, isInView);
      },
      setCarouselProgress(progress: number) {
        animation.current?.progress(progress);
      },
      getRect() {
        return $element.current?.getBoundingClientRect();
      },
    }),
    [updateVideoState],
  );

  useLayoutEffect(() => {
    enterProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-enter-progress',
    ) as (value: number) => void;
    leaveProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-leave-progress',
    ) as (value: number) => void;
    nextProgressSetter.current = gsap.quickSetter(
      $element.current,
      '--card-next-progress',
    ) as (value: number) => void;
  }, []);

  useLayoutEffect(() => {
    const rect = $element.current?.getBoundingClientRect();
    if (rect)
      gsap.set($element.current, {
        '--top-offset': `${
          ((windowHeight || window.innerHeight) - rect.height) / 2
        }px`,
      });
  }, [windowWidth, windowHeight]);

  useLayoutEffect(() => {
    if (
      breakpoint &&
      isBreakpointOrGreater(breakpoint, 'lg') &&
      animation.current
    ) {
      // kill mobile animation if no longer on mobile
      animation.current.kill();
    }
  }, [breakpoint, isComputedStyleComplete, index]);

  // hide glow on `sm` breakpoint if user is dragging the slider and breakpoint is currently `sm`
  const isGlowHiddenSm =
    isGlowHidden && breakpoint && !isBreakpointOrGreater(breakpoint, 'lg');

  return (
    <div
      ref={$element}
      className={cn(
        className,
        styles.stackedSideBySideWrapper,
        active && styles.active,
        isGlowHiddenSm && styles.isGlowHiddenSm,
        index === 0 && styles.firstItem,
      )}
      style={
        {
          '--reverse-index': total - index - 1,
        } as CSSProperties
      }
    >
      {title && (
        <SideBySideItem
          ref={sideBySideRef}
          textContentType="richText"
          $wrapper={$element}
          innerClassNames={{
            contentWrapper: styles.contentWrapper,
            mediaContainer: cn(styles.mediaContainer, styles.mediaOutro),
            mediaWrapper: styles.mediaWrapper,
            media: styles.media,
          }}
          layout={'mediaLeftTextRight'}
          title={title}
          content={content}
          logo={logo}
          medias={[media]}
          mediaLayout="stackCard"
          shouldParallax={false}
          alignment="alignLeft"
        />
      )}
    </div>
  );
};

export default forwardRef(StackedSideBySideWrapper);
