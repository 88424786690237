'use client';
import { gsap } from 'gsap';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import SvgMore from '~/assets/svg/more.svg';
import CircleLoader from '~/components/atoms/CircleLoader/CircleLoader';
import { CircleLoaderRef } from '~/components/atoms/CircleLoader/CircleLoader.types';
import Media from '~/components/molecules/Media/Media';
import { cn } from '~/utils';
import { EaseType } from '~/utils/singletons/Easing';

import styles from './HeroCard.module.css';
import { ForwardedHeroCardRef, HeroCardProps } from './HeroCard.types';

const HeroCard = (
  { className, title, author, date, media, shouldAnimate }: HeroCardProps,
  ref: ForwardedRef<ForwardedHeroCardRef>,
) => {
  const $element = useRef<HTMLDivElement>(null);
  const $loaderRef = useRef<CircleLoaderRef>(null);
  const $mediaInnerWrapper = useRef<HTMLDivElement>(null);

  // TODO: update if i18n
  const dateString = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const loadingDuration = gsap.utils.random(1, 3, 0.5);

  useImperativeHandle(ref, () => {
    return {
      $element,
      enter: () => {
        const tl = gsap.timeline();
        if ($loaderRef.current) tl.add($loaderRef.current?.start());
        tl.fromTo(
          $mediaInnerWrapper.current,
          { clipPath: 'circle(0%)' },
          {
            clipPath: 'circle(100%)',
            ease: EaseType.DEFAULT,
            duration: 1.2,
          },
        );
        return tl;
      },
    };
  });

  return (
    <div
      className={cn(
        styles.heroCard,
        shouldAnimate && styles.shouldAnimate,
        className,
      )}
      ref={$element}
    >
      <div className={styles.cardMediaWrapper}>
        {shouldAnimate && (
          <CircleLoader
            ref={$loaderRef}
            className={styles.loader}
            loadingDuration={loadingDuration}
          />
        )}

        <div className={styles.mediaInnerWrapper} ref={$mediaInnerWrapper}>
          <Media
            sanityMedia={media.media.sanityMedia}
            fixedAspectRatio={true}
          />
        </div>
      </div>
      <div className={styles.chin}>
        <span className={styles.title}>{title}</span>
        <div className={styles.lowerChin}>
          <span className={styles.subtitle}>
            <span className={styles.author}>{author}</span>
            <span>{dateString}</span>
          </span>
          <SvgMore className={styles.more} />
        </div>
      </div>
    </div>
  );
};

export default forwardRef<ForwardedHeroCardRef, HeroCardProps>(HeroCard);
