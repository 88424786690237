import { PortableTextCustomValueData } from '~/components/molecules/PortableText/PortableText.types';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';

const CarouselWithToutsTextBlock = ({
  text,
  className,
}: {
  text: PortableTextCustomValueData;
  className?: string;
}) => {
  return (
    <TextLockup
      className={className}
      value={text.blocks}
      lockupOptions={text.lockupOptions}
    />
  );
};

export default CarouselWithToutsTextBlock;
