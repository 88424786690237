'use client';
import { gsap } from 'gsap';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './PaginationDots.module.css';
import { MIN_DOTS, PaginationDotsProps } from './PaginationDots.types';

/**
 * Pagination dots, used in conjunction with a keen slider carousel
 * @param total Total number of slides
 * @param parentSliderRef Ref to the keen slider instance that the pagination dots correspond to. Passed so the dots can access its current track details.
 * @param className
 * @example <PaginationDots activeIndex={1} total={6} parentSliderRef={sliderInstance}/>
 */
const PaginationDots = ({
  className,
  total,
  parentSliderRef,
}: PaginationDotsProps) => {
  const refDots = useRef<HTMLButtonElement[]>(Array(total));
  const dotsTrack = useRef<HTMLUListElement>(null);
  const trackWidth = useRef<number>();
  const [activeIndex, setActiveIndex] = useState(0);
  const isSlider = total > MIN_DOTS;

  const onSlideChanged = () => {
    if (parentSliderRef.current)
      setActiveIndex(parentSliderRef.current.track.details.rel);
  };

  const onDetailsChanged = useCallback(() => {
    positionTrack();
  }, []);

  useEffect(() => {
    trackWidth.current = dotsTrack.current?.getBoundingClientRect().width;

    parentSliderRef.current?.on('slideChanged', onSlideChanged);
    parentSliderRef.current?.on('detailsChanged', onDetailsChanged);

    // if the parent slider starts on a slide that's not the first slide
    if (parentSliderRef.current)
      setActiveIndex(parentSliderRef.current.track.details.rel);
    positionTrack();

    return () => {
      parentSliderRef.current?.on('slideChanged', onSlideChanged, true);
      parentSliderRef.current?.on('detailsChanged', onDetailsChanged, true);
    };
  }, [setActiveIndex]);

  const scaleDots = useCallback(() => {
    const slider = parentSliderRef.current;
    if (slider) {
      const parentTrackLength =
        parentSliderRef.current?.track.details.length || 0;

      const nearestDot = slider.track.details.progress * parentTrackLength;

      slider.track.details.slides.forEach((slide, i: number) => {
        const distFromCurrent = i - nearestDot;

        // currently active slide has dist of 0
        // previous slide has dist of -1
        // next slide has dist of 1
        // prev dots are scaled to 0, unless its the second to last dot

        // when distance is between 0 and -1 (moving from current to prev), scale is between 1 and SCALE/2 (or SCALE if its the second to last dot)
        // when distance is between 1 and 0 (moving from next to current), scale is between SCALE and 1

        let scale = 1;
        if (distFromCurrent > 1) {
          scale = distFromCurrent > 1 ? 0.01 : 1; // Can't use 0 because of a bug in keen-slider
        } else if (distFromCurrent < 0) {
          scale = distFromCurrent < -2 ? 0.01 : 1; // Can't use 0 because of a bug in keen-slider
        }

        gsap.set(refDots.current[i], { scale: scale });
      });
    }
  }, [parentSliderRef, total]);

  const positionTrack = useCallback(() => {
    if (!isSlider) return;

    if (parentSliderRef.current) {
      const { progress } = parentSliderRef.current?.track.details;

      const clampedProgress = gsap.utils.clamp(
        1 / total,
        (total - 2) / total,
        progress,
      );

      if (trackWidth.current) {
        // position the dots carousel to center the active dot
        gsap.set(dotsTrack.current, {
          x: -trackWidth.current * clampedProgress,
        });
      }

      scaleDots();
    }
  }, [parentSliderRef, scaleDots, total]);

  const onDotClick = (index: number) => {
    // this callback should update `activeIndex` prop
    parentSliderRef.current?.moveToIdx(index);
  };

  if (total < 2) {
    return null;
  }

  return (
    <div className={styles.paginationDots}>
      <div
        className={cn(
          styles.paginationDotsContainer,
          isSlider && styles.isSlider,
          className,
        )}
      >
        <ul className={cn(styles.paginationDotsList)} ref={dotsTrack}>
          {[...Array(total)].map((x, i) => (
            <li
              className={cn(
                styles.dotItem,
                activeIndex === i && styles.active,
                'dotItem',
              )}
              key={i}
            >
              <button
                className={styles.dotButton}
                onClick={() => onDotClick(i)}
                aria-label={`${dict('goToSlide')} ${i + 1}`}
                aria-current={activeIndex === i}
              >
                <figure
                  className={styles.dotFigure}
                  ref={(ref: HTMLButtonElement) => {
                    addToRefArray({
                      element: ref,
                      refArray: refDots,
                      index: i,
                    });
                  }}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default forwardRef(PaginationDots);
