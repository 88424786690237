import styles from '../DebugUI.module.css';

type DebugUIGroupProps = React.PropsWithChildren & {
  name: string;
};

export const DebugUIGroup = ({ children, name }: DebugUIGroupProps) => {
  return (
    <div className={styles.group}>
      <div className={styles.groupTitle}>{name}</div>
      {children}
    </div>
  );
};
