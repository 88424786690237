'use client';

import { useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import Shadow from '~/components/molecules/Shadow/Shadow';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './CardGridNav.module.css';
import { CardGridNavProps } from './CardGridNav.types';
import CardGridNavItem from './CardGridNavItem/CardGridNavItem';

const CardGridNav = (props: CardGridNavProps) => {
  const { cardGridNavItems, className } = props;

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  return (
    <ModuleWrapper className={className} {...props}>
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
        className={cn(styles.cardGridLayout, className)}
      >
        <div className={styles.cardItems}>
          {cardGridNavItems.map(({ _key: cardKey, content, link, media }) => (
            <CardGridNavItem
              key={cardKey}
              content={content}
              media={media}
              link={link}
              isInView={isInView !== false}
              className={styles.cardItem}
              cardContentClassName={styles.cardContent}
            />
          ))}
          <Shadow className={styles.shadowWrapper} />
        </div>
      </Observer>
    </ModuleWrapper>
  );
};

export default CardGridNav;
