'use client';

import OverflowingMedia from '~/components/molecules/OverflowingMedia/OverflowingMedia';
import { cn } from '~/utils';

import CarouselWithToutsTextBlock from '../CarouselWithToutsTextBlock/CarouselWithToutsTextBlock';
import styles from './CarouselWithToutsMobile.module.css';
import { CarouselWithToutsMobileProps } from './CarouselWithToutsMobile.types';

/**
 * Carousel Tabber mobile component with slides displayed stacked instead of as a carousel
 * @param slides An array of slide data objects
 * @param className
 * @example <CarouselWithToutsMobile slides={slides]/>
 */
const CarouselWithToutsMobile = ({
  slides,
  className,
}: CarouselWithToutsMobileProps) => {
  return (
    <div className={cn(styles.mobileCarouselContainer, className)}>
      <ul className={styles.slides}>
        {slides.map((slide, index: number) => (
          <li key={slide._key} className={styles.slideItem}>
            <OverflowingMedia
              media={slide.media.media}
              alignment={index % 2 === 0 ? 'alignLeft' : 'alignRight'}
            />

            <div className={styles.slideDescriptionContainer}>
              <CarouselWithToutsTextBlock text={slide.text} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CarouselWithToutsMobile;
