'use client';
import { useCallback, useEffect, useRef, useState } from 'react';

import SvgArrowIcon from '~/assets/svg/arrow.svg';
import Image from '~/components/atoms/Image/Image';
import Link from '~/components/atoms/Link/Link';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import useUIStore from '~/state/ui';
import { cn, isBreakpointOrSmaller } from '~/utils';

import styles from './CardGridNavItem.module.css';
import { CardGridNavItemProps } from './CardGridNavItem.types';

const ANIMATION_DURATION = 500;
const FRAME_RATE = 30;
const FRAME_DURATION = ANIMATION_DURATION / FRAME_RATE;

const CardGridNavItem = ({
  className,
  cardContentClassName,
  link,
  media,
}: CardGridNavItemProps) => {
  const desktopVideoRef = useRef<HTMLVideoElement>(null);
  const animationFrameRef = useRef<number | null>(null);
  const touchTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);
  const lastTimeRef = useRef<number>(0);
  const lastDirectionRef = useRef<boolean>(false);
  const frameCountRef = useRef<number>(0);
  const breakpoint = useUIStore((state) => state.breakpoint);

  const isMobile = isBreakpointOrSmaller(breakpoint, 'sm');

  const animate = useCallback(
    (timestamp: number) => {
      const video = desktopVideoRef.current;
      if (!video || !hasInteracted) return;

      if (!lastTimeRef.current) lastTimeRef.current = timestamp;
      const deltaTime = timestamp - lastTimeRef.current;

      if (deltaTime < FRAME_DURATION) {
        animationFrameRef.current = requestAnimationFrame(animate);
        return;
      }

      lastTimeRef.current = timestamp;

      const videoDuration = video.duration;
      const progressPerMs = 1 / ANIMATION_DURATION;
      const deltaProgress = deltaTime * progressPerMs;

      if (isHovered !== lastDirectionRef.current) {
        lastDirectionRef.current = isHovered;
      }

      let newTime;
      if (isHovered) {
        newTime = Math.min(
          video.currentTime + deltaProgress * videoDuration,
          videoDuration,
        );
      } else {
        newTime = Math.max(
          video.currentTime - deltaProgress * videoDuration,
          0,
        );
      }

      video.currentTime = newTime;

      if (
        (isHovered && newTime < videoDuration) ||
        (!isHovered && newTime > 0)
      ) {
        frameCountRef.current = frameCountRef.current + 1;
        animationFrameRef.current = requestAnimationFrame(animate);
      } else {
        lastTimeRef.current = 0;
      }
    },
    [isHovered, hasInteracted],
  );

  const startAnimation = useCallback(() => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    lastTimeRef.current = 0;
    animationFrameRef.current = requestAnimationFrame(animate);
  }, [animate]);

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) {
      setIsHovered(true);
      setHasInteracted(true);
    }
  }, [isMobile]);

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) {
      setIsHovered(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (hasInteracted) {
      startAnimation();
    }
  }, [isHovered, hasInteracted, startAnimation]);

  useEffect(() => {
    return () => {
      const touchTimer = touchTimerRef.current;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (touchTimer) {
        clearTimeout(touchTimer);
      }
    };
  }, []);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(styles.cardGridNavItem, className)}
    >
      <Link to={link}>
        <div className={styles.innerWrapper}>
          <div className={cn(styles.textContainer, cardContentClassName)}>
            <div className={styles.titleWrapper}>
              <h4 className={styles.title}>{link.label}</h4>
            </div>
            <SvgArrowIcon color="white" className={styles.buttonArrow} />
          </div>
          {media.media.sanityMedia?.mediaType === 'video' && (
            <EnhancedMedia
              overlay={media.overlay}
              className={styles.videoContainer}
            >
              {isMobile && media.media.sanityMedia.thumbnail ? (
                <Image
                  source={media.media.sanityMedia.thumbnail}
                  className={styles.videoElement}
                  contain={true}
                />
              ) : (
                <video
                  className={styles.videoElement}
                  ref={desktopVideoRef}
                  muted
                  playsInline
                  preload="auto"
                >
                  <source
                    src={media.media.sanityMedia.mp4Url}
                    type="video/mp4"
                  />
                  <source src={media.media.sanityMedia.url} type="video/mp4" />
                </video>
              )}
            </EnhancedMedia>
          )}
        </div>
      </Link>
    </div>
  );
};

export default CardGridNavItem;
