'use client';

import { ForwardedRef, forwardRef } from 'react';

import Video from '~/components/atoms/Video/Video';
import { VideoRef } from '~/components/atoms/Video/Video.types';

import { AutoplayingVideoProps } from './AutoplayingVideo.types';

const AutoplayingVideo = (
  props: AutoplayingVideoProps,
  ref: ForwardedRef<VideoRef>,
) => {
  const { willAutoplay = true } = props;

  return (
    <Video
      {...props}
      willAutoplay={willAutoplay}
      isMuted={true}
      controls={false}
      playsInline={true}
      ref={ref}
    />
  );
};

export default forwardRef(AutoplayingVideo);
