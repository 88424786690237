'use client';
import { gsap } from 'gsap';
import { useRef } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Image from '~/components/atoms/Image/Image';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import Shadow from '~/components/molecules/Shadow/Shadow';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import blurredImagesStyles from '~/styles/theme/blurredImages.module.css';
import { cn, useScrollProgress } from '~/utils';

import styles from './HeroTransitionV4.module.css';
import { HeroTransitionV4Props } from './HeroTransitionV4.types';

/**
 * Hero Transition V4 component
 * @param media The main media element displayed on the device screen.
 * @param moduleIndex The module's index within its section
 * @param className
 * @example <HeroTransitionV4 media={media} />
 */
const HeroTransitionV4 = (props: HeroTransitionV4Props) => {
  const { loopCueTime, hasBlurredBackground, media, gridOffset, className } =
    props;

  const mediaProps = media.media.sanityMedia;
  const $element = useRef<HTMLDivElement>(null);
  const $media = useRef<VideoRef>(null);
  const $mediaInner = useRef<HTMLDivElement>(null);

  const onProgress = (progress: number) => {
    gsap.set($mediaInner.current, {
      '--hero-progress': progress,
    });

    if (progress > 0.05) {
      try {
        $media.current?.play();
      } catch (e) {
        console.error(e);
      }
    }
  };

  useScrollProgress($element, onProgress);

  const onVideoEnded = () => {
    $media.current?.seekTo(loopCueTime);
    $media.current?.play();
  };

  const renderBlurredImage = () => {
    let blur;
    let aspectRatio;
    switch (mediaProps.mediaType) {
      case 'image':
        blur = mediaProps;
        aspectRatio = mediaProps.asset.aspectRatio;
        break;
      case 'video':
        blur = mediaProps.thumbnail;
        aspectRatio = mediaProps.thumbnail?.asset.aspectRatio;
        break;
    }
    if (blur && hasBlurredBackground)
      return (
        <div
          className={cn(blurredImagesStyles.blurWrapper, styles.blurWrapper)}
          style={{
            aspectRatio,
          }}
        >
          <Image
            source={blur}
            className={blurredImagesStyles.blur}
            dpr={1}
            blur={500}
          />
          <div className={blurredImagesStyles.blurDim} />
        </div>
      );
  };
  return (
    <ModuleWrapper
      className={cn(styles.heroTransitionV4, className)}
      ref={$element}
      {...props}
    >
      {renderBlurredImage()}
      <div className={styles.mediaContainer}>
        <div
          className={cn(styles.mediaInner, gridOffset && styles.gridOffset)}
          ref={$mediaInner}
        >
          <GlassWrapper className={styles.mediaWrapper}>
            <Media
              sanityMedia={mediaProps}
              className={styles.media}
              fixedAspectRatio={true}
              onVideoEnded={onVideoEnded}
              ref={$media}
            />
          </GlassWrapper>
          <Shadow />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default HeroTransitionV4;
