'use client';
import { useCallback, useEffect, useRef } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';

import styles from './CarouselWithToutsSlide.module.css';
import { CarouselWithToutsSlideProps } from './CarouselWithToutsSlide.types';

/**
 * Carousel Tabber slide item.
 * @param media Image or video data object
 * @param isActive Boolean of whether the slide is the currently visible slide. Restarts video is media is a video.
 * @param index Slide index number
 * @param onReady Callback for when the slide's media has loaded
 * @param isInView Boolean from intersection observer for whether the carousel is currently in view
 * @param className
 * @example <CarouselWithToutsSlide media={media} isActive={true} index={1} onReady={onReady} isInView={true}/>
 */
const CarouselWithToutsSlide = ({
  media,
  isActive,
  index,
  onReady,
  isInView,
}: CarouselWithToutsSlideProps) => {
  const ref = useRef<HTMLElement | VideoRef>(null);

  useEffect(() => {
    const isVideo =
      media.media.sanityMedia?.mediaType === 'video' &&
      ref.current &&
      'restart' in ref.current;

    if (isVideo) {
      if (isActive) {
        ref.current.restart(true);
      } else {
        ref.current.restart(false);
      }
    }
  }, [isActive, media.media.sanityMedia?.mediaType]);

  const onMediaReady = useCallback(
    (element: HTMLImageElement | HTMLVideoElement) => {
      if (onReady && typeof index !== 'undefined') {
        onReady(element, index);
      }
    },
    [index, onReady],
  );

  return (
    <Media
      ref={ref}
      className={styles.media}
      sanityMedia={media.media.sanityMedia}
      onReady={onMediaReady}
      isDisplayed={isInView}
      fixedAspectRatio={true}
    />
  );
};

export default CarouselWithToutsSlide;
