'use client';

import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './BookEnd.module.css';
import { BookEndProps } from './BookEnd.types';

const BookEnd = (props: BookEndProps) => {
  const { content, className } = props;
  return (
    <ModuleWrapper className={cn(styles.bookEnd, className)} {...props}>
      <div className={styles.container}>
        <TextLockup
          lockupOptions={content.lockupOptions}
          className={styles.content}
          value={content.blocks}
        />
      </div>
    </ModuleWrapper>
  );
};

export default BookEnd;
