import {
  Camera,
  Geometry,
  Mesh,
  Post,
  Program,
  Renderer,
  TextureLoader,
  Triangle,
} from 'ogl';

import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';

import compositeFragmentShader from './shaders/Composite.frag';
import defaultVertexShader from './shaders/Default.vert';
import maskFragmentShader from './shaders/Mask.frag';
import snowFragmentShader from './shaders/Snow.frag';
import snowVertexShader from './shaders/Snow.vert';
import { SnowRenderer, SnowRendererOptions } from './types';

export default function createSnowRenderer({
  numParticles,
  container,
  particlesTexture,
  armMask,
}: SnowRendererOptions): SnowRenderer {
  if (!(container instanceof HTMLElement)) {
    throw new Error('container element required');
  }

  const renderer = new Renderer({
    dpr: 1,
    alpha: true,
    antialias: false,
    premultipliedAlpha: true,
  });

  if (!renderer.gl) {
    throw new Error('failed to obtain gl rendering context');
  }

  // should the webgl context crash, remove the canvas from the DOM so that
  // we are not showing the default white background
  const canvas = renderer.gl.canvas;
  canvas.addEventListener('webglcontextlost', () => {
    canvas.remove();
    stop();
  });

  const rnd = Math.random;
  const gl = renderer.gl;
  const camera = new Camera(gl, {
    fov: 45,
    near: 0.1,
    far: 1000.0,
  });

  gl.clearColor(0, 0, 0, 0);
  container.appendChild(gl.canvas);

  // load our texture atlas and arm mask textures
  const atlas = TextureLoader.load(gl, { src: particlesTexture.url });
  const mask = TextureLoader.load(gl, { src: armMask.url });

  // create particle geometry with the desired number of particles
  function createParticleGeometry(
    count: number,
    isForeground: boolean = false,
  ) {
    const position = new Float32Array(count * 3);
    const random = new Float32Array(count * 4);

    for (let i = 0; i < count; i++) {
      const pos = [rnd(), rnd(), rnd()];
      if (isForeground) {
        pos[0] *= 2.0;
        pos[1] *= 2.0;
        pos[2] *= 1.5;
      }
      position.set(pos, i * 3);
      random.set([rnd(), rnd(), rnd(), rnd()], i * 4);
    }

    // create the geometry data, glsl program and the mesh object.
    return new Geometry(gl, {
      position: { size: 3, data: position },
      random: { size: 4, data: random },
    });
  }

  // the particle program is re-used across the two passes
  const program = new Program(gl, {
    vertex: snowVertexShader,
    fragment: snowFragmentShader,
    uniforms: {
      uColorMultiplier: { value: [0, 0, 0] },
      uResolution: { value: [renderer.width, renderer.height] },
      uScaleFactor: { value: 1.0 },
      uMouse: { value: [0, 0] },
      uScroll: { value: 0 },
      uScrollFactor: { value: 1.0 },
      uTexture: { value: atlas },
      uTime: { value: 0 },
      uTimeFactor: { value: 1.0 },
      uDT: { value: 0 },
    },
    depthTest: false,
    transparent: true,
  });

  // split particle count between background & foreground
  const bgParticlesScene = new Mesh(gl, {
    mode: gl.POINTS,
    geometry: createParticleGeometry(Math.round(numParticles * 0.9)),
    program,
  });

  const fgParticlesScene = new Mesh(gl, {
    mode: gl.POINTS,
    geometry: createParticleGeometry(Math.round(numParticles * 0.1), true),
    program,
  });

  // background particle post pass. render to texture
  const bgParticles = new Post(gl, { targetOnly: true });
  bgParticles.addPass({ program });

  // arm mask pass to clear out particles overlapping arm & device
  const bgParticlesMask = bgParticles.addPass({
    uniforms: {
      uMask: { value: mask },
      uMaskAlpha: { value: 1.0 },
      uMaskScale: { value: [1.0, 1.0] },
      uMaskPosition: { value: [0, 0] },
      uMaskOffset: { value: [0, 0] },
      uResolution: { value: [renderer.width, renderer.height] },
    },
    vertex: defaultVertexShader,
    fragment: maskFragmentShader,
  });

  // foreground particle post pass, render to texture
  const fgParticles = new Post(gl, { targetOnly: true });
  fgParticles.addPass({ program });

  // combine the two separate particle renders into the final output
  const compositeProgram = new Program(gl, {
    uniforms: {
      uBackground: { value: bgParticles.uniform.value },
      uForeground: { value: fgParticles.uniform.value },
      uSnowAlpha: { value: 1 },
    },
    vertex: defaultVertexShader,
    fragment: compositeFragmentShader,
  });
  const scene = new Mesh(gl, {
    geometry: new Triangle(gl),
    program: compositeProgram,
  });

  // represents the current state of the particle simulation
  let running: boolean = false;
  // the animation frame cancellation token used to stop the simulation
  let rafCancellationToken: number = 0;
  // previous frame timestamp used to calcualte the frame delta
  let previousTick: number = 0;
  // top position of the snow container
  let containerTop: number = 0;

  // primary render loop, update all variables in the program, update the
  // particle simulation etc., and render the frame
  function render(elapsed: number) {
    const resolution = [renderer.width, renderer.height];

    // Apply an offset to the elapsed time so we are not counting up from
    // zero. This has the effect of fast forwarding the simulation and
    // visually distributes the particles and removes the initial cluster
    elapsed += 10_000;

    program.uniforms.uTime.value = elapsed;
    program.uniforms.uDT.value = elapsed - previousTick;
    program.uniforms.uResolution.value = resolution;

    // update scroll position and apply exponential smoothing
    const scrollCurrent = program.uniforms.uScroll.value;
    const scrollY = -containerTop + (ScrollPosition.y || 0);
    const scrollDelta = scrollY - scrollCurrent;

    function integrateScrollPosition(scrollSmoothing: number): number {
      return (
        scrollCurrent * scrollSmoothing + scrollDelta * (1 - scrollSmoothing)
      );
    }

    program.uniforms.uScroll.value = integrateScrollPosition(0.98);
    program.uniforms.uScrollFactor.value = 0.25;

    bgParticlesMask.uniforms.uResolution.value = resolution;

    // render the snow scene with background particles
    program.uniforms.uColorMultiplier.value = [1, 1, 1];
    program.uniforms.uScaleFactor.value = 0.85;
    program.uniforms.uTimeFactor.value = 1.0;
    bgParticles.render({
      scene: bgParticlesScene,
      camera,
    });

    // render the foreground particles
    // program.uniforms.uColorMultiplier.value = [1, 1, 1];
    program.uniforms.uScaleFactor.value = 1.275;
    program.uniforms.uTimeFactor.value = 1.5;
    fgParticles.render({
      scene: fgParticlesScene,
      camera,
    });

    // composite the two passes together
    compositeProgram.uniforms.uBackground.value = bgParticles.uniform.value;
    compositeProgram.uniforms.uForeground.value = fgParticles.uniform.value;

    renderer.render({ scene, camera });

    previousTick = elapsed;

    if (running) {
      rafCancellationToken = requestAnimationFrame(render);
    }
  }

  function start() {
    if (running === true) {
      return;
    }
    running = true;
    rafCancellationToken = requestAnimationFrame(render);
  }

  function stop() {
    cancelAnimationFrame(rafCancellationToken);
    running = false;
    rafCancellationToken = 0;
  }

  function destroy() {
    stop();
    if (gl.canvas) {
      gl.canvas.remove();
    }
  }

  function resize(width: number, height: number) {
    renderer.setSize(width, height);
    camera.perspective({
      aspect: width / height,
    });
    bgParticles.resize();
    fgParticles.resize();
  }

  function setMaskScale(x: number, y: number) {
    bgParticlesMask.uniforms.uMaskScale.value = [x, y];
  }

  function setMaskPosition(x: number, y: number) {
    bgParticlesMask.uniforms.uMaskPosition.value = [x, y];
  }

  function setMaskOffset(x: number, y: number) {
    bgParticlesMask.uniforms.uMaskOffset.value = [x, y];
  }

  function setMaskAlpha(a: number) {
    bgParticlesMask.uniforms.uMaskAlpha.value = a;
  }

  function setContainerTop(y: number) {
    containerTop = y;
  }

  // implement the SnowRenderer interface
  return {
    start,
    stop,
    resize,
    destroy,
    setMaskAlpha,
    setMaskScale,
    setMaskOffset,
    setMaskPosition,
    setContainerTop,
  };
}
