'use client';

import { gsap } from 'gsap';
import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { cn } from '~/utils';

import styles from './CircleLoader.module.css';
import { CircleLoaderProps, CircleLoaderRef } from './CircleLoader.types';
import { snapper } from './CircleLoader.utils';

const snap = snapper(4);

const CircleLoader = (
  { className, loadingDuration }: CircleLoaderProps,
  ref: ForwardedRef<CircleLoaderRef>,
) => {
  const $element = useRef<HTMLDivElement>(null);
  const $percentage = useRef<HTMLElement>(null);
  const [percentage, setPercentage] = useState(0);
  useImperativeHandle(
    ref,
    () => ({
      $element,
      start: () => {
        const dummy = { value: 0 };
        const tl = gsap.timeline();

        const config = {
          duration: loadingDuration || 2,
        };
        tl.fromTo(
          $element.current,
          {
            '--progress': 0,
          },
          {
            '--progress': 1,
            ...config,
          },
          0,
        );
        tl.to(
          dummy,
          {
            value: 100,
            ...config,
            onUpdate() {
              setPercentage(snap(dummy.value));
            },
          },
          0,
        );

        return tl;
      },
    }),
    [],
  );

  return (
    <div className={cn(styles.circleLoader, className)} ref={$element}>
      <svg
        className={styles.progressCircle}
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="25"
          cy="25"
          r="22.5"
          fill="none"
          stroke="#36384A"
          strokeWidth="3"
        />
        <circle
          className={styles.progressBar}
          cx="25"
          cy="25"
          r="22.5"
          fill="none"
          stroke="#5B53FF"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </svg>
      <span className={styles.progressValue}>
        <strong ref={$percentage}>{percentage}</strong>%
      </span>
    </div>
  );
};

export default forwardRef<CircleLoaderRef, CircleLoaderProps>(CircleLoader);
