import { KeenSliderInstance } from 'keen-slider/react';
import { MutableRefObject } from 'react';

export type PaginationDotsProps = {
  className?: string;
  /**
   * Total number of slides
   */
  total: number;
  /**
   * Ref to the keen slider instance that the pagination dots correspond to. Passed so the dots can access its current track details.
   */
  parentSliderRef: MutableRefObject<KeenSliderInstance | null>;
};

export const MIN_DOTS = 5;
