import { gsap } from 'gsap';

/**
 * The `clampedMapRange` utility uses a combination of `mapRange` and `clamp`
 * from the gsap package to ensure that the output never exceeds the min/max
 * range specified.
 *
 * @param inMin Minimim input value
 * @param inMax Maximum input value
 * @param outMin Minimum output value
 * @param outMax Maximum output value
 * @param value Value within input range
 * @returns Mapped value onto the output range
 */
export function clampedMapRange(
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
  value: number,
): number {
  return gsap.utils.mapRange(
    inMin,
    inMax,
    outMin,
    outMax,
    gsap.utils.clamp(inMin, inMax, value),
  );
}
