import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn } from '~/utils';

import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import styles from './StandaloneText.module.css';
import { StandaloneTextProps } from './StandaloneText.types';

const StandaloneText = (props: StandaloneTextProps) => {
  const { className, content, contentColumns } = props;

  const renderClass = cn(styles.standaloneText, className);

  return (
    <ModuleWrapper className={renderClass} {...props}>
      <div className={styles.container}>
        {content && (
          <TextLockup
            value={content.blocks}
            lockupOptions={content.lockupOptions}
          />
        )}

        {contentColumns && (
          <div className={styles.columnsWrapper}>
            <TextLockup
              className={styles.contentCol1}
              value={contentColumns.col1.blocks}
              lockupOptions={contentColumns.col1.lockupOptions}
            />

            <TextLockup
              className={styles.contentCol2}
              value={contentColumns.col2.blocks}
              lockupOptions={contentColumns.col2.lockupOptions}
            />
          </div>
        )}
      </div>
    </ModuleWrapper>
  );
};

export default StandaloneText;
