import { BreakpointName } from '~/types';

import { CMSPricingProduct, PricePeriodValue } from '../../Pricing.types';

export const MAX_BULLETS = 7;

export type PricingCardVariant = 'full' | 'reduced';

export type PricingCardLinkType = 'internal' | 'external' | 'anchor';

export type PricingCardFeaturesLink = {
  type: PricingCardLinkType;
} & (
  | { type: 'internal'; url: string }
  | { type: 'external'; url: string }
  | { type: 'anchor'; hash: string }
);

export type PricingCardProps = {
  product: CMSPricingProduct;
  pricePeriod: PricePeriodValue;
  highlighted: boolean;
  variant: PricingCardVariant;
  featuresLink: PricingCardFeaturesLink;
  numSibblings?: number;
};

export const EXPAND_BREAKPOINTS: BreakpointName[] = ['sm'];
