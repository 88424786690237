import styles from '../DebugUI.module.css';

export type NumericSliderProps = {
  id: string;
  label: string;
  min: number;
  max: number;
  value: number;
  step?: number;
  onChange: (v: number) => void;
};

export const NumericSlider = ({
  id,
  label,
  min,
  max,
  value,
  step,
  onChange,
}: NumericSliderProps) => {
  return (
    <div className={styles.numericSlider}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type="range"
        min={min}
        max={max}
        step={step || 0.0001}
        value={value}
        onChange={(evt) => {
          onChange(parseFloat(evt.target.value));
        }}
      />
      <span className={styles.value}>{value}</span>
    </div>
  );
};
