'use client';
import { gsap } from 'gsap';
import { CSSProperties, RefObject, useCallback, useRef, useState } from 'react';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import FloatingVideoPreview from '~/components/molecules/FloatingVideoPreview/FloatingVideoPreview';
import Media from '~/components/molecules/Media/Media';
import { PortableTextCustomDataProps } from '~/components/molecules/PortableText/PortableText.types';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import decorationsStyles from '~/styles/theme/decorations.module.css';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import { coveredHeroAnimation, heroAnimation } from './Hero.animations';
import styles from './Hero.module.css';
import { HeroProps } from './Hero.types';

/**
 * Hero component
 * @param background Css string value for the module's background color
 * @param content Portable text content
 * @param alignment Vertical and Horizontal alignment of hte content, defaults to 'leftBottom'
 * @param heroHeight Height of the hero, defaults to 'full'
 * @param gradientOverlay String value for the name of gradient overlay that sits on top of the background image
 * @param media Image or video, displayed as the background for the hero.
 * @param floatingVideoPreview (optional) Video with a floating preview that opens in a fullscreen modal
 * @param className
 * @example <Hero media={media} content={content}/>
 */
const Hero = (props: HeroProps) => {
  const {
    className,
    content,
    media,
    alignment,
    heroHeight,
    floatingVideoPreview,
    isCoveredModule,
  } = props;

  const $background = useRef<HTMLElement | VideoRef>(null);
  const $element = useRef<HTMLDivElement>(null);
  const $overlay = useRef<HTMLDivElement>(null);
  const $textContainer = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState<HTMLVideoElement>();

  const renderClasses = cn(styles.hero, className);

  // animation reference
  const animation = useRef<GSAPTimeline>();

  const buttonGroupBlock = content.blocks.find(
    (block) => block._type === 'block.buttonGroup',
  ) as PortableTextCustomDataProps;

  const numberOfButtons = buttonGroupBlock
    ? buttonGroupBlock.buttonGroup?.length
    : 0;

  useLayoutEffect(() => {
    if ($background.current) {
      const animationType = isCoveredModule
        ? coveredHeroAnimation
        : heroAnimation;

      animation.current = animationType({
        $overlay,
        $background: $background as RefObject<HTMLElement>,
        $textContainer,
      });
    }
  }, [isCoveredModule, media]);

  const onProgress = useCallback(
    (progress: number) => {
      const animationProgress = isCoveredModule
        ? gsap.utils.mapRange(0.3, 0.7, 0, 1, progress)
        : progress;
      animation.current?.progress(animationProgress);
    },
    [isCoveredModule],
  );

  useScrollProgress($element, onProgress);

  return (
    <ModuleWrapper
      className={cn(
        renderClasses,
        alignment.includes('left') ? styles['left'] : styles['center'],
        alignment.includes('Top') ? styles['top'] : styles['middle'],
        alignment.includes('Bottom') ? styles['bottom'] : styles['middle'],
        floatingVideoPreview && styles.hasFloatingVideoPreview,
      )}
      {...props}
      ref={$element}
      style={{ '--nb-buttons': numberOfButtons } as CSSProperties}
    >
      <div
        ref={$textContainer}
        className={cn(
          styles.container,
          heroHeight === 'organic' && styles.organicHeight,
          isCoveredModule &&
            alignment.includes('Bottom') &&
            styles.isCoveredAndBottom,
        )}
      >
        <TextLockup
          lockupOptions={content.lockupOptions}
          className={styles.content}
          value={content.blocks}
        />
        {floatingVideoPreview && (
          <FloatingVideoPreview
            options={floatingVideoPreview}
            className={styles.floatingVideoPreview}
            $parent={$element}
          />
        )}
      </div>
      {media.media && (
        <EnhancedMedia
          overlay={media.overlay}
          className={styles.backgroundWrapper}
        >
          <Media
            sanityMedia={media.media.sanityMedia}
            className={cn(
              styles.backgroundImageContainer,
              !isReady &&
                media.media.sanityMedia.mediaType === 'video' &&
                styles.isHidden,
            )}
            aspectRatio={1.5}
            onVideoReady={setIsReady}
            forceIsInView={true}
            ref={$background}
          />
        </EnhancedMedia>
      )}
      <div ref={$overlay} className={decorationsStyles.overlay} />
    </ModuleWrapper>
  );
};

export default Hero;
