'use client';

import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn } from '~/utils';

import styles from './ButtonCard.module.css';
import { ButtonCardProps } from './ButtonCard.types';

const ButtonCard = ({ blurredBackground, content }: ButtonCardProps) => {
  return (
    <div
      className={cn(
        styles.buttonCard,
        blurredBackground && styles.blurredBackground,
      )}
    >
      <TextLockup
        value={content.blocks}
        lockupOptions={content.lockupOptions}
        className={styles.content}
      />
    </div>
  );
};

export default ButtonCard;
