import Graphic from '~/components/atoms/Graphic/Graphic';
import { LogoTextItemProps } from '~/components/molecules/LogoTextItem/LogoTextItem.types';
import { cn } from '~/utils';

import styles from './LogoTextItem.module.css';

// TODO: Handle sizes for images
const LogoTextItem = ({
  label,
  logo,
  className,
  labelClassName,
  hideLabelInTile,
  chiclet = false,
  isInView,
}: LogoTextItemProps) => (
  <div
    className={cn(
      styles.logoTextItem,
      chiclet && styles.chiclet,
      hideLabelInTile && styles.iconOnly,
      className,
    )}
  >
    <div className={styles.iconWrapper}>
      <Graphic {...logo} className={styles.icon} isInView={isInView} />
    </div>

    <div
      className={cn(styles.labelWrapper, hideLabelInTile && 'visuallyHidden')}
    >
      <p className={cn(styles.label, labelClassName)}>{label}</p>
    </div>
  </div>
);

export default LogoTextItem;
