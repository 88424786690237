import { Camera, OGLRenderingContext, RenderTarget, Transform } from 'ogl';

import { CMSEnhancedMedia } from '~/components/molecules/EnhancedMedia/EnhancedMedia.types';
import { CMSModuleBase } from '~/components/organisms/ModularComponent/ModularComponent.types';

import { ShaderParameters } from './HeroGradient.store';

export const CANVAS_SCALE_FACTOR = 0.5;

export type DoubleFBO = {
  read: RenderTarget;
  write: RenderTarget;
  swap(): void;
};

export type Splat = {
  x: number;
  y: number;
  dx: number;
  dy: number;
};

export type HeroGradient = CMSModuleBase & {
  _type: 'sectionBackground.heroGradient';
  media: CMSEnhancedMedia;
  debugUI: boolean;
};

export type HeroGradientRenderer = {
  gl: OGLRenderingContext;
  camera: Camera;
  root: Transform;
  setSize(width: number, height: number): void;
  render(): void;
};

export type InitMessage = {
  type: 'init';
  sampleImageURL: string;
  canvas: OffscreenCanvas;
  width: number;
  height: number;
};

export type ResizeMessage = {
  type: 'resize';
  width: number;
  height: number;
};

export type PlayMessage = {
  type: 'play';
};

export type PauseMessage = {
  type: 'pause';
};

export type MouseMessage = {
  type: 'mousemove';
  x: number;
  y: number;
};

export type ShaderParameterMessage = {
  type: 'shader-parameters';
  params: ShaderParameters;
};

export type HeroGradientMessage = MessageEvent<
  | InitMessage
  | ResizeMessage
  | PlayMessage
  | PauseMessage
  | MouseMessage
  | ShaderParameterMessage
>;
