'use client';

import { gsap } from 'gsap';
import { createRef, useCallback, useEffect, useMemo, useRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import useUIStore from '~/state/ui';
import {
  cn,
  isBreakpointOrGreater,
  isBreakpointOrSmaller,
  useScrollProgress,
} from '~/utils';

import ModuleWrapper from '../../ModuleWrapper/ModuleWrapper';
import styles from './StandoutMosaic.module.css';
import {
  CARDS_PER_COLUMN,
  COLUMN_OFFSETS,
  MosaicColumn,
  StandoutMosaicProps,
} from './StandoutMosaic.types';
import { clampedMapRange } from './StandoutMosaic.utils';

const StandoutMosaic = (props: StandoutMosaicProps) => {
  const $container = useRef<HTMLDivElement>(null);
  const $device = useRef<HTMLDivElement>(null);
  const $mosaic = useRef<HTMLDivElement>(null);
  const $lockup = useRef<HTMLDivElement>(null);
  const $deviceUI = useRef<HTMLElement>(null);
  const breakpoint = useUIStore((state) => state.breakpoint);

  // convert the flat array of cards into columns/cards
  const columns = useMemo(() => {
    const result: MosaicColumn[] = [];
    let current: MosaicColumn | null = null;

    for (const [i, image] of props.mosaicCards.entries()) {
      if (i % CARDS_PER_COLUMN === 0) {
        const index = result.length;
        const offset = COLUMN_OFFSETS[index] * 4.0;
        current = {
          index,
          offset,
          element: createRef<HTMLDivElement>(),
          cards: [],
        };
        result.push(current);
      }
      current?.cards.push({
        index: i % CARDS_PER_COLUMN,
        element: createRef<HTMLDivElement>(),
        image,
      });
    }

    return result;
  }, [props.mosaicCards]);

  const setMosaicProgress = useCallback(
    (progress: number) => {
      if (isBreakpointOrSmaller(breakpoint, 'sm')) {
        return;
      }
      const titleProgress = clampedMapRange(0, 1, -1, 1, progress);
      const titleFadeout = clampedMapRange(0.45, 0.55, 0, 1, progress);
      const deviceProgress = clampedMapRange(0.2, 0.8, -1, 1, progress);

      gsap.to($lockup.current, {
        duration: 1,
        ease: 'expo.out',
        y: `${titleProgress * -6}rem`,
        opacity: `${1 - titleFadeout}`,
      });

      gsap.to($device.current, {
        duration: 1,
        ease: 'expo.out',
        y: `${28 + deviceProgress * -28}rem`,
      });

      columns.forEach((column) => {
        const columnOffset = clampedMapRange(0, 0.8, 0, 1, progress);
        const columnY = columnOffset * column.offset * 35;

        gsap.to(column.element.current, {
          duration: 1.5,
          ease: 'expo.out',
          y: `${columnY}rem`,
        });

        column.cards.forEach((card) => {
          const cardProgress = clampedMapRange(0, 1, 1, -1, progress);

          gsap.to(card.element.current, {
            duration:
              0.25 + card.index * ((column.index + 2) % columns.length) * 0.4,
            ease: 'power3.out',
            y: `${cardProgress * 12}rem`,
          });
        });
      });
    },
    [columns, $device, breakpoint],
  );

  useScrollProgress($container, setMosaicProgress, {
    startOnMiddleOfScreen: false,
    finishOnMiddleOfScreen: false,
    shouldAlwaysComplete: true,
  });

  useEffect(() => {
    if (isBreakpointOrGreater(breakpoint, 'md')) {
      return;
    }

    gsap.killTweensOf($lockup.current);
    gsap.set($lockup.current, {
      y: 0,
      opacity: 1,
      clearProps: 'all',
    });

    gsap.killTweensOf($device.current);
    gsap.set($device.current, {
      y: 0,
      clearProps: 'all',
    });

    columns.forEach((column) => {
      const columnY = column.offset * 10;

      gsap.killTweensOf(column.element.current);
      gsap.set(column.element.current, {
        y: `${columnY}rem`,
      });

      column.cards.forEach((card) => {
        gsap.killTweensOf(card.element.current);
        gsap.set(card.element.current, {
          clearProps: 'all',
          y: 0,
        });
      });
    });
  }, [breakpoint, columns]);

  return (
    <ModuleWrapper
      className={cn(props.className, styles.container)}
      ref={$container}
      {...props}
    >
      <div className={styles.title} ref={$lockup}>
        <TextLockup
          lockupOptions={props.title.lockupOptions}
          value={props.title.blocks}
        />
      </div>
      <div className={styles.mosaicWrapper} ref={$mosaic}>
        <div className={styles.deviceWrapper} ref={$device}>
          <EnhancedMedia
            overlay={props.deviceUI.overlay}
            className={styles.deviceMedia}
          >
            <Media
              sanityMedia={props.deviceUI.media.sanityMedia}
              quality={90}
              forceIsInView={true}
              autoResize={false}
              willAutoplay={false}
              isLooping={true}
              fixedAspectRatio={true}
              ref={$deviceUI}
            />
          </EnhancedMedia>
        </div>
        {columns.map((column) => {
          return (
            <div
              className={styles.cardColumn}
              key={`column-${column.index}`}
              ref={column.element}
              data-column-index={column.index}
            >
              {column.cards.map((card) => {
                return (
                  <div
                    className={styles.card}
                    key={`card-${card.index}`}
                    ref={card.element}
                    data-card-index={card.index}
                  >
                    <Image source={card.image} fixedAspectRatio={true} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </ModuleWrapper>
  );
};

export default StandoutMosaic;
