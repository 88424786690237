import { PricingCardFeaturesLink } from './PricingCard.types';

export function getFeaturesLinkURL(link: PricingCardFeaturesLink): string {
  if (!link) {
    return '';
  }
  switch (link.type) {
    case 'anchor':
      return link.hash;
    case 'external':
    case 'internal':
      return link.url;
  }
}
