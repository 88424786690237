import styles from '../DebugUI.module.css';

export type CheckboxProps = {
  id: string;
  label: string;
  value: boolean;
  onChange: (v: boolean) => void;
};

export const Checkbox = ({ id, label, value, onChange }: CheckboxProps) => {
  return (
    <div className={styles.checkbox}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        type="checkbox"
        checked={value}
        onChange={(evt) => {
          onChange(evt.target.checked);
        }}
      />
      <span className={styles.value}>{value}</span>
    </div>
  );
};
