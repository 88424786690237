'use client';

import { default as CarouselMolecule } from '~/components/molecules/Carousel/Carousel';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn } from '~/utils';

import styles from './Carousel.module.css';
import { CarouselProps } from './Carousel.types';

/**
 * Carousel component
 * @param slides An array of slide data objects
 * @param className
 * @example <Carousel slides={slides]/>
 */
const Carousel = (props: CarouselProps) => {
  const { accentType, slides, className, variant = 'oneCard' } = props;
  const renderClasses = cn(styles.carousel, className);

  return (
    <ModuleWrapper className={renderClasses} {...props}>
      <CarouselMolecule
        slides={slides}
        accentType={accentType}
        variant={variant}
      />
    </ModuleWrapper>
  );
};

export default Carousel;
