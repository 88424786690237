'use client';
import { gsap } from 'gsap';
import React, { useCallback, useEffect, useRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Glow from '~/components/molecules/Glow/Glow';
import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import Touts from '~/components/molecules/Touts/Touts';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from './StickyBackground.module.css';
import stickyBackgroundStore from './StickyBackground.store';
import {
  StickyBackgroundProps,
  StickyBackgroundSection,
  StickyBackgroundSectionTouts,
  THRESHOLD,
} from './StickyBackground.types';

const enterProgressTransformer = gsap.utils.pipe(
  gsap.utils.normalize(0, THRESHOLD),
  gsap.utils.clamp(0, 1),
);
const leaveProgressTransformer = gsap.utils.pipe(
  gsap.utils.normalize(1 - THRESHOLD, 1),
  gsap.utils.clamp(0, 1),
);

/**
 * Sticky background module
 * @description Scrolling text with a sticky background image and video
 * @param video Video displayed on top of the background image
 * @param sections Sections containing PortableText content rich text or touts
 */
const StickyBackground = (props: StickyBackgroundProps) => {
  const { video, sections, className } = props;
  const $wrapper = useRef<HTMLDivElement>(null);
  const $sections = useRef<HTMLDivElement[]>([]);
  const $media = useRef<VideoRef>(null);
  const enterProgressSetter = useRef<(value: number) => void>();
  const stickyBackgroundImage =
    stickyBackgroundStore.getState().stickyBackgroundImage;
  const windowWidth = useUIStore((state) => state.windowWidth);
  const windowHeight = useUIStore((state) => state.windowHeight);
  const isComputedStyleComplete = useUIStore(
    (state) => state.isComputedStyleComplete,
  );

  useEffect(() => {
    // use the height of the first and last sections to center align to the TV
    const firstSectionHeight = Math.round(
      $sections.current[0].getBoundingClientRect().height,
    );
    const lastSectionHeight = Math.round(
      $sections.current[$sections.current.length - 1].getBoundingClientRect()
        .height,
    );

    gsap.set($wrapper.current, {
      '--first-section-height': `${firstSectionHeight / 2}px`,
      '--last-section-height': `${lastSectionHeight / 2}px`,
    });
  }, [windowWidth, windowHeight, isComputedStyleComplete]);

  // Track visibility via useScrollProgress, to avoid re-render
  const onProgress = useCallback((progress: number, isInView: boolean) => {
    if (enterProgressSetter.current)
      enterProgressSetter.current(
        Math.max(
          1 - enterProgressTransformer(progress),
          leaveProgressTransformer(progress),
        ),
      );

    if ($media && $media.current && $media.current.$player) {
      if (isInView && $media.current.$player.paused === true) {
        $media.current.play();
      } else if (!isInView && $media.current.$player.paused !== true) {
        $media.current.pause();
      }
    }
  }, []);

  useScrollProgress($wrapper, onProgress);

  useLayoutEffect(() => {
    enterProgressSetter.current = gsap.quickSetter(
      $wrapper.current,
      '--stickybg-enter-progress',
    ) as (value: number) => void;
  }, []);

  return (
    <ModuleWrapper
      ref={$wrapper}
      className={cn(styles.stickyBackground, className)}
      {...props}
    >
      <div className={styles.innerContainer}>
        <div className={styles.background}>
          <div className={styles.backgroundWrapper}>
            <Glow className={styles.backgroundGlow} source={video.glow} />
          </div>
        </div>

        <div className={styles.foreground}>
          <div className={styles.foregroundWrapper}>
            <div className={styles.videoWrapper}>
              {stickyBackgroundImage && (
                <Image
                  className={styles.foregroundImage}
                  source={stickyBackgroundImage.backgroundAsset}
                  fixedAspectRatio={true}
                />
              )}

              <Media
                className={styles.video}
                ref={$media}
                sanityMedia={video.video}
                isLooping={video.video.isLooping}
                forceIsInView={false}
                fixedAspectRatio={true}
              />
            </div>
          </div>
        </div>

        <div className={styles.container}>
          {sections.map(
            (
              section: StickyBackgroundSection | StickyBackgroundSectionTouts,
              index: number,
            ) => {
              return (
                <div
                  ref={(node: HTMLDivElement) =>
                    ($sections.current[index] = node)
                  }
                  className={styles.section}
                  key={section._key}
                >
                  {section._type === 'stickyBackgroundSection' && (
                    <TextLockup
                      className={styles.sectionContentText}
                      value={section.text.blocks}
                      lockupOptions={section.text.lockupOptions}
                    />
                  )}

                  {section._type === 'stickyBackgroundSectionTouts' && (
                    <Touts
                      className={styles.sectionContentTouts}
                      touts={section.touts}
                      title={section.title}
                      tallestWrapperHeight={section.tallestWrapperHeight}
                      isTile={false}
                      isColumnLayout={true}
                    />
                  )}
                </div>
              );
            },
          )}
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default StickyBackground;
