import { gsap } from 'gsap';
import debounce from 'lodash/debounce';
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

import UIStore from '~/state/ui';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';

import { ScrollPositionValue } from '../domEvents/ScrollPosition/ScrollPosition.types';
import isBreakpointOrGreater from '../isBreakpointOrGreater';
import { tickerAdd, tickerRemove } from '../ticker';
import {
  DURATION,
  EASE,
  MarqueeConfig,
  PROGRESS_DELTA_STEPS,
} from './useMarquee.type';

const useUIStore = UIStore;

// TODO: For some unknown reason yet,the marquee can show some form a jankiness on iOS Safari. We might need QA team's help on this one.
/**
 * Create & animate a "marquee" type of animation. provides a play / pause function
 * @param elements The elements to animate, should be a ref array of elements
 * @param config Configuration for the animation.
 */
export default function useMarquee(
  $elements: RefObject<HTMLElement> | RefObject<HTMLElement[]>,
  config: MarqueeConfig = {
    speed: 1,
  },
) {
  const isPlaying = useRef(false);
  const animRefs = useRef<Animation[]>([]);

  const breakpoint = useUIStore((state) => state.breakpoint);
  const isTabletOrGreater = useMemo(
    () => breakpoint && isBreakpointOrGreater(breakpoint, 'md'),
    [breakpoint],
  );
  const vars = useRef<{
    lastY: ScrollPositionValue;
    deltaY: number;
    width: number;
    progress: number;
    progressTarget: number;
  }>({
    lastY: 0,
    deltaY: 0,
    width: 0,
    progress: 1,
    progressTarget: 0,
  });

  useEffect(() => {
    const elements = $elements.current;
    const anims = animRefs.current;

    if (!elements) return;

    const element = Array.isArray(elements) ? elements[0] : elements;

    const resetAnimations = () => {
      const gap = parseFloat(getComputedStyle(element).gap);
      vars.current.width = element.getBoundingClientRect().width + gap;
      vars.current.progressTarget = vars.current.progress;
      if (Array.isArray(elements)) {
        elements.forEach((element, index) => {
          // if an anim has already been set, cancel it and reset it
          if (anims[index]) anims[index].cancel();
          anims[index] = element.animate(
            [
              {
                transform: 'translate3d(0px, 0, 0)',
              },
              {
                transform: `translate3d(${-vars.current.width}px, 0, 0)`,
              },
            ],
            {
              duration: DURATION,
              iterations: 10,
            },
          );
          animRefs.current[index].pause();
          animRefs.current[index].persist();
        });
      }
    };

    resetAnimations();

    const unsubscribeWindowWidth = UIStore.subscribe(
      (state) => state.windowWidth,
      debounce(resetAnimations, 800),
    );

    return () => {
      unsubscribeWindowWidth();
      anims.forEach((anim) => {
        anim.cancel();
      });
    };
  }, [$elements, config, breakpoint]);

  const render = useCallback(() => {
    const { y: scrollY } = ScrollPosition.getPosition();
    if (scrollY !== null && vars.current.lastY !== null)
      vars.current.deltaY = scrollY - vars.current.lastY;
    const deltaThreshold = isTabletOrGreater
      ? config.deltaThreshold || 60
      : config.deltaThresholdMobile || 10;

    vars.current.progressTarget +=
      Math.max(-deltaThreshold, Math.min(vars.current.deltaY, deltaThreshold)) *
      0.00015 *
      config.speed;
    const deltaRatio = gsap.ticker.deltaRatio();

    // increase progress over time (only > medium)
    vars.current.progressTarget += Math.min(
      2,
      PROGRESS_DELTA_STEPS * deltaRatio,
    );

    vars.current.progress +=
      (vars.current.progressTarget - vars.current.progress) * EASE * deltaRatio;

    animRefs.current.forEach((anim) => {
      anim.currentTime = Math.abs(vars.current.progress % 1) * DURATION;
    });

    vars.current.lastY = scrollY;
  }, [
    isTabletOrGreater,
    config.speed,
    config.deltaThreshold,
    config.deltaThresholdMobile,
  ]);

  const play = useCallback(() => {
    if (!isPlaying.current) {
      isPlaying.current = true;
      tickerAdd(render);
    }
  }, [render]);

  const pause = useCallback(() => {
    isPlaying.current = false;
    tickerRemove(render);
  }, [render]);

  return { play, pause };
}
