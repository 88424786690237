'use client';
import { gsap } from 'gsap';
import { CSSProperties, useCallback, useEffect, useRef } from 'react';

import Shadow from '~/components/molecules/Shadow/Shadow';
import useNavState from '~/components/organisms/Navigation//Navigation.state';
import useUIStore from '~/state/ui';
import { cn, useScrollProgress } from '~/utils';
import isBreakpointOrGreater from '~/utils/isBreakpointOrGreater';
import Easing, { EaseType } from '~/utils/singletons/Easing';

import styles from './PageSection.module.css';
import { PageSectionProps } from './PageSection.types';
import {
  getSectionBackgroundComponent,
  opacityTransform,
} from './PageSection.utils';

/**
 * A page section, containing modules and options
 * @param modules array of modules in the section
 * @param moduleTypes array of all the the module types within the section
 * @param background a string containing the section's background color
 * @param sectionIndex the index of the section on the page
 * @param stackingOrder whether the section is stacked on top of, below, or at the same level as the previous section
 * @param zIndex the z index of the section, based on the stacking order setting in the CMS
 * @param overflowHidden set overflow hidden
 * @param shouldMaintainAspectRatio should the section always maintain its aspect ratio on scroll (no parallax)
 * @param followsShouldMaintainAspectRatio does the section immediately follow a section that should maintain its aspect ratio (no parallax)
 * @param parallaxRevealType the sections parallax/overlap reveal type
 * @param isLastSection if the section is the last section on the page
 * @example <PageSection
          sectionIndex={1}
          stackingOrder={above}
          modules={...}
        />
 */

const PageSection = ({
  className,
  background,
  backgroundComponent,
  sectionIndex,
  stackingOrder: initialStackingOrder,
  nextSectionStackingOrder,
  zIndex,
  overflowHidden,
  moduleTypes,
  shouldMaintainAspectRatio,
  followsShouldMaintainAspectRatio,
  parallaxRevealType,
  isLastSection,
  children,
}: PageSectionProps) => {
  const mobileNavOpen = useNavState((state) => state.mobileNavOpen);

  const $el = useRef<HTMLDivElement>(null);
  const $shadow = useRef<HTMLDivElement>(null);
  const $dummyTop = useRef<HTMLDivElement>(null);
  const $dummyBottom = useRef<HTMLDivElement>(null);

  const breakpoint = useUIStore((state) => state.breakpoint);
  const setPageSectionsRefs = useUIStore((state) => state.setPageSectionsRefs);

  const enterProgress = useRef(0);
  const parallaxTween = useRef<GSAPTween>();
  const shouldParallax = breakpoint && isBreakpointOrGreater(breakpoint, 'md');

  const opacitySetter = useRef<(value: number) => void>();
  const layerOpacity = useRef(0);

  // the option to change stacking order is hidden for the first module in the CMS
  // therefore, the stacking order for the first section will always be undefined
  const stackingOrder = sectionIndex === 0 ? undefined : initialStackingOrder;

  // sections will have negative margins to account for overlap, but the first section shouldn't have any negative margin
  const hasOverlapTop = sectionIndex !== 0 && stackingOrder === 'below';
  const hasOverlapBottom =
    nextSectionStackingOrder === 'above' || isLastSection;

  // add rounded corners to sections
  const hasRoundedTop = stackingOrder === 'above';
  const hasRoundedBottom = nextSectionStackingOrder === 'below';

  // a "below" section, revealed by the previous section
  const isRevealedSection =
    sectionIndex !== 0 &&
    !shouldMaintainAspectRatio &&
    stackingOrder === 'below';

  // an "above" section, covering the previous section
  const isCoveringSection =
    sectionIndex !== 0 &&
    !shouldMaintainAspectRatio &&
    stackingOrder === 'above' &&
    !followsShouldMaintainAspectRatio;

  // a section being covered by the next "above" section
  const isCoveredSection = nextSectionStackingOrder === 'above';

  const isSectionSameLevel =
    sectionIndex !== 0 &&
    stackingOrder !== 'above' &&
    stackingOrder !== 'below';
  const isNextSectionSameLevel =
    nextSectionStackingOrder !== 'above' &&
    nextSectionStackingOrder !== 'below';

  // covered sections will fade out unless, unless configured not to
  // const maintainOpacityOnLeave = moduleTypes.some((value) =>
  //   SHOULD_MAINTAIN_OPACITY_ON_LEAVE_COMPONENTS.includes(value),
  // );
  const maintainOpacityOnLeave = false;

  // Check if the section has a FAB to add extra space at the bottom
  const hasFab = moduleTypes.includes('floatingActionButton');
  // Remove extra space for FAB if it's the last module
  const hasFabLast =
    moduleTypes[moduleTypes.length - 1] === 'floatingActionButton';

  useEffect(() => {
    if ($el.current) setPageSectionsRefs($el.current, sectionIndex);
  }, [setPageSectionsRefs, sectionIndex]);

  useEffect(() => {
    // all parallaxing sections will move from a start y position (negative for below elements and positive for above sections) to 0 (its position without parallax). the starting y position depends on the section's parallax intensity setting (default, light or heavy) and its value is set in PageSection.css
    parallaxTween.current = gsap.to(
      // if there's a shadow, apply parallax to it too
      $shadow.current ? [$el.current, $shadow.current] : $el.current,
      {
        y: 0,
        paused: true,
        easing: Easing.getEasingFunction(EaseType.OUT),
      },
    );
  }, [parallaxRevealType, stackingOrder]);

  useEffect(() => {
    if (shouldParallax) {
      opacitySetter.current = gsap.quickSetter(
        $el.current,
        '--section-layer-opacity',
      ) as (value: number) => void;
    }
  }, [shouldParallax]);

  const moveSection = useCallback(() => {
    if (!shouldParallax) return;
    parallaxTween.current?.progress(enterProgress.current);
  }, [shouldParallax]);

  const fadeSection = useCallback(() => {
    if (!shouldParallax) return;

    if (opacitySetter.current) {
      opacitySetter.current(layerOpacity.current);
    }
  }, [shouldParallax]);

  // tracks the progress of the top edge of the section from the bottom of the viewport to the top of the viewport (value from 0 to 1)
  const onEnterProgress = useCallback(
    (progress: number) => {
      // If progress = 0 we re-enter the section, we want to make sure it's not faded out anymore due to the onLeaveProgress
      if (progress === 0) {
        layerOpacity.current = 0;
        fadeSection();
      }
      enterProgress.current = progress;
      moveSection();
    },
    [moveSection, fadeSection],
  );

  // tracks the progress of the bottom edge of the section from the bottom of the viewport to the top of the viewport (value from 0 to 1)
  const onLeaveProgress = useCallback(
    (progress: number) => {
      // covered sections will fade out unless (unless set to maintain opacity on leave)
      if (isCoveredSection && !maintainOpacityOnLeave)
        layerOpacity.current = opacityTransform(progress);

      fadeSection();
    },
    [isCoveredSection, maintainOpacityOnLeave, fadeSection],
  );

  useScrollProgress($dummyTop, onEnterProgress);
  useScrollProgress($dummyBottom, onLeaveProgress);

  const moduleClassNames = moduleTypes
    ?.map((item: string) => (styles[item] === undefined ? '' : styles[item]))
    .join(' ');

  return (
    <section
      className={cn(
        styles.pageSectionWrapper,
        className,
        hasOverlapTop && styles.hasOverlapTop,
        hasOverlapBottom && styles.hasOverlapBottom,
        stackingOrder === 'above' && styles.above,
        overflowHidden && styles.overflowHidden,
        styles[parallaxRevealType],
      )}
      style={
        {
          zIndex: zIndex,
          ...(background ? { '--section-background': background } : {}),
        } as CSSProperties
      }
      aria-hidden={mobileNavOpen ? true : false}
    >
      <div ref={$dummyTop} className={styles.dummyTop}></div>
      <div
        ref={$el}
        className={cn(
          styles.pageSection,
          hasFab && styles.hasFab,
          hasFabLast && styles.hasFabLast,
          isSectionSameLevel && styles.isSameLevelSection,
          isNextSectionSameLevel && styles.isNextSectionSameLevel,
          hasRoundedTop && styles.hasRoundedTop,
          hasRoundedBottom && styles.hasRoundedBottom,
          isRevealedSection && styles.isRevealedSection,
          isCoveringSection && styles.isCoveringSection,
          isCoveredSection && styles.isCoveredSection,
          moduleClassNames,
        )}
      >
        {backgroundComponent.map(getSectionBackgroundComponent)}
        {children}
      </div>

      <div ref={$dummyBottom} className={styles.dummyBottom}></div>

      {hasRoundedBottom && (
        <div
          className={cn(
            styles.shadowWrapper,
            isRevealedSection && styles.isRevealedSection,
            isCoveringSection && styles.isCoveringSection,
          )}
          ref={$shadow}
        >
          <Shadow className={styles.shadow} />
        </div>
      )}
    </section>
  );
};

export default PageSection;
