'use client';
import { gsap } from 'gsap';
import { CSSProperties, Fragment, useCallback, useRef, useState } from 'react';

import Image from '~/components/atoms/Image/Image';
import Observer from '~/components/atoms/Observer/Observer';
import Glow from '~/components/molecules/Glow/Glow';
import Shadow from '~/components/molecules/Shadow/Shadow';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import {
  cn,
  useIsomorphicLayoutEffect as useLayoutEffect,
  useScrollProgress,
} from '~/utils';

import styles from './ImageCloud.module.css';
import { ImageCloudProps, NUMBER_OF_IMAGES } from './ImageCloud.types';

const parallaxFactors = styles.parallaxFactors
  .split(' ')
  .map((factor) => parseFloat(factor));

/**
 * Image cloud module: displays 9 images in pseudo random positions, with a parallax effect
 * @param props.content Portable Text blocks
 * @param props.images Sanity images
 */
const ImageCloud = (props: ImageCloudProps) => {
  const { content, images, className } = props;
  const $imagesWrapper = useRef<HTMLDivElement>(null);
  const $imageWrappers = useRef<Array<HTMLDivElement | null>>(
    new Array<null>(NUMBER_OF_IMAGES * 2).fill(null),
  );
  const progressSetters = useRef<Array<((value: number) => void) | null>>(
    new Array<null>(NUMBER_OF_IMAGES * 2).fill(null),
  );
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  const breakpoint = useUIStore((state) => state.breakpoint);

  useLayoutEffect(() => {
    let i = 0;
    for (const $imageWrapper of $imageWrappers.current) {
      progressSetters.current[i] = gsap.quickSetter(
        $imageWrapper,
        'y',
        'vw',
      ) as (value: number) => void;
      i++;
    }
  }, []);

  const onProgress = useCallback(
    (progress: number) => {
      if (breakpoint) {
        const y = parseFloat((0.7 - progress).toFixed(2));
        for (let index = 0; index < NUMBER_OF_IMAGES; index++) {
          const imageProgressSetter = progressSetters.current[index * 2];
          const glowProgressSetter = progressSetters.current[index * 2 + 1];

          const offset = parseFloat(styles[breakpoint.name + 'ParallaxOffset']);

          if (offset) {
            const transform = y * parallaxFactors[index] * offset;
            if (imageProgressSetter) {
              imageProgressSetter(transform * 2);
            }
            if (glowProgressSetter) {
              glowProgressSetter(transform * 2);
            }
          }
        }
      }
    },
    [breakpoint],
  );

  useScrollProgress($imagesWrapper, onProgress);

  // get the aspect ratio of the first image and align the text box to it in css
  const image0AspectRatio = images[0].image.asset.aspectRatio.toFixed(3);

  return (
    <ModuleWrapper className={className} {...props}>
      {/* We're using an extra inner container to be able to apply padding without conflict with the spacers */}
      <div
        className={styles.container}
        style={
          {
            '--image-0-aspect-ratio': image0AspectRatio,
          } as CSSProperties
        }
      >
        <TextLockup
          lockupOptions={content.lockupOptions}
          className={styles.content}
          value={content.blocks}
        />
        <Observer
          callback={updateIsInView}
          options={{ rootMargin: '200% 0%' }}
          className={styles.images}
          ref={$imagesWrapper}
        >
          {images.map((image, i) => {
            return (
              <Fragment key={image._key}>
                <div
                  className={styles.imageWrapper}
                  ref={(node) => {
                    $imageWrappers.current[i * 2] = node;
                  }}
                >
                  <Shadow className={styles.imageShadow} />
                  <Image
                    source={image.image}
                    className={styles.image}
                    isDisplayed={isInView !== false}
                    fixedAspectRatio={true}
                  />
                </div>
                {/* We're displaying the image twice in order to have a layer of
              glows and a layer of images. This is to paliate a z-index issue */}
                <div
                  className={cn(styles.imageWrapper, styles.imageGlow)}
                  aria-hidden
                  ref={(node) => {
                    $imageWrappers.current[i * 2 + 1] = node;
                  }}
                  style={
                    {
                      '--aspect-ratio':
                        image.image.asset.aspectRatio.toFixed(3),
                    } as CSSProperties
                  }
                >
                  {image.glow && (
                    <Glow
                      className={styles.glow}
                      source={{
                        ...image.glow,
                      }}
                    />
                  )}
                </div>
              </Fragment>
            );
          })}
        </Observer>
      </div>
    </ModuleWrapper>
  );
};

export default ImageCloud;
