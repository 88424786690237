'use client';
import { gsap } from 'gsap';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import Dim from '~/components/atoms/Dim/Dim';
import Image from '~/components/atoms/Image/Image';
import EnhancedMedia from '~/components/molecules/EnhancedMedia/EnhancedMedia';
import Glow from '~/components/molecules/Glow/Glow';
import Shadow from '~/components/molecules/Shadow/Shadow';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';

import styles from './CarouselSlide.module.css';
import {
  CarouselSlideProps,
  ForwardedCarouselSlideRef,
} from './CarouselSlide.types';

/**
 * Carousel slide item.
 * @param slideIndex Slide index number
 * @param gradientOverlay Selected gradient opacity option, defaults to medium

 * @param text Portable Text content displayed on the slide
 * @param image The slide image
 * @param onClick Callback for when a slide is clicked
 * @param isInView Boolean from intersection observer for whether the carousel is currently in view
 * @param variant  TwoUp or oneCard
 * @param className
 * @example <CarouselSlide slides={slides]/>
 */
const CarouselSlide = (
  {
    className,
    image,
    isInView,
    onClick,
    slideIndex,
    text,
    variant,
  }: CarouselSlideProps,
  ref: ForwardedRef<ForwardedCarouselSlideRef>,
) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $staggerTextElements = useRef<NodeList>();
  const $image = useRef<HTMLElement>(null);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const progressQuickSet = useRef<Function>();

  useImperativeHandle(
    ref,
    () => ({
      $element: $wrapper,
      setActive: (active: boolean) => {
        if (active) {
          if ($wrapper.current) {
            $wrapper.current.setAttribute('data-active', `${active}`);
            $wrapper.current.ariaHidden = 'false';
          }
        } else {
          if ($wrapper.current) {
            $wrapper.current.removeAttribute('data-active');
            $wrapper.current.ariaHidden = 'true';
          }
        }
      },
      setProgress: (progress: number) => {
        if (progressQuickSet.current) progressQuickSet.current(progress);
      },
    }),
    [],
  );

  useLayoutEffect(() => {
    progressQuickSet.current = gsap.quickSetter(
      $wrapper.current,
      '--scroll-progress',
    );

    $staggerTextElements.current =
      $wrapper.current?.querySelectorAll('.stagger');
  }, []);

  const handleClick = () => {
    if (onClick) onClick(slideIndex);
  };

  return (
    <div
      className={cn(styles.slide, styles[variant], className)}
      ref={$wrapper}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <Dim dim="dim80" className={styles.inactiveOverlayLayerDim} />
      <Glow className={styles.glow} source={image.glow && image.glow}></Glow>
      <Shadow className={styles.slideInner}>
        {/* overlay appears on small, one card variant only */}
        <Dim dim="heavy" className={styles.oneCardCarouselOverlay} />
        <EnhancedMedia
          overlay={image.overlay}
          className={styles.slideImageWrapper}
          overlayClassName={styles.slideImageOverlay}
        >
          <Image
            className={styles.slideImage}
            ref={$image}
            source={image.image}
            isDisplayed={isInView}
            fixedAspectRatio={true}
          />
        </EnhancedMedia>

        <TextLockup
          className={cn(styles.slideTextLockup, styles[variant])}
          lockupOptions={text.lockupOptions}
          value={text.blocks}
        />
      </Shadow>
    </div>
  );
};

const ForwardedCarouselSlide = forwardRef(CarouselSlide);

export default ForwardedCarouselSlide;
