import { debounce } from 'lodash';
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import UIStore from '~/state/ui';
import { useScrollProgress } from '~/utils';

import createSnowRenderer from './renderer/createRenderer';
import { SnowRenderer } from './renderer/types';
import styles from './Snow.module.css';
import { SnowProps, SnowRef } from './Snow.types';
import { getTextureInfo } from './Snow.utils';

const Snow = ({ particles, mask }: SnowProps, ref: ForwardedRef<SnowRef>) => {
  const $wrapper = useRef<HTMLDivElement>(null);
  const $container = useRef<HTMLDivElement>(null);
  const $overlay = useRef<HTMLDivElement>(null);
  const $renderer = useRef<SnowRenderer | null>(null);

  useEffect(() => {
    if (!$container.current) {
      return;
    }
    try {
      // create the new snow renderer and set the ref object
      const renderer = createSnowRenderer({
        container: $container.current,
        numParticles: Math.max(400, Math.round(window.screen.width * 0.3)),
        particlesTexture: {
          url: getTextureInfo(particles).url,
          gridWidth: 4,
          gridHeight: 4,
        },
        armMask: getTextureInfo(mask),
      });
      $renderer.current = renderer;

      // resize the renderer when the viewport size changes
      const unsubscribeUIStore = UIStore.subscribe(
        (state) => [state.windowWidth, state.windowHeight],
        debounce(([windowWidth, windowHeight]) => {
          renderer.resize(windowWidth || 320, windowHeight || 240);
        }, 100),
      );

      // cleanup all subscriptions and refs
      return () => {
        unsubscribeUIStore();
        renderer.destroy();
        $renderer.current = null;
      };
    } catch (e) {
      console.error(e);
    }
  }, [particles, mask]);

  useImperativeHandle(
    ref,
    () => ({
      get $container() {
        return $container;
      },
      get $overlay() {
        return $overlay;
      },
      setMaskOffset(_x, y) {
        if (!$renderer.current || !$container.current) {
          return;
        }

        // the incoming y value is based on the media container y position which
        // includes the scroll position. add the container rect top to account for
        // the snow container moving across the screen before sticking to top
        const rect = $container.current.getBoundingClientRect();
        const winHeight = UIStore.getState().windowHeight || window.innerHeight;

        $renderer.current.setContainerTop(rect.top);
        $renderer.current.setMaskOffset(0, (-rect.top + y) / winHeight);
      },
      setMaskPosition(x, y) {
        if ($renderer.current) {
          $renderer.current.setMaskPosition(x, y);
        }
      },
      setMaskScale(x, y) {
        if ($renderer.current) {
          $renderer.current.setMaskScale(x, y);
        }
      },
      setMaskAlpha(a) {
        if ($renderer.current) {
          $renderer.current.setMaskAlpha(a);
        }
      },
    }),
    [],
  );

  useScrollProgress($container, (_progress: number, isInView: boolean) => {
    if (isInView) {
      $renderer.current?.start();
    } else {
      $renderer.current?.stop();
    }
  });

  return (
    <div className={styles.wrapper} ref={$wrapper}>
      <div className={styles.container} ref={$container}></div>
      <div className={styles.overlay} ref={$overlay}></div>
    </div>
  );
};

export default forwardRef(Snow);
