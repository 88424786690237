import { Flip, gsap } from 'gsap/all';
import { RefObject } from 'react';
import { v4 } from 'uuid';

import { VideoRef } from '~/components/atoms/Video/Video.types';
import UIStore from '~/state/ui';
import { isBreakpointOrGreater } from '~/utils';
import { EaseType } from '~/utils/singletons/Easing';

export const closeVideoAnimation = ({
  $perspectiveWrapper,
  $overlay,
  $video,
  $background,
  $closeButton,
  callback,
}: {
  $perspectiveWrapper: RefObject<HTMLElement>;
  $overlay: RefObject<HTMLElement>;
  $video: VideoRef;
  $background: RefObject<HTMLElement>;
  $closeButton: RefObject<HTMLElement>;
  callback: () => void;
}) => {
  gsap.killTweensOf($overlay.current);
  const state = Flip.getState($perspectiveWrapper.current);

  gsap.set($perspectiveWrapper.current, {
    clearProps: 'all',
  });

  Flip.from(state, {
    duration: 0.4,
    fade: true,
    toggleClass: 'flipping',
    ease: EaseType.BASIC_BUTTER,
  });

  const tl = gsap.timeline();

  tl.set($overlay.current, {
    clearProps: 'display',
  });

  tl.add($video.muteSmoothly);
  tl.to(
    $background.current,
    {
      opacity: 0,
    },
    0,
  );

  tl.to(
    $overlay.current,
    {
      opacity: 1,
      yPercent: 0,
      duration: 0.3,
    },
    '-=0.1',
  );

  if (isBreakpointOrGreater(UIStore.getState().breakpoint, 'md')) {
    tl.to(
      $video.$wrapper.current,
      {
        scale: 1.3,
        onComplete: () => {
          gsap.set($video.$wrapper.current, {
            clearProps: 'scale',
          });
        },
      },
      0,
    );
  }

  tl.to(
    $closeButton.current,
    {
      opacity: 0,
      duration: 0.2,
    },
    0,
  );

  tl.set($closeButton.current, {
    display: 'none',
  });

  tl.add(callback);
};

export const openVideoAnimation = ({
  $perspectiveWrapper,
  $overlay,
  $video,
  $background,
  $closeButton,
  $modalVideoPlaceholder,
  $modalPlaceholder,
}: {
  $perspectiveWrapper: RefObject<HTMLElement>;
  $overlay: RefObject<HTMLElement>;
  $video: VideoRef;
  $background: RefObject<HTMLElement>;
  $closeButton: RefObject<HTMLElement>;
  $modalVideoPlaceholder: RefObject<HTMLElement>;
  $modalPlaceholder: RefObject<HTMLElement>;
}) => {
  gsap.killTweensOf($perspectiveWrapper.current);
  gsap.killTweensOf($video.$wrapper.current);

  const batch = Flip.batch(v4());

  batch.add({
    setState: () => {
      Flip.fit($background.current, $modalPlaceholder.current);
    },
    once: true,
  });

  batch.add({
    getState: () => {
      return Flip.getState($perspectiveWrapper.current);
    },
    setState: () => {
      Flip.fit($perspectiveWrapper.current, $modalVideoPlaceholder.current);
    },
    animate: (self) => {
      Flip.from(self.state, {
        duration: 0.4,
        ease: EaseType.BASIC_BUTTER,
      });
    },
    once: true,
  });

  batch.run();

  const tl = gsap.timeline();

  tl.set($closeButton.current, {
    display: 'block',
  });

  tl.add($video.unmuteSmoothly, 0);

  tl.to($background.current, {
    opacity: 1,
  });

  tl.to(
    $overlay.current,
    {
      opacity: 0,
      yPercent: 6,
      duration: 0.3,
    },
    0,
  );

  tl.to(
    $video.$wrapper.current,
    {
      scale: 1,
      xPercent: -50,
      yPercent: -50,
    },
    0,
  );

  tl.to($closeButton.current, {
    opacity: 1,
  });

  tl.set($overlay.current, {
    display: 'none',
  });
};

export const hideThumbnail = ({
  $videoWrapper,
  $shadow,
}: {
  $videoWrapper: HTMLDivElement;
  $shadow: HTMLDivElement;
}) => {
  const tl = gsap.timeline();
  tl.set([$videoWrapper, $shadow], {
    transformOrigin: 'bottom',
  });
  tl.to([$videoWrapper, $shadow], {
    opacity: 0,
    rotateX: 30,
    duration: 0.5,
    yPercent: -90,
  });
  tl.set([$videoWrapper, $shadow], {
    display: 'none',
  });
};

export const showThumbnail = ({
  $videoWrapper,
  $shadow,
  delay,
}: {
  $videoWrapper: HTMLDivElement;
  $shadow: HTMLDivElement;
  delay?: number;
}) => {
  const tl = gsap.timeline({
    delay,
  });
  gsap.killTweensOf([$videoWrapper, $shadow]);
  tl.set([$videoWrapper, $shadow], {
    clearProps: 'display',
    rotateX: -30,
    scale: 0.9,
    yPercent: 60,
    transformOrigin: 'top',
  });
  tl.to([$videoWrapper, $shadow], {
    opacity: 1,
    scale: 1,
    rotateX: 0,
    yPercent: 0,
    duration: 0.6,
    ease: EaseType.BASIC_BUTTER,
  });
};
