import { CMSCarouselWithToutsSlide } from './CarouselWithToutsSlide/CarouselWithToutsSlide.types';

export type CMSModuleCarouselWithToutsDesktop = {
  /**
   * An array of slide data objects
   */
  slides: CMSCarouselWithToutsSlide[];
  hasBlurredBackground: boolean;
  className?: string;
};

export const SLIDE_DURATION = 1;
export type rect = { left: number; width: number };
