'use client';
import { KeyboardEvent, useRef, useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import LogoTextItem from '~/components/molecules/LogoTextItem/LogoTextItem';
import { CMSLogoTextItem } from '~/components/molecules/LogoTextItem/LogoTextItem.types';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import { cn, titleToHash } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import { useHighlightReference } from './helpers/useHighlightReference';
import styles from './TileTable.module.css';
import { CMSTileTable, TileTableProps } from './TileTable.types';
import TileTableModal from './TileTableModal/TileTableModal';

const TileTable = (props: TileTableProps) => {
  const { title, groups, className, sectionIndex } = props;
  const groupRefs = useRef<HTMLDivElement[]>(Array(groups.length));
  const $tileTable = useRef<HTMLDivElement>(null);
  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  const pageSectionsRefs = useUIStore((state) => state.pageSectionsRefs);

  const isSingleGroup = groups.length < 2;

  // get the tile table's parent section so the parallax offset can be calculated for the anchor nav
  const parentSection = pageSectionsRefs[sectionIndex];

  // hook that calculates which menu should be selected
  const selected = useHighlightReference(groupRefs.current, parentSection);

  const [selectedGroup, setSelectedGroup] = useState<CMSLogoTextItem[] | null>(
    null,
  );

  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null,
  );

  const onClick = (groupIndex: number, itemIndex: number) => {
    onSelectItem(groupIndex, itemIndex);
  };

  // selects one of the tile items and opens the modal
  const handleKeyDown = (
    event: KeyboardEvent<HTMLButtonElement>,
    groupIndex: number,
    itemIndex: number,
  ) => {
    if (event.code === 'Enter') {
      onSelectItem(groupIndex, itemIndex);
    }
  };

  const onSelectItem = (groupIndex: number, itemIndex: number) => {
    setSelectedGroup(groups[groupIndex].logoTextItems);
    setSelectedItemIndex(itemIndex);
  };

  const onClose = () => {
    setSelectedGroup(null);
    setSelectedItemIndex(null);
  };

  return (
    <ModuleWrapper
      ref={$tileTable}
      className={cn(styles.tileTable, className)}
      {...props}
    >
      <div className={cn(styles.header, isSingleGroup && styles.noKeyline)}>
        <h3 className={styles.headerTitle}>{title}</h3>
        {isSingleGroup === false && <div className={styles.keyline}></div>}
      </div>

      {isSingleGroup === false && (
        <aside className={styles.anchorNav}>
          <nav className={styles.anchorNavItems}>
            {groups.map((group: CMSTileTable, index: number) => (
              <a
                key={group._key}
                href={`#tile-table-${titleToHash(group.name)}`}
                className={cn(
                  styles.anchorNavItem,
                  selected === index && styles.active,
                )}
              >
                {group.name}
              </a>
            ))}
          </nav>
        </aside>
      )}

      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '100% 0%' }}
        className={cn(styles.groups, isSingleGroup && styles.isSingleGroup)}
      >
        {groups.map((group: CMSTileTable, groupIndex: number) => (
          <div
            ref={(ref: HTMLDivElement) => {
              addToRefArray({
                element: ref,
                refArray: groupRefs,
                index: groupIndex,
              });
            }}
            id={`tile-table-${titleToHash(group.name)}`}
            key={group._key}
            className={styles.groupContainer}
          >
            {isSingleGroup === false && (
              <h4 className={styles.groupTitle}>{group.name}</h4>
            )}
            <div className={styles.groupItems}>
              {group.logoTextItems &&
                group.logoTextItems.map((item, itemIndex) => (
                  <button
                    key={item._key}
                    className={styles.groupItem}
                    onClick={() => onClick(groupIndex, itemIndex)}
                    onKeyDown={(event) =>
                      handleKeyDown(event, groupIndex, itemIndex)
                    }
                  >
                    <LogoTextItem
                      {...item}
                      labelClassName={styles.label}
                      className={styles.tileItem}
                      isInView={isInView !== false}
                    />
                  </button>
                ))}
            </div>
          </div>
        ))}
      </Observer>
      {selectedGroup && selectedItemIndex !== null && (
        <TileTableModal
          selectedGroup={selectedGroup}
          selectedItemIndex={selectedItemIndex}
          onClose={onClose}
        />
      )}
    </ModuleWrapper>
  );
};

export default TileTable;
