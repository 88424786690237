import { gsap } from 'gsap';
import { capitalize } from 'lodash';
import { useEffect, useRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import useUIStore from '~/state/ui';
import {
  cn,
  isBreakpointOrGreater,
  isBreakpointOrSmaller,
  useScrollProgress,
} from '~/utils';

import styles from './BackgroundImage.module.css';
import { BackgroundImage as BackgroundImageProps } from './BackgroundImage.types';

const BackgroundImage = (props: BackgroundImageProps) => {
  const $container = useRef<HTMLDivElement>(null);
  const breakpoint = useUIStore((store) => store.breakpoint);

  useScrollProgress($container, (progress: number) => {
    if (isBreakpointOrSmaller(breakpoint, 'sm')) {
      return;
    }
    gsap.to($container.current, {
      y: `${-10 + Math.round(progress * 20)}%`,
      duration: 0.1,
      ease: 'none',
      overwrite: true,
    });
  });

  useEffect(() => {
    if (isBreakpointOrGreater(breakpoint, 'md')) {
      return;
    }
    gsap.killTweensOf($container.current);
    gsap.set($container.current, {
      y: 0,
    });
  }, [breakpoint]);

  return (
    <div className={styles.container} ref={$container}>
      <Image
        className={cn(
          styles.image,
          styles[`anchor${capitalize(props.anchor)}`],
          styles[`blend${capitalize(props.blendMode)}`],
        )}
        source={props.image}
        isDisplayed={true}
      />
    </div>
  );
};

export default BackgroundImage;
