'use client';

import { useKeenSlider } from 'keen-slider/react';
import { useEffect, useState } from 'react';

import Observer from '~/components/atoms/Observer/Observer';
import PaginationDots from '~/components/atoms/Pagination/Dots/PaginationDots';
import CardItemLandscape from '~/components/organisms/modules/CardGridLandscapeSquare/CardItemLandscape/CardItemLandscape';
import CardItemSquare from '~/components/organisms/modules/CardGridLandscapeSquare/CardItemSquare/CardItemSquare';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import useUIStore from '~/state/ui';
import decorationsStyles from '~/styles/theme/decorations.module.css';
import { cn, getSpacer, keenSliderConfig } from '~/utils';

import styles from './CardGridLandscapeSquare.module.css';
import { CardGridLandscapeSquareProps } from './CardGridLandscapeSquare.types';

const spacerValue = getSpacer(16);

const CardGridLandscapeSquare = (props: CardGridLandscapeSquareProps) => {
  const { cardGridItems, className, hasSpacers, layoutType } = props;

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);
  const [isSliderReady, setIsSliderReady] = useState<boolean>(false);

  const isComputedStyleComplete = useUIStore(
    (state) => state.isComputedStyleComplete,
  );

  const total = cardGridItems.length;

  // set layout specific variables
  let title;
  let tallestWrapperHeight: number;
  if (layoutType === 'landscape') {
    title = props.title;
    tallestWrapperHeight = props.tallestWrapperHeight;
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.slideItem',
    slides: {
      origin: 'auto',
      spacing: spacerValue,
      perView: 'auto',
      number: total,
    },
    breakpoints: {
      [keenSliderConfig.breakpoints.md.mediaQuery]: {
        disabled: true,
      },
    },
    created() {
      setIsSliderReady(true);
    },
  });

  useEffect(() => {
    if (isComputedStyleComplete) {
      instanceRef.current?.update();
    }
  }, [isComputedStyleComplete, instanceRef]);

  return (
    <ModuleWrapper
      className={cn(
        hasSpacers?.before && decorationsStyles.hasSpacerBefore,
        hasSpacers?.after && decorationsStyles.hasSpacerAfter,
        className,
      )}
      {...props}
    >
      <Observer
        callback={updateIsInView}
        options={{ rootMargin: '200% 0%' }}
        className={cn(
          styles.cardGridLayout,
          isSliderReady && styles.isSliderReady,
          className,
        )}
      >
        {layoutType === 'landscape' && title && (
          <h3 className={styles.title}>{title}</h3>
        )}
        <ul className={styles.cardItems} ref={sliderRef}>
          {layoutType === 'landscape' &&
            cardGridItems.map(
              ({ _key: cardKey, content, eyebrow, image, logo, title }) => (
                <li className={cn(styles.cardItem, 'slideItem')} key={cardKey}>
                  <CardItemLandscape
                    _key={cardKey}
                    className={styles.cardInner}
                    content={content}
                    image={image}
                    title={title}
                    eyebrow={eyebrow}
                    logo={logo}
                    logoWrapperHeight={tallestWrapperHeight}
                    isInView={isInView !== false}
                  />
                </li>
              ),
            )}

          {layoutType === 'square' &&
            cardGridItems.map(({ _key: cardKey, content, image, title }) => (
              <li className={cn(styles.cardItem, 'slideItem')} key={cardKey}>
                <CardItemSquare
                  _key={cardKey}
                  className={styles.cardInner}
                  content={content}
                  image={image}
                  title={title}
                  isInView={isInView !== false}
                />
              </li>
            ))}
        </ul>

        <div className={styles.carouselNavigation}>
          <PaginationDots total={total} parentSliderRef={instanceRef} />
        </div>
      </Observer>
    </ModuleWrapper>
  );
};

export default CardGridLandscapeSquare;
