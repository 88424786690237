'use client';
import { forwardRef } from 'react';

import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import Graphic from '~/components/atoms/Graphic/Graphic';
import Image from '~/components/atoms/Image/Image';
import Link from '~/components/atoms/Link/Link';
import Glow from '~/components/molecules/Glow/Glow';
import Shadow from '~/components/molecules/Shadow/Shadow';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import { cn } from '~/utils';

import styles from '../CardGridEditorial.module.css';
import { CardGridEditorialCardProps } from './CardGridEditorialCard.types';

const CardGridEditorialCard = (
  { card, className, isInView, logoWrapperHeight }: CardGridEditorialCardProps,
  ref: React.Ref<HTMLAnchorElement>,
) => {
  const Wrapper = card.image.glow ? Glow : 'div';
  // assembling props here as otherwise typescript complains about non-standard
  // dom properties being set on a <div> element.
  const WrapperProps = card.image.glow
    ? { backgroundClassName: styles.glow, source: card.image.glow }
    : {};

  return (
    <Link
      ref={ref}
      key={card._key}
      className={cn(styles.card, className)}
      to={card.link}
    >
      <div className={styles.innerCardContainer}>
        <Wrapper className={styles.glowContainer} {...WrapperProps}>
          <Shadow className={styles.shadowContainer} />
          <div className={styles.imageContainer}>
            <Image
              source={card.image.image}
              className={styles.imageElement}
              isDisplayed={isInView !== false}
              fixedAspectRatio={true}
            />
            {card.logo && logoWrapperHeight && (
              <div
                className={styles.logoWrapper}
                style={{
                  height: logoWrapperHeight
                    ? `${logoWrapperHeight / 10}rem`
                    : 'auto',
                }}
              >
                <Graphic {...card.logo} className={styles.logo} />
              </div>
            )}
          </div>
        </Wrapper>
      </div>
      <TextLockup
        lockupOptions={card.text.lockupOptions}
        className={styles.text}
        value={card.text.blocks}
      />
      <Button
        asNonInteractive
        className={styles.button}
        buttonVariant="textLink"
      >
        {card.link.label}
      </Button>
    </Link>
  );
};

export default forwardRef(CardGridEditorialCard);
