'use client';

import RadioButtons from '~/components/atoms/RadioButtons/RadioButtons';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import usePricingStore from '~/state/pricing';
import { cn } from '~/utils';

import { PricePeriodValue } from '../Pricing.types';
import PricingCard from './PricingCard/PricingCard';
import styles from './PricingOverview.module.css';
import { PricingOverviewProps } from './PricingOverview.types';

const PricingOverview = (props: PricingOverviewProps) => {
  const { text, radioButtons, className, pricing, showIntroText } = props;
  const pricePeriod = usePricingStore((state) => state.pricePeriod);
  const setPricePeriod = usePricingStore((state) => state.setPricePeriod);
  const showRadioButtons =
    Array.isArray(radioButtons) &&
    radioButtons.length > 0 &&
    props.cardVariant === 'full';
  const haveProducts =
    Array.isArray(pricing.products) && pricing.products.length > 0;

  const onRadioButtonChange = (index: number) => {
    const radioValue = radioButtons[index].value;

    if (
      Object.values(PricePeriodValue).includes(radioValue as PricePeriodValue)
    ) {
      setPricePeriod(radioValue as PricePeriodValue);
    }
  };

  return (
    <ModuleWrapper
      className={cn(
        styles.pricingOverview,

        className,
      )}
      {...props}
    >
      {showIntroText && (
        <TextLockup
          value={text.blocks}
          className={styles.content}
          lockupOptions={text.lockupOptions}
        />
      )}

      {showRadioButtons && (
        <RadioButtons
          className={styles.radioButtons}
          buttons={radioButtons}
          onChange={onRadioButtonChange}
        />
      )}

      <div className={styles.pricingCardsWrapper}>
        {haveProducts &&
          pricing.products.flatMap((product) => (
            <PricingCard
              key={product._key}
              product={product}
              pricePeriod={pricePeriod}
              highlighted={props.pricing.highlightedTier === product.tier._id}
              variant={props.cardVariant}
              featuresLink={props.viewAllFeaturesLink}
              numSibblings={pricing.products.length}
            />
          ))}
      </div>
    </ModuleWrapper>
  );
};

export default PricingOverview;
