import { Flip, gsap } from 'gsap/all';
import {
  CSSProperties,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';

import { ForwardedButtonRef } from '~/components/atoms/Buttons/Ctas/Button/Button.types';
import ButtonClose from '~/components/atoms/Buttons/UI/ButtonClose/ButtonClose';
import Dim from '~/components/atoms/Dim/Dim';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import PlayableVideo from '~/components/molecules/PlayableVideo/PlayableVideo';
import UIStore from '~/state/ui';
import { VIDEO_MODAL_WHEEL_THRESHOLD } from '~/types/decorations';
import { cn, isBreakpointOrGreater, useScrollProgress } from '~/utils';
import ClientOnlyPortal from '~/utils/ClientOnlyPortal/ClientOnlyPortal';
import isPointInRectangle from '~/utils/math/isPointInRectangle';
import { tickerAddOnce } from '~/utils/ticker';
import useFocusTrap from '~/utils/useFocusTrap';
import useMouseDistance from '~/utils/useMouseDistance/useMouseDistance';

import PortableText from '../PortableText/PortableText';
import Shadow from '../Shadow/Shadow';
import {
  closeVideoAnimation,
  hideThumbnail,
  openVideoAnimation,
  showThumbnail,
} from './FloatingVideoPreview.animations';
import styles from './FloatingVideoPreview.module.css';
import {
  FLOATING_VIDEO_PREVIEW_PORTAL_CONTAINER_ID,
  FloatingVideoPreviewProps,
  PREVIEW_LOOP_DURATION,
} from './FloatingVideoPreview.types';
import { clearMagnetism } from './FloatingVideoPreview.utils';

gsap.registerPlugin(Flip);

/**
 * Component displaying a sticky preview of a video in the bottom right corner
 * of the screen on md+. The preview will open full screen on click similar to
 * a modal.
 * On mobile the video will simply display in the regular flow.
 * @param options CMSFloatingVideoPreviewProps
 * @param options.video CMSVideoProps - The video data
 
 * @param options.textContent PortableTextCustomValue - The text content
 * @param options.isSticky boolean - Whether the video preview should remain sticky after its parent component has scrolled away
 * @param $parent RefObject<HTMLDivElement> - Ref for the component that contains the video preview
 */
const FloatingVideoPreview = ({
  options,
  className,
  $parent,
}: FloatingVideoPreviewProps) => {
  const { video, textContent } = options;
  // default to true for isSticky option
  const isSticky = options.isSticky === null ? true : options.isSticky;

  const $componentWrapper = useRef(null);
  const $perspectiveWrapper = useRef(null);
  const $shadow = useRef(null);
  const $closeButton = useRef<ForwardedButtonRef>(null);

  // ref for the desktop video, rendered within a client portal
  const $video = useRef<VideoRef>(null);

  // ref for the mobile video, rendered within its parent component
  const $videoInline = useRef<VideoRef>(null);

  const $mouseDistanceWrapper = useRef<HTMLDivElement>(null);
  const mouseDistanceWrapperRect = useRef<DOMRect>();
  const $overlay = useRef(null);
  const $overlayMobile = useRef(null);
  const $background = useRef(null);
  const $videoWrapper = useRef<HTMLDivElement>(null);
  const $modalPlaceholder = useRef<HTMLDivElement>(null);
  const $modalVideoPlaceholder = useRef<HTMLDivElement>(null);

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const wheelAmountRef = useRef<number>(0);

  const isFloatingPreviewDisplayed = useRef(true);

  const [mounted, setMounted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [controls, setControls] = useState(false);
  const [videoStartedMobile, setVideoStartedMobile] = useState(false);

  const breakpointRef = useRef(UIStore.getState().breakpoint);

  const id = useId();

  const closeVideo = () => {
    UIStore.getState().setIsNavigationBarVisible(true);
    UIStore.getState().setIsScrollLocked(false);
    setControls(false);

    window.removeEventListener('keydown', handleKeyDown);
    window.removeEventListener('wheel', handleWheel);

    tickerAddOnce(() => {
      if (
        $perspectiveWrapper.current &&
        $modalPlaceholder.current &&
        $video.current &&
        $closeButton.current
      ) {
        closeVideoAnimation({
          $perspectiveWrapper,
          $overlay,
          $video: $video.current,
          $background,
          $closeButton: $closeButton.current.$button,
          callback: () => {
            setIsModalOpen(false);
          },
        });
      }
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      $video.current?.restart();
    }
  }, [isModalOpen]);

  const openVideo = () => {
    setIsModalOpen(true);

    if (isBreakpointOrGreater(breakpointRef.current, 'md')) {
      // restart the video in the client portal (shown only on desktop)
      $video.current?.restart();
      setControls(true);
      UIStore.getState().setIsNavigationBarVisible(false);
      UIStore.getState().setIsScrollLocked(true);

      tickerAddOnce(() => {
        if (
          $perspectiveWrapper.current &&
          $modalPlaceholder.current &&
          $video.current &&
          $closeButton.current
        ) {
          openVideoAnimation({
            $perspectiveWrapper,
            $overlay,
            $video: $video.current,
            $background,
            $closeButton: $closeButton.current.$button,
            $modalVideoPlaceholder,
            $modalPlaceholder,
          });
          window.addEventListener('keydown', handleKeyDown);
          window.addEventListener('wheel', handleWheel);
        }
      });
    } else {
      gsap.set($overlayMobile.current, { display: 'none' });
      setVideoStartedMobile(true);
    }
  };

  /**
   * Handles trapping the focus when the video modal is open
   */
  const onFocusTrapKeyDown = useFocusTrap($videoWrapper);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeVideo();
      }
      if (onFocusTrapKeyDown) {
        onFocusTrapKeyDown(event);
      }
    },
    // Disabling the linter as we don't expect closeVideo to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFocusTrapKeyDown],
  );

  /**
   * Handles hiding the preview when we scroll far down
   */
  const onProgress = useCallback(
    (progress: number) => {
      if (
        isBreakpointOrGreater(breakpointRef.current, 'md') &&
        $videoWrapper.current &&
        $shadow.current &&
        mounted
      ) {
        const showRatio = 0.5;
        if (
          progress >= showRatio &&
          isFloatingPreviewDisplayed.current &&
          isSticky
        ) {
          isFloatingPreviewDisplayed.current = false;
          hideThumbnail({
            $videoWrapper: $videoWrapper.current,
            $shadow: $shadow.current,
          });
        } else if (
          progress < showRatio &&
          isFloatingPreviewDisplayed.current === false &&
          isSticky
        ) {
          isFloatingPreviewDisplayed.current = true;
          showThumbnail({
            $videoWrapper: $videoWrapper.current,
            $shadow: $shadow.current,
          });
        }
      }
    },
    // add mounted as a dependency here to ensure portal is mounted and all refs are present
    [mounted, isSticky],
  );

  useScrollProgress($componentWrapper, onProgress, {
    finishOnMiddleOfScreen: true,
    shouldAlwaysComplete: false,
  });

  /**
   * Handles the magnetism of the preview
   */

  // This variable represents the state of the magnetism of the floating preview
  const isResting = useRef(false);

  useMouseDistance(
    $mouseDistanceWrapper,
    (distance: number, { x, y }) => {
      if (breakpointRef.current?.name === 'sm') {
        clearMagnetism({ $perspectiveWrapper, $video });
      } else if (!isModalOpen && mouseDistanceWrapperRect.current && mounted) {
        let translateX = 0;
        let translateY = 0;
        let videoTranslateX = 0;
        let videoTranslateY = 0;

        let resting = true;

        // We're doubling the lengths to simulate an origin bottom right instead of centered
        const isInField = isPointInRectangle(
          x,
          y,
          mouseDistanceWrapperRect.current.width * 2,
          mouseDistanceWrapperRect.current.height * 2.5,
        );

        if (isInField) {
          resting = false;
          translateX = x / -10;
          translateY = y / -10;

          videoTranslateX = x / -30;
          videoTranslateY = y / -30;
        }

        if (!resting || resting !== isResting.current) {
          isResting.current = resting;
          gsap.to($perspectiveWrapper.current, {
            x: translateX,
            y: translateY,
            duration: resting ? 1.2 : 0.7,
            ease: resting ? 'elastic.out(1, 0.75)' : 'power1.out',
          });

          if ($video.current?.$wrapper.current) {
            gsap.to($video.current.$wrapper.current, {
              xPercent: -50 + Math.min(0, videoTranslateX),
              yPercent: -50 + Math.min(0, videoTranslateY),
              x: 0,
              y: 0,
              duration: resting ? 1 : 0.7,
            });
          }
        }
      }
    },
    { origin: 'bottomRight' },
  );

  /**
   * Handles closing the video on scroll
   */
  const handleWheel = useCallback(
    (event: WheelEvent) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      wheelAmountRef.current += event.deltaY;
      if (Math.abs(wheelAmountRef.current) > VIDEO_MODAL_WHEEL_THRESHOLD) {
        wheelAmountRef.current = 0;
        closeVideo();
      }

      // reset wheel value if scroll is not happening for a certain amount of time
      timeoutRef.current = setTimeout(() => {
        wheelAmountRef.current = 0;
      }, 100);
    },
    // Keeping the deps array empty as we don't expect closeVideo to change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Bind resize events when client portal is mounted
   */
  const onMount = useCallback(() => {
    setMounted(true);

    if (mounted) {
      const setHeight = () => {
        if (
          $componentWrapper.current &&
          $parent?.current &&
          isBreakpointOrGreater(breakpointRef.current, 'md')
        ) {
          // the desktop video is in a client only portal and doesn't know the size of its parent element by default, so it doesn't know what size to be
          const parentHeight = $parent.current.getBoundingClientRect().height;
          gsap.set($componentWrapper.current, {
            '--parent-height': `${parentHeight}px`,
          });
        }
      };
      const reset = () => {
        closeVideo();
        clearMagnetism({
          $perspectiveWrapper,
          $video,
        });
        setControls(false);
      };

      const unsubscribeBreakpoint = UIStore.subscribe(
        (state) => state.breakpoint,
        (newBreakpoint) => {
          if (document.fullscreenElement === null) {
            breakpointRef.current = newBreakpoint;
            reset();
            setHeight();
          }
        },
      );

      const unsubscribeWindowHeight = UIStore.subscribe(
        (state) => state.windowHeight,
        () => {
          if (isBreakpointOrGreater(breakpointRef.current, 'md')) {
            if (document.fullscreenElement === null) {
              reset();
              setHeight();
            }
          }
        },
      );

      setHeight();

      if (
        mounted &&
        $videoWrapper.current &&
        $shadow.current &&
        isBreakpointOrGreater(UIStore.getState().breakpoint, 'md') &&
        isSticky
      ) {
        showThumbnail({
          $videoWrapper: $videoWrapper.current,
          $shadow: $shadow.current,
          delay: 1.2,
        });
      }

      const updateMouseDistanceWrapperRect: ResizeObserverCallback = (
        entries,
      ) => {
        for (const entry of entries) {
          mouseDistanceWrapperRect.current = entry.contentRect;
        }
      };

      const resizeObserver = new ResizeObserver(updateMouseDistanceWrapperRect);

      if ($mouseDistanceWrapper.current) {
        resizeObserver.observe($mouseDistanceWrapper.current);
      }

      return () => {
        unsubscribeBreakpoint();
        unsubscribeWindowHeight();
        resizeObserver.disconnect();
      };
    }
    // add mounted as a dependency here to ensure refs are all updated
  }, [mounted]);

  /**
   * We create a custom style snippet to have fullscreen that takes up either
   * the full width or the full height depending on the aspect-ratio of the
   * video compared to the aspect ratio of the viewport
   */

  const customMediaQuery = `
  @media screen and (max-aspect-ratio: ${video.aspectRatio}) {
    .${styles.modalVideoPlaceholder}[data-id="${id}"] {
      height: auto;
    }
  }
  @media screen and (aspect-ratio: ${video.aspectRatio}) {
    .${styles.modalVideoPlaceholder}[data-id="${id}"] {
      height: auto;
    }
  }
  @media screen and (min-aspect-ratio: ${video.aspectRatio}) {
    .${styles.modalVideoPlaceholder}[data-id="${id}"] {
      width: auto;
    }
  }
  `;

  function renderVideoPreview(whichBreakpoint: string) {
    const isDesktop = whichBreakpoint === 'desktop';
    return (
      <div
        className={cn(
          styles.floatingVideoPreviewWrapper,
          isSticky && styles.isSticky,
          styles[whichBreakpoint],
          className,
        )}
        ref={isDesktop ? $componentWrapper : null}
      >
        <div
          className={styles.background}
          ref={isDesktop ? $background : null}
        ></div>

        <div
          className={styles.mouseDistanceWrapper}
          ref={isDesktop ? $mouseDistanceWrapper : null}
        >
          <div className={styles.perspectiveWrapper} ref={$perspectiveWrapper}>
            <div className={styles.floatingVideoPreview} ref={$videoWrapper}>
              <PlayableVideo
                className={styles.video}
                playButtonClassName={styles.playButton}
                {...options.video}
                autoplayPreview={options.video.autoplayPreview}
                src={options.video.url}
                ref={!isDesktop ? $videoInline : $video}
                shouldFocusControls={controls}
                loopLength={!isModalOpen ? PREVIEW_LOOP_DURATION : undefined}
                displayPreview={!isModalOpen ? true : false}
                forceIsInView={options.video.autoplayPreview ? true : false}
                forceVideoStart={videoStartedMobile}
              />

              <button
                className={styles.overlay}
                ref={isDesktop ? $overlay : $overlayMobile}
                onClick={openVideo}
              >
                <Dim dim="medium" className={styles.dim} />
                <div className={styles.ui}>
                  <PortableText
                    value={textContent}
                    options={{
                      block: {
                        titles: {
                          title55: {
                            className: styles.title,
                          },
                        },
                      },
                    }}
                  />
                </div>
              </button>
              <ButtonClose
                className={styles.closeButton}
                ref={isDesktop ? $closeButton : null}
                onClick={closeVideo}
              />
            </div>
            <Shadow
              className={styles.shadow}
              ref={isDesktop ? $shadow : null}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* on mobile, the video preview will render inline within its parent component */}
      {renderVideoPreview('mobile')}

      {/* on desktop, the video preview will render in a client portal */}
      <ClientOnlyPortal
        selector={`#${FLOATING_VIDEO_PREVIEW_PORTAL_CONTAINER_ID}`}
        onMount={onMount}
      >
        {renderVideoPreview('desktop')}
      </ClientOnlyPortal>

      <ClientOnlyPortal selector="body">
        <div className={styles.modalPlaceholder} ref={$modalPlaceholder}>
          <style>{customMediaQuery}</style>
          <div
            className={styles.modalVideoPlaceholder}
            style={{ '--aspect-ratio': video.aspectRatio } as CSSProperties}
            data-id={id}
            ref={$modalVideoPlaceholder}
          />
        </div>
      </ClientOnlyPortal>
    </>
  );
};

export default FloatingVideoPreview;
