import styles from './DebugUI.module.css';

export type DebugUIProps = React.PropsWithChildren & {
  onShare?: () => void;
  onReset?: () => void;
};

export const DebugUI = ({ children, onShare, onReset }: DebugUIProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>
          <div className={styles.titleLabel}>Shader Parameters</div>
          <div className={styles.titleButtonGroup}>
            <button onClick={onShare}>Share</button>
            <button onClick={onReset}>Reset</button>
          </div>
        </div>
        <div className={styles.properties}>{children}</div>
      </div>
    </div>
  );
};
