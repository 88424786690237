import { RefObject } from 'react';

import UIStore from '~/state/ui';

const getKeenSliderSlidesPerView = (
  $sizer: RefObject<HTMLElement>,
  spacer: number = 0,
  slidesPerView: number = 1,
) => {
  const windowWidth = UIStore.getState().windowWidth;
  // use the dummy grid to calculate the grid width, max grid size, and grid margin at any breakpoint
  // must return a number > 0 for keen slider to work on page load
  if (!$sizer.current || !windowWidth) return 'auto';

  const gridWidth = $sizer.current.getBoundingClientRect().width;
  const gridMargin = windowWidth - gridWidth - spacer / 2;
  const perView = gridMargin / (gridWidth / slidesPerView) + slidesPerView;
  // if the carousel is hidden by css on a certain breakpoint, the gridWidth will return 0

  return gridWidth === 0 ? 'auto' : perView;
};

export default getKeenSliderSlidesPerView;
