'use client';
import { gsap } from 'gsap';
import { CSSProperties, useRef, useState } from 'react';

import GlassWrapper from '~/components/atoms/GlassWrapper/GlassWrapper';
import Observer from '~/components/atoms/Observer/Observer';
import { VideoRef } from '~/components/atoms/Video/Video.types';
import Media from '~/components/molecules/Media/Media';
import TextLockup from '~/components/molecules/TextLockups/TextLockup';
import ModuleWrapper from '~/components/organisms/ModuleWrapper/ModuleWrapper';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';

import styles from './MediaWithText.module.css';
import { MediaWithTextProps } from './MediaWithText.types';

const MAX_PARALLAX_PERCENT = 16;

const MediaWithText = (props: MediaWithTextProps) => {
  const { media, mobileAlignment, text, displayGlassBorder } = props;
  const progressSetter = useRef<(value: number) => void>();
  const $element = useRef<HTMLDivElement>(null);
  const $media = useRef<HTMLElement | VideoRef>(null);
  const needsParallax = false;
  const CMSAspectRatio =
    'asset' in media.sanityMedia
      ? media.sanityMedia.asset.aspectRatio
      : media.sanityMedia.aspectRatio;
  const mediaAspectRatio =
    CMSAspectRatio && needsParallax
      ? CMSAspectRatio * (needsParallax ? 1 + MAX_PARALLAX_PERCENT / 100 : 1)
      : CMSAspectRatio;
  const mediaOriginalAspectRatio = CMSAspectRatio || null;

  const [isInView, updateIsInView] = useState<false | DOMRect>(false);

  useLayoutEffect(() => {
    progressSetter.current = gsap.quickSetter(
      $element.current,
      '--media-with-text-progress',
    ) as (value: number) => void;
  }, []);

  const Wrapper = displayGlassBorder ? GlassWrapper : 'div';

  return (
    <ModuleWrapper
      className={cn(
        styles.mediaWithText,
        mobileAlignment === 'right' && styles.isMobileRightAligned,
        mobileAlignment === 'left' && styles.isMobileLeftAligned,
      )}
      style={
        {
          '--max-parallax-percent': MAX_PARALLAX_PERCENT - 2,
        } as CSSProperties
      }
      ref={$element}
      {...props}
    >
      <div className={styles.mediaWrapper}>
        <Observer
          className={styles.contentWrapper}
          callback={updateIsInView}
          options={{ rootMargin: '200% 0%' }}
        >
          <Wrapper
            className={cn(
              styles.mediaContainer,
              !displayGlassBorder && styles.hasRoundedBorders,
            )}
            style={
              {
                ...(mediaAspectRatio
                  ? { '--media-aspect-ratio': mediaAspectRatio }
                  : {}),
                ...(mediaOriginalAspectRatio
                  ? {
                      '--media-original-aspect-ratio': mediaOriginalAspectRatio,
                    }
                  : {}),
              } as CSSProperties
            }
          >
            <Media
              ref={$media}
              className={cn(styles.media, styles.noMediaParallax)}
              sanityMedia={media.sanityMedia}
              isDisplayed={isInView !== false}
              fixedAspectRatio={true}
            />
          </Wrapper>
        </Observer>
      </div>
      <TextLockup
        className={styles.textContent}
        lockupOptions={text.lockupOptions}
        value={text.blocks}
      />
    </ModuleWrapper>
  );
};

export default MediaWithText;
